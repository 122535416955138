import { MutationFunction } from '@apollo/client'
import delay from 'delay'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import {
  DAY_FORMAT,
  LienWaiverCategory,
  supportsVendorInvoicesDateRangeFilter,
} from 'siteline-common-all'
import type { WritableDeep } from 'type-fest'
import { getVendorIdByInvoiceId } from '../../components/vendors/vendor-lien-waivers/select-vendors-for-month/SelectVendorsForMonth'
import {
  AddIntegrationVendorsToContractMutation,
  AddIntegrationVendorsToContractMutationVariables,
  AddIntegrationVendorsToSwornStatementContractMutation,
  GetIntegrationVendorInvoicesDocument,
  GetIntegrationVendorInvoicesQuery,
  MinimalIntegrationProperties,
  MinimalVendorContractProperties,
  useGetImportedVendorInvoicesLazyQuery,
  useGetIntegrationVendorInvoicesLazyQuery,
} from '../graphql/apollo-operations'

export type IntegrationVendorInvoice = Omit<
  GetIntegrationVendorInvoicesQuery['integrationVendorInvoices'][number],
  '__typename'
>

export type SearchIntegrationVendorInvoicesParams =
  | {
      type: 'integration'
    }
  | {
      type: 'imported'
      contractId: string
      companyIntegrationId: string | null
    }

/**
 * Some integrations support filtering by date range in the query, in which case we execute
 * a new query each time the user changes the date range on the frontend. Other integrations don't
 * support filtering by date range, so we have to fetch all invoices and payments on a project for
 * any date range; for those integrations, we only make one request for all invoices, and this
 * hook handles filtering by date range.
 *
 * This hook is used for both imported and integration vendor invoices. In one case, the user will have
 * imported their invoices ahead of time and this hook will fetch them from our database. In the other
 * case, the user will fetch invoices directly from the integration (which takes a little longer).
 *
 * Vendor ID may be provided for any integration to filter only for invoices for that vendor, or it
 * may be null to fetch all invoices on the job.
 */
export function useSearchIntegrationVendorInvoices({
  timeZone,
  vendorId,
  integration,
  searchParams,
}: {
  timeZone: string
  vendorId: string | null
  integration: MinimalIntegrationProperties | null
  searchParams: SearchIntegrationVendorInvoicesParams
}) {
  const [hasRequestedInvoices, setHasRequestedInvoices] = useState<boolean>(false)
  const [allVendorInvoices, setAllVendorInvoices] = useState<IntegrationVendorInvoice[]>([])
  const [filteredVendorInvoices, setFilteredVendorInvoices] = useState<
    IntegrationVendorInvoice[] | null
  >(null)
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const [
    getIntegrationVendorInvoices,
    { loading: isLoadingIntegrationQuery, error: integrationError },
  ] = useGetIntegrationVendorInvoicesLazyQuery({
    // Sets loading to true when calling refetch on error
    notifyOnNetworkStatusChange: true,
  })
  const [getImportedVendorInvoices, { loading: isLoadingImportedQuery, error: importedError }] =
    useGetImportedVendorInvoicesLazyQuery({
      // Sets loading to true when calling refetch on error
      notifyOnNetworkStatusChange: true,
    })

  const { error, loadingFromErp, loadingFromCache } = useMemo(() => {
    switch (searchParams.type) {
      case 'imported':
        return {
          error: importedError,
          loadingFromCache: isLoadingImportedQuery || isSearching,
          loadingFromErp: false,
        }
      case 'integration':
        return {
          error: integrationError,
          loadingFromCache: false,
          loadingFromErp: isLoadingIntegrationQuery || isSearching,
        }
    }
  }, [
    importedError,
    integrationError,
    isLoadingImportedQuery,
    isLoadingIntegrationQuery,
    isSearching,
    searchParams,
  ])

  const searchImportedVendorInvoices = useCallback(
    async (
      startDate: Moment | null,
      endDate: Moment | null
    ): Promise<IntegrationVendorInvoice[]> => {
      if (searchParams.type !== 'imported' || !startDate || !endDate) {
        return []
      }

      setIsSearching(true)

      const { data } = await getImportedVendorInvoices({
        variables: {
          input: {
            contractId: searchParams.contractId,
            vendorId,
            companyIntegrationId: searchParams.companyIntegrationId,
            startDate: startDate.format(DAY_FORMAT),
            endDate: endDate.format(DAY_FORMAT),
          },
        },
      })
      const invoices = data ? [...data.importedVendorInvoices] : []
      const invoicesWithIntegrationInvoiceId = invoices.map((invoice) => {
        // If the bulk ap data comes from excel, some fields like `integrationInvoiceId` are
        // undefined. `integrationInvoiceId` is only referenced to perform a diff on which invoices
        // are selected when new data is uploaded, so we'll use the data available to us to create
        // an ID for the excel invoice.
        const integrationInvoiceId =
          invoice.integrationInvoiceId ??
          `${invoice.amount}-${invoice.invoiceDate}-${invoice.vendorName}`

        return {
          ...invoice,
          integrationInvoiceId,
          integrationVendorId: invoice.integrationVendorId ?? '',
          integrationVendorCode: invoice.integrationVendorCode ?? '',
          integrationProjectId: invoice.integrationProjectId ?? '',
        }
      })
      // Delay to simulate loading state
      await delay(250)
      setIsSearching(false)
      return invoicesWithIntegrationInvoiceId
    },
    [getImportedVendorInvoices, searchParams, vendorId]
  )

  const searchIntegrationVendorInvoices = useCallback(
    async (startDate: Moment | null, endDate: Moment | null) => {
      if (integration === null) {
        console.error('Integration was not provided')
        return []
      }
      if (searchParams.type !== 'integration') {
        return []
      }

      const doesIntegrationSupportDateFilter = supportsVendorInvoicesDateRangeFilter(
        integration.type
      )

      let newFilteredInvoices: IntegrationVendorInvoice[] | null = null
      if (doesIntegrationSupportDateFilter) {
        // Set filtered invoices to null while search is being executed
        newFilteredInvoices = null

        // If the integration supports filtering by date range, execute a new query for each search
        const { data } = await getIntegrationVendorInvoices({
          variables: {
            input: {
              integrationId: integration.id,
              vendorId,
              startDate: startDate?.format(DAY_FORMAT),
              endDate: endDate?.format(DAY_FORMAT),
            },
          },
        })

        newFilteredInvoices = data ? [...data.integrationVendorInvoices] : []
      } else {
        let allInvoices = allVendorInvoices
        setIsSearching(true)
        newFilteredInvoices = null

        // If the integration doesn't support filtering by date range, only execute the query once and
        // cache the result. If it has already been executed, search the cached data by date range.
        if (!hasRequestedInvoices) {
          const { data } = await getIntegrationVendorInvoices({
            variables: { input: { integrationId: integration.id, vendorId } },
          })
          if (data) {
            allInvoices = [...data.integrationVendorInvoices]
            setAllVendorInvoices(allInvoices)
            setHasRequestedInvoices(true)
          }
        }

        const filteredInvoices = allInvoices.filter((invoice) => {
          const invoiceDate = moment.tz(invoice.invoiceDate, timeZone)
          return (
            (!startDate || startDate.isSameOrBefore(invoiceDate, 'date')) &&
            (!endDate || endDate.isSameOrAfter(invoiceDate, 'date'))
          )
        })

        // Add brief artificial loading state so it's clear a search happened
        await delay(500)

        newFilteredInvoices = filteredInvoices
        setIsSearching(false)
      }

      setFilteredVendorInvoices(newFilteredInvoices)
      return newFilteredInvoices
    },
    [
      allVendorInvoices,
      getIntegrationVendorInvoices,
      hasRequestedInvoices,
      integration,
      searchParams.type,
      timeZone,
      vendorId,
    ]
  )

  const searchVendorInvoices = useCallback(
    async (startDate: Moment | null, endDate: Moment | null) => {
      switch (searchParams.type) {
        case 'imported':
          return searchImportedVendorInvoices(startDate, endDate)
        case 'integration':
          return searchIntegrationVendorInvoices(startDate, endDate)
      }
    },
    [searchImportedVendorInvoices, searchIntegrationVendorInvoices, searchParams.type]
  )

  return [
    searchVendorInvoices,
    { invoices: filteredVendorInvoices, loadingFromCache, loadingFromErp, error },
  ] as const
}

export function getIntegrationInvoiceAmount(
  invoice: Pick<IntegrationVendorInvoice, 'amount' | 'retentionAmount'>
) {
  return invoice.amount - invoice.retentionAmount
}

export function getIntegrationInvoiceAmountPaid(
  invoice: Pick<IntegrationVendorInvoice, 'amountPaid'>
) {
  return invoice.amountPaid
}

export function getIntegrationInvoiceAmountDue(
  invoice: Pick<
    IntegrationVendorInvoice,
    'isFullyPaid' | 'amount' | 'amountPaid' | 'retentionAmount'
  >
) {
  // NOTE this was added to handle discounts on invoices
  const amountDue = invoice.isFullyPaid
    ? 0
    : getIntegrationInvoiceAmount(invoice) - getIntegrationInvoiceAmountPaid(invoice)
  return amountDue
}

export function getLienWaiverInvoiceAmountForCategory(
  invoice: IntegrationVendorInvoice,
  category: LienWaiverCategory
) {
  switch (category) {
    case LienWaiverCategory.CONDITIONAL:
      return getIntegrationInvoiceAmountDue(invoice)
    case LienWaiverCategory.UNCONDITIONAL:
      return getIntegrationInvoiceAmountPaid(invoice)
  }
}

/**
 * Calls a mutation to add or link vendors from an integration to a contract from the integration
 * dialog when pulling invoices from an integration, either for creating lien waivers or updating
 * sworn statement amounts
 */
export async function addOrLinkIntegrationVendors<
  T extends
    | AddIntegrationVendorsToContractMutation
    | AddIntegrationVendorsToSwornStatementContractMutation,
>({
  addIntegrationVendorsToContractMutation,
  integration,
  selectedIntegrationVendorIds,
  sitelineVendorIdByIntegrationVendorId,
  invoices,
  onUpdateInvoices,
  queryStartDate,
  queryEndDate,
}: {
  addIntegrationVendorsToContractMutation: MutationFunction<
    T,
    AddIntegrationVendorsToContractMutationVariables
  >
  integration: MinimalIntegrationProperties
  selectedIntegrationVendorIds: string[]
  sitelineVendorIdByIntegrationVendorId: Record<string, string>
  invoices: IntegrationVendorInvoice[] | null
  onUpdateInvoices: (
    invoices: IntegrationVendorInvoice[] | null,
    vendorContracts: T['addIntegrationVendorsToContract']['vendorContracts'][number][]
  ) => void
  queryStartDate: Moment | null
  queryEndDate: Moment | null
}) {
  await addIntegrationVendorsToContractMutation({
    variables: {
      input: {
        integrationId: integration.id,
        integrationVendors: selectedIntegrationVendorIds.map((integrationVendorId) => ({
          integrationVendorId,
          // If linking the integration vendor to a Siteline vendor, we include the Siteline
          // vendor ID. Otherwise, we leave it null and a new vendor will be created.
          vendorId: _.get(sitelineVendorIdByIntegrationVendorId, integrationVendorId, null),
        })),
      },
    },
    update: (cache, { data }) => {
      if (!data || !invoices) {
        return
      }
      const newVendorContracts = [...data.addIntegrationVendorsToContract.vendorContracts]

      // Create a map from Siteline vendor ID to invoice, based on the vendor contracts returned
      // from the mutation. We'll use this map below to update the invoices in the cache and
      // state, so the changes are immediately reflected in the dialog.
      const sitelineVendorIdByInvoiceId = getVendorIdByInvoiceId(
        invoices,
        newVendorContracts,
        integration.companyIntegration.id
      )

      // For the vendors that were newly added to Siteline, we want to update all their invoices
      // to reference the new Siteline vendor ID. We need to do this in state, for all invoices
      // matching the current search range, and also in the cache, for all invoices that have
      // been returned for the current integration since we may have fetched more invoices than
      // are currently in state.
      const updatedInvoices = invoices.map((invoice) => {
        const sitelineVendorId = _.get(
          sitelineVendorIdByInvoiceId,
          invoice.integrationInvoiceId,
          invoice.sitelineVendorId
        )
        return { ...invoice, sitelineVendorId }
      })
      const queryData: GetIntegrationVendorInvoicesQuery | null = cache.readQuery({
        query: GetIntegrationVendorInvoicesDocument,
        variables: {
          input: {
            integrationId: integration.id,
            vendorId: null,
            startDate: queryStartDate?.format(DAY_FORMAT),
            endDate: queryEndDate?.format(DAY_FORMAT),
          },
        },
      })
      if (queryData) {
        const cachedSitelineVendorIdByInvoiceId = getVendorIdByInvoiceId(
          [...queryData.integrationVendorInvoices],
          newVendorContracts,
          integration.companyIntegration.id
        )
        queryData.integrationVendorInvoices.forEach((invoice) => {
          const sitelineVendorId = _.get(
            cachedSitelineVendorIdByInvoiceId,
            invoice.integrationInvoiceId,
            invoice.sitelineVendorId
          )
          cache.modify<WritableDeep<IntegrationVendorInvoice>>({
            id: cache.identify(invoice),
            fields: {
              sitelineVendorId() {
                return sitelineVendorId
              },
            },
          })
        })
      }

      onUpdateInvoices(updatedInvoices, newVendorContracts)
    },
  })
}

/**
 * When invoices are updated, as part of searching for invoices from an integration to create
 * lien waivers or update sworn statements, we update the map of selected invoices by vendor
 * contract. By default, we select all invoices, unless they were previously in the map and
 * de-selected by the user.
 */
export function updateSelectedIntegrationInvoicesByVendorContractId({
  oldInvoices,
  newInvoices,
  initialVendorContracts,
  newVendorContracts,
  selectedIntegrationInvoicesByVendorContractId,
  isExcelInvoiceData,
}: {
  oldInvoices: IntegrationVendorInvoice[]
  newInvoices: IntegrationVendorInvoice[]
  initialVendorContracts: MinimalVendorContractProperties[]
  newVendorContracts: MinimalVendorContractProperties[]
  selectedIntegrationInvoicesByVendorContractId: Record<string, IntegrationVendorInvoice[]>
  isExcelInvoiceData: boolean
}) {
  return _.chain(newVendorContracts)
    .map((vendorContract) => {
      const vendorContractInvoices = newInvoices.filter((invoice) => {
        // For Excel data, we don't have access to `invoice.sitelineVendorId`, so we have to do
        // name matching. Because we only have access to the name of the vendor in this case,
        // we should match invoices to upper tier vendor contracts (which are added during
        // excel import)
        const isLowerTierVendorContract = vendorContract.lowerTierTo !== null
        const isMatchingVendorContract =
          invoice.vendorName.toLowerCase() === vendorContract.vendor.name.toLocaleLowerCase()
        if (isExcelInvoiceData && (!isMatchingVendorContract || isLowerTierVendorContract)) {
          return false
        }
        // Include only invoices for this vendor contract
        if (!isExcelInvoiceData && invoice.sitelineVendorId !== vendorContract.vendor.id) {
          return false
        }
        // There are 3 cases where we automatically select an invoice:
        // 1. If the invoice was not previously in the invoice list, i.e. we've expanded our
        // search and this invoice is from a new date
        // 2. If the invoice did not previously have a Siteline vendor ID, i.e. we just added
        // the vendor to Siteline
        // 3. If the invoice's Siteline vendor ID didn't previously match a vendor contract,
        // i.e. we've just added the vendor to the contract
        const oldInvoice = oldInvoices.find(
          (oldInvoice) => oldInvoice.integrationInvoiceId === invoice.integrationInvoiceId
        )
        // Case #1
        if (!oldInvoice) {
          return true
        }
        // Case #2
        if (!oldInvoice.sitelineVendorId) {
          return true
        }
        // Case #3
        const hadVendorContract = initialVendorContracts.some(
          (vendorContract) => vendorContract.vendor.id === oldInvoice.sitelineVendorId
        )
        if (!hadVendorContract) {
          return true
        }
        // If this invoice was previously in the list, we include it only if it was previously
        // selected. If it wasn't previously selected, we can assume the user actively
        // de-selected it and we don't want to revert that action.
        const oldVendorContractInvoices = _.get(
          selectedIntegrationInvoicesByVendorContractId,
          vendorContract.id,
          []
        )
        return oldVendorContractInvoices.some(
          (oldInvoice) => oldInvoice.integrationInvoiceId === invoice.integrationInvoiceId
        )
      })
      return [vendorContract.id, vendorContractInvoices]
    })
    .fromPairs()
    .value()
}
