import { attachmentTypes } from 'siteline-common-all'
import { v4 as uuidv4 } from 'uuid'
import { getCurrentUserAuthorization } from '../../client'
import { attachmentsBaseUrl } from '../config/constants'

export async function getAttachmentPdf(params: attachmentTypes.AttachmentPdfParams): Promise<Blob> {
  const url = new URL(attachmentsBaseUrl)
  url.pathname = '/pdf'
  url.search = new URLSearchParams(params).toString()

  const response = await fetch(url.toString(), {
    headers: {
      authorization: await getCurrentUserAuthorization(),
    },
  })

  if (response.status !== 200) {
    throw new Error(`Could not get attachment PDF for: ${params}`)
  }
  return response.blob()
}

const UPLOADING_ID_PREFIX = 'UPLOADING:'

/**
 * We need a way to filter to differentiate a pending upload and a completed upload,
 * which boils down to whether the data is optimistic or not in an attachment card component.
 * Adding an extra field `isOptimistic` does not work, because apollo filters out
 * non-selected fields.
 * This works around that in a hacky way by adding a `UPLOADING:` prefix to the
 * attachment id, which we then look for in the card component.
 */
export function getAttachmentUploadingId(): string {
  return `${UPLOADING_ID_PREFIX}${uuidv4()}`
}

/**
 * Returns whether an attachment ID is a temporary ID while the attachment is uploading.
 * Used to disable updates/deletions during uploads.
 */
export function isAttachmentUploadingId(id: string): boolean {
  return id.startsWith(UPLOADING_ID_PREFIX)
}
