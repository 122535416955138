import { Autocomplete, TextField } from '@mui/material'
import { FilterOptionsState } from '@mui/material/useAutocomplete'
import _ from 'lodash'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

interface Option {
  title: string
  inputValue?: string
}

interface AutocompleteOrAddProps {
  options: string[]
  placeholder?: string
  type: string
  values: { [key: string]: string | null }
  freeSolo: boolean
  handleInputChange: (type: string, value: string | null) => void
  autoFocus?: boolean
}

export function AutocompleteOrAdd({
  options,
  placeholder,
  type,
  values,
  freeSolo,
  handleInputChange,
  autoFocus,
}: AutocompleteOrAddProps) {
  const { t } = useTranslation()

  const property = values[type]
  if (property === null) {
    throw new Error('Cannot have null value for autocomplete')
  }

  const value: Option = { title: property, inputValue: property }

  const existingOptions: Option[] = options.sort().map((option) => {
    return { title: option, inputValue: option }
  })

  return (
    <Autocomplete
      freeSolo={freeSolo}
      // Autoselect the top available option if freeSolo isn't allowed
      autoSelect={!freeSolo}
      options={existingOptions}
      value={value}
      autoHighlight
      isOptionEqualToValue={(option, typedValue) => {
        if (!freeSolo && typedValue.inputValue === '') {
          if (existingOptions.length === 0) {
            return false
          }
          return option.title === existingOptions[0].title
        }
        return option.inputValue === typedValue.inputValue
      }}
      onChange={(event: ChangeEvent<unknown>, newValue: string | Option | null | undefined) => {
        if (typeof newValue === 'string') {
          handleInputChange(type, newValue)
        } else if (_.isNil(newValue)) {
          handleInputChange(type, null)
        } else if (newValue.inputValue) {
          handleInputChange(type, newValue.inputValue)
        }
      }}
      onInputChange={
        // If able to enter input freely, always update the value when the input changes
        freeSolo
          ? (event: ChangeEvent<unknown>, newValue: string) => {
              handleInputChange(type, newValue)
            }
          : undefined
      }
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          type={type}
          autoFocus={autoFocus}
        />
      )}
      filterOptions={(options: Option[], state: FilterOptionsState<Option>) => {
        // The input value is only passed in the state when `onInputChanged` isn't
        // set for freeSolo
        const inputValue = freeSolo ? value.inputValue : state.inputValue
        if (!inputValue) {
          return options
        }
        const filteredOptions = options.filter((option) =>
          option.title.toLowerCase().includes(inputValue.toLowerCase())
        )
        if (freeSolo) {
          // If manually typed options are allowed, show "Add 'new option'" as the final option
          filteredOptions.push({
            inputValue,
            title: t(
              'projects.subcontractors.pay_app.submit.new_contact.new_contact_headers.add_new',
              { inputValue }
            ),
          })
        } else if (filteredOptions.length === 0) {
          // If no options are available and manually typed isn't allowed, indicate that the option
          // is not found
          filteredOptions.push({
            title: t(
              'projects.subcontractors.pay_app.submit.new_contact.new_contact_headers.not_found'
            ),
            inputValue: '',
          })
        }
        return filteredOptions
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        // If this is a new option, just the input value
        if (!_.isUndefined(option.inputValue)) {
          return option.inputValue
        }
        // Regular option
        return option.title
      }}
    />
  )
}
