import moment, { Moment } from 'moment-timezone'
import {
  getFilename,
  getLienWaiverFilenameFromComponents,
  getPayAppFilenameFromComponents,
} from 'siteline-common-all'
import { getPayAppMonth } from 'siteline-common-web'
import {
  ChangeOrderRequestProperties,
  LienWaiverProperties,
  LienWaiverType,
  MinimalProjectProperties,
  PayAppProperties,
} from '../graphql/apollo-operations'

type ProjectForFilename = Pick<MinimalProjectProperties, 'name' | 'projectNumber'>

/** Removes invalid characters for a string to be used as a filename */
export function sanitizeFilenameString(filename: string): string {
  return filename.replace(/[\W_]/g, '')
}

/**
 * Returns a filename for a pay app PDF package.
 * Example: 1234_DRAFT_PayApp_EmpireStateBuilding_ABCPlumbers_Feb2020.pdf
 * Example: 1234_DRAFT_Invoice_EmpireStateBuilding_ABCPlumbers_Feb2020.pdf
 */
export function getPayAppFilename(payApp: PayAppProperties, project: ProjectForFilename): string {
  return getPayAppFilenameFromComponents({
    companyName: payApp.contract.company.name,
    billingType: payApp.billingType,
    projectName: project.name,
    projectNumber: project.projectNumber,
    status: payApp.status,
    billingEnd: moment.tz(payApp.billingEnd, payApp.timeZone),
    extension: 'pdf',
  })
}

type GetInvoiceFilenameParams = {
  billingEnd: Moment
  companyName: string
  projectName: string
  projectNumber: string
}

/**
 * Returns a filename for a manually uploaded invoice PDF file.
 * Example: 1234_Invoice_EmpireStateBuilding_ABCPlumbers_Feb2020.pdf
 */
export function getInvoiceFilename({
  projectNumber,
  projectName,
  companyName,
  billingEnd,
}: GetInvoiceFilenameParams): string {
  const date = billingEnd.clone().format('MMMYYYY')
  return getFilename([projectNumber, 'Invoice', projectName, companyName, date], 'pdf')
}

/**
 * Returns a filename for a backup PDF package.
 * Example: Backup_EmpireStateBuilding_ABCPlumbers_Feb2020.pdf
 */
export function getBackupFilename(payApp: PayAppProperties, project: ProjectForFilename): string {
  const timeZone = payApp.timeZone
  const date = getPayAppMonth(payApp, timeZone).format('MMMYYYY')
  return getFilename(['Backup', project.name, payApp.contract.company.name, date], 'pdf')
}

/**
 * Returns a filename for a lien-waiver PDF package.
 * Example: UPLW_ABCPlumbers_EmpireStateBuilding_Mar2020.pdf
 */
export function getLienWaiverFilename(
  lienWaiver: Pick<LienWaiverProperties, 'date' | 'type' | 'claimant'>,
  projectName: string,
  timeZone: string
): string {
  return getLienWaiverFilenameFromComponents({
    type: lienWaiver.type,
    claimant: lienWaiver.claimant,
    projectName,
    date: moment.tz(lienWaiver.date, timeZone),
  })
}

/**
 *
 * @param type Returns a filename for a group of vendor lien waivers.
 * Example: EmpireStateBuilding_VendorLienWaivers_Mar2020.pdf
 */
export function getVendorLienWaiversFilename(project: ProjectForFilename, month: Moment): string {
  const dateString = month.format('MMMYYYY')
  return getFilename([project.name, 'VendorLienWaivers', dateString], 'pdf')
}

/**
 * Returns a filename for a single vendor legal document.
 * Example: CertifiedPayroll_EmpireStateBuilding_ABCPlumbers_Mar2020.pdf
 */
export function getVendorLegalDocumentFilename(
  requirementName: string,
  projectName: string,
  vendorName: string,
  date?: moment.Moment
): string {
  const components = [requirementName, projectName, vendorName]
  if (date) {
    components.push(date.format('MMMYYYY'))
  }
  return getFilename(components, 'pdf')
}

/**
 * Returns a filename for a lien-waiver preview package.
 * Example: UPLW_ABCPlumbers_EmpireStateBuilding_Mar2020.pdf
 */
export function getLienWaiverPreviewFilename(
  type: LienWaiverType,
  project: ProjectForFilename,
  claimant: string,
  date: moment.Moment
): string {
  return getLienWaiverFilenameFromComponents({ type, claimant, projectName: project.name, date })
}

/**
 * Returns a filename for a legal requirement PDF package.
 * Example: VendorDocuments_EmpireStateBuilding_ABCPlumbers_Mar2020.pdf
 */
export function getVendorDocumentsFilename(
  projectName: string,
  companyName: string,
  date?: moment.Moment
): string {
  const components = ['VendorDocuments', projectName, companyName]
  if (date) {
    components.push(date.format('MMMYYYY'))
  }
  return getFilename(components, 'pdf')
}

/**
 * Returns a filename for the monthly data report
 * Example: ABCPlumbers_Apr2021.pdf
 */
export function getMonthlyReportFilename(companyName: string, date: moment.Moment): string {
  const dateStr = date.format('MMMYYYY')
  return getFilename([companyName, dateStr], 'pdf')
}

/**
 * Returns a filename for a change order request PDF package.
 * Example: COR_1234_ABCPlumbers.pdf
 */
export function getChangeOrderRequestFilename(
  changeOrderRequest: Pick<
    ChangeOrderRequestProperties,
    'generalContractorNumber' | 'internalNumber'
  >,
  companyName: string
): string {
  const changeOrderNumber =
    changeOrderRequest.generalContractorNumber ?? changeOrderRequest.internalNumber
  return getFilename(['COR', changeOrderNumber ?? '', companyName], 'pdf')
}

/**
 * Returns a filename for a change order log PDF.
 * Example: COLog_EmpireStateBuilding_06062024.pdf
 */
export function getChangeOrderLogFilename(projectName: string, date: moment.Moment): string {
  const dateStr = date.format('DDMMMYYYY')
  return getFilename(['COLog', projectName, dateStr], 'pdf')
}
