import _ from 'lodash'
import {
  getLineItemMatches,
  getScoringForIntegration,
  IntegrationType,
  LineItemIdentifier,
  MatchResult,
  safeDivide,
} from 'siteline-common-all'
import {
  IntegrationSovLineItem,
  SovLineItemProgressProperties,
} from '../../graphql/apollo-operations'

export type BilledLineItem = {
  progressBilled: number
  retentionReleased: number
  retention: number
  sitelineTaxGroupId: string | null
}
export type BilledLineItemMap = {
  [integrationLineItemId: string]: BilledLineItem
}
export const EMPTY_BILLED_LINE_ITEM: BilledLineItem = {
  progressBilled: 0,
  retentionReleased: 0,
  retention: 0,
  sitelineTaxGroupId: null,
}
type Source = LineItemIdentifier & { sovLineItemId: string }
type Destination = LineItemIdentifier & { integrationLineItemId: string }

/**
 * We want to look for scores that are greater than this number, which means matches will be one of
 * the following:
 *
 * 1. code and totalValue match (0.65)
 * 2. name matches (1)
 * 3. name and code and/or totalValue match (1.25, 1.4, 1.65)
 *
 * The following will not be considered strong enough for a match:
 *
 * 1. code matches (0.25)
 * 2. totalValue matches (0.4)
 */
export const PAY_APP_LINE_ITEMS_MIN_SCORE = 0.5

export function getErpIntegrationLineItemMatchResult(
  integrationType: IntegrationType,
  payAppLineItems: readonly SovLineItemProgressProperties[],
  sovLineItems: readonly IntegrationSovLineItem[]
): MatchResult<Source, Destination> {
  const sourceLineItems = payAppLineItems.map((lineItem) => ({
    sovLineItemId: lineItem.sovLineItem.id,
    code: lineItem.sovLineItem.code,
    name: lineItem.sovLineItem.name,
    totalValue: lineItem.totalValue,
  }))
  const destinationLineItems = sovLineItems.map((lineItem) => ({
    integrationLineItemId: lineItem.integrationLineItemId ?? '',
    code: lineItem.code,
    name: lineItem.description,
    totalValue: lineItem.scheduledValue,
  }))
  const scoring = getScoringForIntegration(integrationType)
  return getLineItemMatches(sourceLineItems, destinationLineItems, scoring)
}

/**
 * Uses our standard line item matching algorithm to match line items in Siteline to those in the ERP.
 * We then return a new BilledLineItemMap with amounts billed against matching line items. We will
 * bill up to the most that can be billed based on scheduled value and what was previously billed
 * to date.
 */
export function allocateMatchingLineItems(
  integrationType: IntegrationType,
  payAppLineItems: readonly SovLineItemProgressProperties[],
  sovLineItems: readonly IntegrationSovLineItem[]
): BilledLineItemMap {
  const newBilledMap: BilledLineItemMap = {}
  const { matches } = getErpIntegrationLineItemMatchResult(
    integrationType,
    payAppLineItems,
    sovLineItems
  )
  matches.forEach((match) => {
    const payAppLineItem = payAppLineItems.find(
      (lineItem) => lineItem.sovLineItem.id === match.source.sovLineItemId
    )
    const sovLineItem = sovLineItems.find(
      (lineItem) => lineItem.integrationLineItemId === match.destination.integrationLineItemId
    )

    // If the score is below our min score, then ignore and continue processing
    if (
      match.score < PAY_APP_LINE_ITEMS_MIN_SCORE ||
      !payAppLineItem ||
      !sovLineItem?.integrationLineItemId
    ) {
      return
    }

    newBilledMap[sovLineItem.integrationLineItemId] = {
      progressBilled: payAppLineItem.currentBilled,
      retentionReleased: payAppLineItem.previousRetentionBilled,
      retention: payAppLineItem.currentRetention,
      sitelineTaxGroupId: payAppLineItem.sovLineItem.taxGroup?.id ?? null,
    }
  })

  return newBilledMap
}

/**
 * Finds the first line item that still has room left on it, given our current billedMap. If no line
 * item is found, then we return undefined.
 */
function getFirstSovLineItemNotMaxedOut(
  sovLineItems: readonly IntegrationSovLineItem[],
  billedMap: BilledLineItemMap
): IntegrationSovLineItem | undefined {
  if (sovLineItems.length === 0) {
    throw new Error('No line items were provided')
  }

  return sovLineItems.find((lineItem) => {
    if (!lineItem.integrationLineItemId) {
      return false
    }

    const amountLeftInErp = lineItem.scheduledValue - lineItem.billedToDate
    const map: BilledLineItem = _.get(billedMap, lineItem.integrationLineItemId, {
      ...EMPTY_BILLED_LINE_ITEM,
    })

    // We are checking not equal to 0, which means it could be positive or negative
    return amountLeftInErp - map.progressBilled !== 0
  })
}

/**
 * Allocates first against our standard matching algorithm. Any line items that weren't matched are
 * allocated starting from the first sovLineItem until that line item is billed to 100%, then it
 * will continue on to the next available line item (that has room to bill) until either it has
 * fully billed. In the event Siteline has more to bill than the ERP has, we will allocate it in the
 * very last line item of the ERP Contract.
 */
export function allocateAnywhereLineItems(
  integrationType: IntegrationType,
  payAppLineItems: readonly SovLineItemProgressProperties[],
  sovLineItems: readonly IntegrationSovLineItem[]
): BilledLineItemMap {
  // Base case, make sure we have at least some line items
  if (sovLineItems.length === 0) {
    return {}
  }
  // Base case, make sure all SOV line items have integrationLineItemId's
  const allExist = sovLineItems.every((sovLineItem) => !!sovLineItem.integrationLineItemId)
  if (!allExist) {
    throw new Error('Missing integrationLineItemId on some SOV line items')
  }

  // First, allocate all matching line items
  const newBilledMap = allocateMatchingLineItems(integrationType, payAppLineItems, sovLineItems)

  // Fetch all matches and figure out which line items still need to be allocated
  const { matches } = getErpIntegrationLineItemMatchResult(
    integrationType,
    payAppLineItems,
    sovLineItems
  )
  const lineItemsNotAllocated = payAppLineItems.filter((lineItem) => {
    const matchFound = matches.find(
      (match) => lineItem.sovLineItem.id === match.source.sovLineItemId
    )

    // If not found in matches, it means we haven't billed for it yet. Include in list to be billed
    if (!matchFound) {
      return true
    }

    // Include line item if the score is below the MIN_SCORE (means we still need to allocate it)
    return matchFound.score < PAY_APP_LINE_ITEMS_MIN_SCORE
  })

  let currentPayAppLineItemIndex = 0

  // For each line item, allocate billed & retention held up to scheduledValue on that line,
  // carrying over to the next available line item.  If there is still money remaining to be
  // allocated (rare edge case), allocate to the last line item
  while (currentPayAppLineItemIndex < lineItemsNotAllocated.length) {
    const payAppLineItem = lineItemsNotAllocated[currentPayAppLineItemIndex]

    let remainderToBill = payAppLineItem.currentBilled
    let remainderRetentionReleased = payAppLineItem.previousRetentionBilled
    let remainderRetention = payAppLineItem.currentRetention

    // Get first available sovLineItem. If there's enough to bill, bill it out on that line item
    while (remainderToBill !== 0) {
      let isLastSovLineItem = false
      let sovLineItem = getFirstSovLineItemNotMaxedOut(sovLineItems, newBilledMap)
      if (!sovLineItem) {
        sovLineItem = sovLineItems[sovLineItems.length - 1]
        isLastSovLineItem = true
      }

      // Ignore lint, we know that integrationLineId's exist from assertion above
      const integrationLineItemId = sovLineItem.integrationLineItemId!
      const map: BilledLineItem = _.get(newBilledMap, integrationLineItemId, {
        ...EMPTY_BILLED_LINE_ITEM,
      })
      const amountAvailableToBill =
        sovLineItem.scheduledValue - sovLineItem.billedToDate - map.progressBilled

      if (isLastSovLineItem || Math.abs(remainderToBill) <= Math.abs(amountAvailableToBill)) {
        newBilledMap[integrationLineItemId] = {
          progressBilled: map.progressBilled + remainderToBill,
          retentionReleased: map.retentionReleased + remainderRetentionReleased,
          retention: map.retention + remainderRetention,
          sitelineTaxGroupId: payAppLineItem.sovLineItem.taxGroup?.id ?? null,
        }

        remainderToBill = 0
        remainderRetention = 0
      } else {
        // If there isn't enough, bill out what we can and move to the next sovLineItem
        const ratio = safeDivide(amountAvailableToBill, remainderToBill, 0)
        const retentionToBill = _.round(remainderRetention * ratio, 0)
        const retentionReleasedToBill = _.round(remainderRetentionReleased * ratio, 0)

        newBilledMap[integrationLineItemId] = {
          progressBilled: map.progressBilled + amountAvailableToBill,
          retentionReleased: map.retentionReleased + retentionReleasedToBill,
          retention: map.retention + retentionToBill,
          sitelineTaxGroupId: payAppLineItem.sovLineItem.taxGroup?.id ?? null,
        }

        remainderToBill -= amountAvailableToBill
        remainderRetention -= retentionToBill
        remainderRetentionReleased -= retentionReleasedToBill
      }
    }

    // Once billing has been fully allocated, move to the next payAppLineItem
    currentPayAppLineItemIndex++
  }

  return newBilledMap
}

type AllocateAnywhereFreeformProps = {
  payAppNet: number
  payAppRetention: number
  payAppRetentionReleased: number
  sovLineItems: readonly IntegrationSovLineItem[]
  billedMap: BilledLineItemMap
}

/**
 * Allocates payAppNet and payAppRetention the first place it sees, starting from the first line
 * item. This will continue to allocate until the line item is billed to 100%, then it will continue
 * on to the next available line item (that has room to bill) until either it has fully billed or
 * there is no more room left to bill.
 */
export function allocateAnywhereFreeform({
  payAppNet,
  payAppRetention,
  payAppRetentionReleased,
  sovLineItems,
  billedMap,
}: AllocateAnywhereFreeformProps): BilledLineItemMap {
  let totalAllocated = _.sum(Object.values(billedMap).map((map) => map.progressBilled))
  let totalRetention = _.sum(Object.values(billedMap).map((map) => map.retention))
  let totalRetentionReleased = _.sum(Object.values(billedMap).map((map) => map.retentionReleased))
  const newBilledMap = _.cloneDeep(billedMap)
  let currentLineItemIndex = 0

  // Continue to iterate until we've allocated everything or we've run out of space to allocate
  while (totalAllocated < payAppNet && currentLineItemIndex < sovLineItems.length) {
    let amountToBill = payAppNet - totalAllocated
    let retentionToBill = payAppRetention - totalRetention
    let retentionReleasedToBill = payAppRetentionReleased - totalRetentionReleased
    // If no lineItemId, skip to the next
    const sovLineItem = sovLineItems[currentLineItemIndex]
    if (!sovLineItem.integrationLineItemId) {
      continue
    }
    const currentBilledOnLineItem: BilledLineItem = _.get(
      newBilledMap,
      sovLineItem.integrationLineItemId,
      { ...EMPTY_BILLED_LINE_ITEM }
    )
    const amountRemainingOnLineItem =
      sovLineItem.scheduledValue - sovLineItem.billedToDate - currentBilledOnLineItem.progressBilled

    if (amountToBill > amountRemainingOnLineItem) {
      const ratio = safeDivide(amountRemainingOnLineItem, amountToBill, 0)
      amountToBill = amountRemainingOnLineItem
      retentionToBill = _.round(retentionToBill * ratio, 0)
      retentionReleasedToBill = _.round(retentionReleasedToBill * ratio, 0)
    }

    newBilledMap[sovLineItem.integrationLineItemId] = {
      progressBilled: currentBilledOnLineItem.progressBilled + amountToBill,
      retentionReleased: currentBilledOnLineItem.retentionReleased + retentionReleasedToBill,
      retention: currentBilledOnLineItem.retention + retentionToBill,
      sitelineTaxGroupId: null,
    }
    totalAllocated += amountToBill
    totalRetention += retentionToBill
    totalRetentionReleased += retentionReleasedToBill
    currentLineItemIndex++
  }

  return newBilledMap
}
