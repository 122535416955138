import { CompanyUsersQuery, LocationInput, LocationProperties } from '../graphql/apollo-operations'

type MinimalOfficeLocation = Pick<LocationProperties, 'nickname' | 'street1' | 'city'>

/** Returns a company office's short-form name */
export function getOfficeName(
  office: MinimalOfficeLocation | Pick<LocationInput, 'nickname' | 'street1' | 'city'>
) {
  return office.nickname || office.street1 || office.city
}

type SortByOfficeParams<T> = {
  /** List to be sorted */
  list: T[]
  /** Function used on each item pre-sort to access relevant sorting key */
  sortBy: (item: T) => MinimalOfficeLocation
  /** Order the list should be sorted in. Note: if bypassSort returns true, sort order will be ignored */
  order: 'asc' | 'desc'
}

/**
 * Sorts a list by office name. Since some customers name their offices numerically, we want
 * to sort in the right order for both numbered and descriptive office nicknames.
 */
export function sortByOffice<T>({ list, sortBy, order }: SortByOfficeParams<T>) {
  const collator = new Intl.Collator([], { numeric: true })
  const sorted = [...list]
  sorted.sort((a, b) => {
    const res = collator.compare(getOfficeName(sortBy(a)), getOfficeName(sortBy(b)))
    return order === 'asc' ? res : res * -1
  })
  return sorted
}

type CompanyUser = CompanyUsersQuery['companyUsers'][number]
type UsersByLocationId = Partial<Record<string, CompanyUser[]>>

/** Builds an object of company users organized by their primary office location id */
export function buildUsersByOfficeIdMap(companyUsersData?: CompanyUsersQuery) {
  const usersByLocation: UsersByLocationId = {}

  if (companyUsersData) {
    companyUsersData.companyUsers.forEach((user) => {
      const { id: locationId } = user.companyLocation
      usersByLocation[locationId] ??= []
      usersByLocation[locationId].push(user)
    })
  }

  return usersByLocation
}
