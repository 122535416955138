import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Card, CardContent, IconButton, LinearProgress, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { ReactElement, ReactNode, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SitelineText,
  colorStyles,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { clampToLines } from 'siteline-common-web/src/utils/CSS'
import {
  PendingFile,
  useBackupFilesDropHandler,
} from '../../components/billing/backup/attachments/FileDragUpload'
import { Z_INDEX } from '../themes/Main'
import { useSitelineDropzone } from '../util/ReactDropzone'

// If file is larger than 10MB, we'll show a warning
const LARGE_FILE_THRESHOLD = 10_000_000

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    position: 'relative',
    '& .deleteIcon': {
      visibility: 'hidden',
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      backgroundColor: colors.grey20,
      borderRadius: '1em',
      padding: theme.spacing(0.25),
      display: 'flex',
      cursor: 'pointer',
      zIndex: Z_INDEX.card,
      transition: theme.transitions.create('transform'),
      '& .MuiSvgIcon-root': {
        color: colors.grey70,
      },
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    '&:hover .deleteIcon': {
      visibility: 'visible',
    },
  },
  card: {
    minHeight: 220,
    borderColor: colors.grey30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    '&.dashed': {
      border: `1px dashed ${colors.grey30}`,
    },
    '&.isDragActive': {
      borderStyle: 'solid',
      borderColor: colors.blue50,
      '& .uploadMedia': {
        backgroundColor: colors.grey10,
      },
    },
  },
  text: {
    // Assiging a 50px height to this section ensures that attachment cards will be more or less the same size.
    // This accommodates the largest possible height for this section without overlap
    height: 50,
  },
  title: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  name: {
    // When there is no subtitle, we allow the title to wrap to 2 lines before cutting off with ellipses
    '&.titleWrap': clampToLines(2),
    // When there is a subtitle, cut off after a single line
    '&:not(.titleWrap)': clampToLines(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    flexGrow: 1,
    padding: theme.spacing(1, 2),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
    },
  },
  lineItemValue: {
    padding: theme.spacing(0, 0, 2),
  },
  footerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  metadata: {
    '& span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiTypography-root:first-child': {
      paddingBottom: theme.spacing(0.5),
    },
  },
  progress: {
    borderRadius: 0,
    marginTop: theme.spacing(-0.5),
  },
  warning: {
    marginLeft: theme.spacing(0.5),
    '& .MuiButtonBase-root.Mui-disabled  .MuiSvgIcon-root': {
      color: colors.grey30,
    },
  },
  warningIcon: {
    color: colors.yellow40,
  },
}))

/** Icon for removing a legal document from the pay app */
function FileCardDeleteIcon({ onDelete }: { onDelete: () => void }) {
  const { t } = useTranslation()

  return (
    <div className="deleteIcon" onClick={onDelete}>
      <Tooltip title={t('projects.subcontractors.pay_app.backup.dont_include')} placement="top">
        <CloseIcon fontSize="small" />
      </Tooltip>
    </div>
  )
}

export type FileCardAction = {
  title: string
  icon: ReactElement
  onClick?: () => void
  iconStyle?: React.CSSProperties
  disabled?: boolean
}

interface SitelineFileCardProps {
  media: ReactElement
  title: string
  titleColor?: keyof typeof colorStyles
  titleEndIcon?: ReactNode
  subtitle?: string | ReactElement
  actions: FileCardAction[]
  footerText?: ReactElement
  isLoading?: boolean
  isUploaded: boolean
  metadata?: string
  onDelete?: () => void
  onUploadFiles?: (files: PendingFile[]) => void
  height?: number

  // Total size in bytes of all uploaded files. Used to show a warning when files are too big.
  totalSize?: number
}

// Displays a "card" of a file, whether that's an attachment or a legal document. Primarily
// used on the invoice backup right now.
export const SitelineFileCard = forwardRef<HTMLDivElement, SitelineFileCardProps>(
  function SitelineFileCard(props, ref) {
    const {
      media,
      title,
      titleEndIcon,
      titleColor,
      subtitle,
      actions,
      footerText,
      isLoading,
      isUploaded,
      metadata,
      onDelete,
      onUploadFiles,
      totalSize = 0,
      height,
    } = props
    const classes = useStyles()
    const snackbar = useSitelineSnackbar()
    const { t } = useTranslation()

    const handleFileDrop = useBackupFilesDropHandler()
    const handleUpload = (files: File[]) => {
      if (!onUploadFiles) {
        return
      }
      const allowedFiles = handleFileDrop(files)
      onUploadFiles(allowedFiles)
    }

    const { getRootProps, getInputProps, isDragActive } = useSitelineDropzone(t, snackbar, {
      onDrop: handleUpload,
      multiple: false,
      noClick: true,
      noKeyboard: true,
    })

    // If file is larger than 10MB, show a warning
    const isLarge = totalSize >= LARGE_FILE_THRESHOLD

    return (
      <div ref={ref} className={classes.root}>
        {onDelete && <FileCardDeleteIcon onDelete={onDelete} />}
        <Card
          variant="outlined"
          className={clsx(classes.card, { dashed: !isUploaded, isDragActive })}
          style={height ? { minHeight: height } : undefined}
          {...(onUploadFiles && getRootProps())}
        >
          {onUploadFiles && <input {...getInputProps()} />}
          {media}
          {isLoading && <LinearProgress className={classes.progress} />}
          <CardContent className={classes.content}>
            <div className={classes.text}>
              <SitelineText
                variant="h4"
                color={titleColor}
                endIcon={titleEndIcon}
                className={classes.title}
              >
                <div className={clsx(classes.name, { titleWrap: !subtitle && !metadata })}>
                  {title}
                </div>
              </SitelineText>
              {subtitle && (
                <SitelineText variant="smallText" color="grey50" className={classes.lineItemValue}>
                  {subtitle}
                </SitelineText>
              )}
            </div>
            <div className={classes.footerText}>
              <div className={classes.actions}>
                {actions.map((action) => (
                  <Tooltip title={action.title} placement="top" key={action.title}>
                    <IconButton
                      color="secondary"
                      onClick={action.onClick}
                      style={action.iconStyle}
                      disabled={action.disabled}
                    >
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </div>
              {footerText}
              {isLarge && (
                <Tooltip title={t('file.large')} placement="top" className={classes.warning}>
                  <InfoOutlinedIcon className={classes.warningIcon} />
                </Tooltip>
              )}
            </div>
            {metadata && (
              <div className={classes.metadata}>
                <SitelineText variant="smallText" color="grey50">
                  {metadata}
                </SitelineText>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    )
  }
)
