import { ApolloCache, ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { StoreObject, getMainDefinition } from '@apollo/client/utilities'
import { SentryLink } from 'apollo-link-sentry'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { createClient } from 'graphql-ws'
import _ from 'lodash'
import pThrottle from 'p-throttle'
import { evictWithGc, identifyUser } from 'siteline-common-web'
import { config as appConfig, firebaseConfig } from './common/config/constants'
import {
  GetPaginatedCashForecastContractsInput,
  GetPaginatedContractsInput,
  GetPaginatedVendorsInput,
  PaginatedVendorContractsInput,
} from './common/graphql/apollo-operations'

// Throttle instance that limits the number of concurrent graphql calls to 5 per second
const throttle = pThrottle({ limit: 5, interval: 1000 })

const wsLink = new GraphQLWsLink(
  createClient({
    url: appConfig.url.SUBSCRIPTIONS_URL,
    connectionParams: async () => ({
      authToken: await getAuthToken(),
    }),
    lazy: true,
  })
)

/**
 * We disable the throttling on certain queries that we expect to be called en masse in quick
 * succession and don't want to slow down. These query names must be kept in sync with the
 * actual queries used.
 */
const throttleWhitelist = ['lienWaiversMonthSummary', 'lienWaiversMonth']

const throttledFetch = throttle((input: string | URL | Request, init?: RequestInit) => {
  return window.fetch(input, init)
})

const uploadLink = createUploadLink({
  uri: appConfig.url.GRAPHQL_API_URL,
  headers: { 'Apollo-Require-Preflight': 'true' },
  fetch: (input: string | URL | Request, init?: RequestInit) => {
    const isWhitelisted = throttleWhitelist.some((whitelistedFunction) =>
      _.isString(init?.body) ? init.body.match(whitelistedFunction) : false
    )
    if (isWhitelisted) {
      return window.fetch(input, init)
    }
    return throttledFetch(input, init)
  },
})

// Using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // Split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  uploadLink as unknown as ApolloLink
)

async function getAuthToken(): Promise<string | null> {
  const auth = getAuth(getApp())
  const currentUser = auth.currentUser
  if (!currentUser) {
    return null
  }

  return currentUser.getIdToken()
}

export const getCurrentUserAuthorization = async () => {
  const token = await getAuthToken()
  if (!token) {
    return ''
  }
  return `Bearer ${token}`
}

export const getCurrentUserId = () => {
  const auth = getAuth(getApp())
  const currentUser = auth.currentUser
  return currentUser?.uid
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authLink = setContext(async (_, { headers }: any) => {
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: await getCurrentUserAuthorization(),
    },
  }
})

const sentryLink = new SentryLink({
  setTransaction: false,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
  },
})

// This enforces that developers think about pagination invalidation when adding new filters.
// For each field, specify whether it should invalidate the paginated list.
// Typically this is true for all filters and sort keys, false for everything related to pagination (limit, cursor).
const paginatedContractInvalidation: {
  [key in keyof Required<GetPaginatedContractsInput>]: boolean
} = {
  cursor: false,
  limit: false,
  companyId: true,
  month: true,
  billingType: true,
  billingTypes: true,
  contractStatus: true,
  leadPMIds: true,
  generalContractorIds: true,
  officeIds: true,
  projectIds: true,
  templateIds: true,
  payAppStatus: true,
  payAppStatuses: true,
  complianceStatus: true,
  hasBillingForecast: true,
  retentionPayAppsOnly: true,
  search: true,
  sort: true,
  submitVia: true,
  submitViaOptions: true,
  includeAllCompanyContracts: true,
  dueToType: true,
  isProcessingForms: true,
}
const paginatedContractInvalidationKeys = _.keys(
  _.pickBy(paginatedContractInvalidation, (include) => include)
)

const paginatedCashForecastContractInvalidation: {
  [key in keyof Required<GetPaginatedCashForecastContractsInput>]: boolean
} = {
  cursor: false,
  limit: false,
  companyId: true,
  billingTypes: true,
  leadPMIds: true,
  generalContractorIds: true,
  officeIds: true,
  contractIds: true,
  search: true,
  sort: true,
  periodType: true,
  currentDate: true,
}
const paginatedCashForecastContractInvalidationKeys = _.keys(
  _.pickBy(paginatedCashForecastContractInvalidation, (include) => include)
)

const paginatedVendorsInvalidation: { [key in keyof Required<GetPaginatedVendorsInput>]: boolean } =
  {
    cursor: false,
    limit: false,
    companyId: true,
    search: true,
    sort: true,
  }
const paginatedVendorsInvalidationKeys = _.keys(
  _.pickBy(paginatedVendorsInvalidation, (include) => include)
)

const paginatedVendorContractsInvalidation: {
  [key in keyof Required<PaginatedVendorContractsInput>]: boolean
} = {
  cursor: false,
  limit: false,
  month: true,
  year: true,
  category: true,
  contractId: true,
  vendorId: true,
}
const paginatedVendorContractsInvalidationKeys = _.keys(
  _.pickBy(paginatedVendorContractsInvalidation, (include) => include)
)

/** Clears various queries from the Apollo cache. Called after projects are created. */
export function clearBillingHomeCache(cache: ApolloCache<unknown>): void {
  evictWithGc(cache, (evict) => {
    evict({ id: 'ROOT_QUERY', fieldName: 'currentProjects' })
    evict({ id: 'ROOT_QUERY', fieldName: 'contracts' })
    evict({ id: 'ROOT_QUERY', fieldName: 'paginatedContracts' })
    evict({ id: 'ROOT_QUERY', fieldName: 'paginatedCashForecastContracts' })
    evict({ id: 'ROOT_QUERY', fieldName: 'contractsOverview' })
    evict({ id: 'ROOT_QUERY', fieldName: 'companyProjects' })
  })
}

export const makeApolloClient = (links: ApolloLink[] = []) =>
  new ApolloClient({
    link: ApolloLink.from([sentryLink, authLink, ...links, link]),
    cache: new InMemoryCache({
      // We need to specify the merge function because these fields don't have unique IDs that
      // Apollo can automatically figure out how to merge. This is a part of Apollo Client 3.
      typePolicies: {
        Query: {
          fields: {
            notifications: {
              // Don't cache separate results based on any of this field's arguments. Without this
              // being set, each set of notifications are cached separately. This allows us to merge
              // correctly.
              keyArgs: false,
              merge(existing, incoming, { readField }) {
                const notificationRefs = existing ? existing.notifications : []
                const mergedNotificationRefs = [...notificationRefs, ...incoming.notifications]
                // Since these are all references, remove any duplicates that may have been returned
                // from the server so we don't show duplicates in the notification list
                const uniqueNotificationRefs = _.uniqBy(mergedNotificationRefs, (ref) => ref.__ref)
                // Because notifications could come from subscriptions (newer) or from infinite
                // scroll (older), we should sort them based on their createdAt date
                const sortedNotificationRefs = uniqueNotificationRefs.sort((aRef, bRef) => {
                  const aCreatedAt = readField('createdAt', aRef) as string
                  const bCreatedAt = readField('createdAt', bRef) as string
                  return aCreatedAt < bCreatedAt ? 1 : -1
                })
                return {
                  cursor: incoming.cursor,
                  hasNext: incoming.hasNext ?? false,
                  notifications: sortedNotificationRefs,
                }
              },
            },
            payAppEvents: {
              // Cache results based on just the payAppId and remove pagination from the cache keys.
              // The syntax is a little abnormal here, but is based on this comment:
              // https://github.com/apollographql/apollo-client/issues/7314#issuecomment-726331129
              keyArgs: ['input', ['payAppId']],
              merge(existing, incoming, { readField }) {
                const eventRefs = existing ? existing.payAppEvents : []
                const mergedEventRefs = [...eventRefs, ...incoming.payAppEvents]
                // Since these are all references, remove any duplicates that may have been returned
                // from the server so we don't show duplicates in the events list
                const uniqueEventRefs = _.uniqBy(mergedEventRefs, (ref) => ref.__ref)
                // Because events could come from subscriptions (newer) or from infinite
                // scroll (older), we should sort them based on their createdAt date
                const sortedEventRefs = uniqueEventRefs.sort((aRef, bRef) => {
                  const aCreatedAt = readField('createdAt', aRef) as string
                  const bCreatedAt = readField('createdAt', bRef) as string
                  return aCreatedAt < bCreatedAt ? 1 : -1
                })
                return {
                  cursor: incoming.cursor,
                  hasNext: incoming.hasNext ?? false,
                  payAppEvents: sortedEventRefs,
                }
              },
            },
            changeOrderRequestEvents: {
              // Cache results based on just the payAppId and remove pagination from the cache keys.
              // The syntax is a little abnormal here, but is based on this comment:
              // https://github.com/apollographql/apollo-client/issues/7314#issuecomment-726331129
              keyArgs: ['input', ['changeOrderRequestId']],
              merge(existing, incoming, { readField }) {
                const eventRefs = existing ? existing.changeOrderRequestEvents : []
                const mergedEventRefs = [...eventRefs, ...incoming.changeOrderRequestEvents]
                // Since these are all references, remove any duplicates that may have been returned
                // from the server so we don't show duplicates in the events list
                const uniqueEventRefs = _.uniqBy(mergedEventRefs, (ref) => ref.__ref)
                // Because events could come from subscriptions (newer) or from infinite
                // scroll (older), we should sort them based on their createdAt date
                const sortedEventRefs = uniqueEventRefs.sort((aRef, bRef) => {
                  const aCreatedAt = readField('createdAt', aRef) as string
                  const bCreatedAt = readField('createdAt', bRef) as string
                  return aCreatedAt < bCreatedAt ? 1 : -1
                })
                return {
                  cursor: incoming.cursor,
                  hasNext: incoming.hasNext ?? false,
                  changeOrderRequestEvents: sortedEventRefs,
                }
              },
            },
            projectLegalRequirements: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            currentProjects: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            contracts: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },

            // See https://www.apollographql.com/docs/react/pagination/cursor-based/#using-list-element-ids-as-cursors
            paginatedContracts: {
              keyArgs: [
                'input',
                // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
                paginatedContractInvalidationKeys,
              ],
              merge(existing, incoming, { readField, args }) {
                const typedArgs = args as { input: GetPaginatedContractsInput } | null
                if (!typedArgs || !_.isString(typedArgs.input.cursor)) {
                  // If there is no cursor, this is an initial query load and we should overwrite
                  // any existing data in the cache with the new response
                  return incoming
                }
                // If there is a cursor in the args, this is a paginated "fetch more" request and
                // we need to merge the incoming result with the existing data in the cache
                const existingContracts = existing?.contracts ?? []
                const mergedContracts = _.uniqBy(
                  [...existingContracts, ...incoming.contracts],
                  (contract) => readField('id', contract)
                )
                return { ...incoming, contracts: mergedContracts }
              },
            },
            paginatedCashForecastContracts: {
              keyArgs: [
                'input',
                // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
                paginatedCashForecastContractInvalidationKeys,
              ],
              merge(existing, incoming, { readField, args }) {
                const typedArgs = args as { input: GetPaginatedCashForecastContractsInput } | null
                if (!typedArgs || !_.isString(typedArgs.input.cursor)) {
                  // If there is no cursor, this is an initial query load and we should overwrite
                  // any existing data in the cache with the new response
                  return incoming
                }
                // If there is a cursor in the args, this is a paginated "fetch more" request and
                // we need to merge the incoming result with the existing data in the cache
                const existingContracts = existing?.contracts ?? []
                const mergedContracts = _.uniqBy(
                  [...existingContracts, ...incoming.contracts],
                  (contract) => readField('id', contract)
                )
                return { ...incoming, contracts: mergedContracts }
              },
            },
            paginatedVendors: {
              keyArgs: [
                'input',
                // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
                paginatedVendorsInvalidationKeys,
              ],
              merge(existing, incoming, { readField, args }) {
                const typedArgs = args as { input: GetPaginatedVendorsInput } | null
                if (!typedArgs || !_.isString(typedArgs.input.cursor)) {
                  // If there is no cursor, this is an initial query load and we should overwrite
                  // any existing data in the cache with the new response
                  return incoming
                }
                // If there is a cursor in the args, this is a paginated "fetch more" request and
                // we need to merge the incoming result with the existing data in the cache
                const existingVendors = existing?.vendors ?? []
                const mergedVendors = _.uniqBy(
                  [...existingVendors, ...incoming.vendors],
                  (vendor) => readField('id', vendor)
                )
                return { ...incoming, vendors: mergedVendors }
              },
            },
            paginatedVendorContracts: {
              keyArgs: [
                'input',
                // If any of the following filters/sort changes, apollo will create a new paginated list instead of merging.
                paginatedVendorContractsInvalidationKeys,
              ],
              merge(existing, incoming, { readField, args }) {
                const typedArgs = args as { input: PaginatedVendorContractsInput } | null
                if (!typedArgs || !_.isString(typedArgs.input.cursor)) {
                  // If there is no cursor, this is an initial query load and we should overwrite
                  // any existing data in the cache with the new response
                  return incoming
                }
                // If there is a cursor in the args, this is a paginated "fetch more" request and
                // we need to merge the incoming result with the existing data in the cache
                const existingVendorContracts = existing?.vendorContracts ?? []
                const mergedVendorContracts = _.uniqBy(
                  [...existingVendorContracts, ...incoming.vendorContracts],
                  (vendorContract) => readField('id', vendorContract)
                )
                return { ...incoming, vendorContracts: mergedVendorContracts }
              },
            },
            integrationProjectsForOnboarding: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            contractByProjectId: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              },
            },
            getVendorLienWaiversByMonth: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        Project: {
          fields: {
            generalContractor: {
              merge(existing: Record<string, unknown>, incoming: Record<string, unknown> | null) {
                if (incoming === null) {
                  return incoming
                }
                return { ...existing, ...incoming }
              },
            },
            owner: {
              merge(existing: Record<string, unknown>, incoming: Record<string, unknown> | null) {
                if (incoming === null) {
                  return incoming
                }
                return { ...existing, ...incoming }
              },
            },
            payApps: {
              merge: false,
            },
            users: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        LienWaiver: {
          fields: {
            lienWaiverRequests: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            defaultVendorContacts: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            requestAttachments: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        LienWaiverRequest: {
          fields: {
            lienWaiver: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              },
            },
          },
        },
        PayApp: {
          fields: {
            lienWaivers: {
              merge(existing, incoming: StoreObject[]) {
                return incoming
              },
            },
            legalDocuments: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            formValues: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            progress: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            collectionsNotifications: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            emailedContacts: {
              merge: false,
            },
          },
        },
        CashForecastContract: {
          keyFields: ['id', 'paymentPeriods', ['dateRange']],
        },
        Contract: {
          fields: {
            legalRequirementContacts: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            vendorSubmitToContacts: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            defaultGcContacts: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            lienWaiverTemplates: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              },
            },
            lowerTierLienWaiverTemplates: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              },
            },
            skippedPayAppMonths: {
              merge: false,
            },
            users: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            onboardedStatus: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              },
            },
            onboardedProjectVendorsStatus: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              },
            },
            payAppRequirementGroups: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        ChangeOrderRequest: {
          fields: {
            emailedContacts: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            sovLineItems: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        RateTable: {
          fields: {
            groups: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
            items: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        User: {
          fields: {
            blockedEmails: {
              merge: false,
            },
            blockedNotifications: {
              merge: false,
            },
            starredProjects: {
              merge: false,
            },
          },
        },
        Sov: {
          fields: {
            lineItems: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        Vendor: {
          fields: {
            companyIntegrations: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        VendorContract: {
          fields: {
            vendorLegalRequirements: {
              merge(existing, incoming: unknown[]) {
                return incoming
              },
            },
          },
        },
        IntegrationVendorInvoice: {
          keyFields: ['integrationInvoiceId'],
        },
      },
    }),
  })

export const apolloClient = makeApolloClient()

/**
 * Initializes Firebase and Firebase Auth. Sets up a watcher when the user state changes.
 */
export function initializeFirebaseAuth() {
  const apps = getApps()
  if (apps.length > 0) {
    return
  }

  // Initialize Firebase
  initializeApp(firebaseConfig)

  // Watch when the user changes and reset the GraphQL client store
  const auth = getAuth(getApp())
  auth.onAuthStateChanged((user) => {
    if (user) {
      identifyUser({
        id: user.uid,
        email: user.email ?? undefined,
      })
    } else {
      apolloClient.clearStore()
    }
  })
}
