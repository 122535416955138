import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import {
  DEFAULT_AGING_INTERVAL_TYPE,
  DEFAULT_GC_PAYMENT_TERMS,
  TaxCalculationType,
  centsToDollars,
  getDefaultInvoiceDates,
  integrationTypes,
} from 'siteline-common-all'
import { saveAs } from 'siteline-common-web'
import { ContractForBulkExport } from '../../../components/billing/invoice/export/PayAppBulkExportDialog'
import { PayAppForProgress } from '../../../components/billing/invoice/LumpSumPayAppInvoice'
import { CompanyForCompanyContext } from '../../contexts/CompanyContext'
import { ContractForProjectContext } from '../../contexts/ProjectContext'
import {
  GetPayAppForProgressQuery,
  MinimalProjectProperties,
  TaxGroupProperties,
} from '../../graphql/apollo-operations'

type ComputerEasePayApp = GetPayAppForProgressQuery['payApp'] & {
  projectNumber: string
}
type PayAppForComputerEaseExport = Pick<
  ComputerEasePayApp,
  | 'billingEnd'
  | 'timeZone'
  | 'payAppNumber'
  | 'currentBilled'
  | 'currentRetention'
  | 'previousRetentionBilled'
  | 'projectNumber'
  | 'lastSubmitted'
  | 'amountDueTaxAmount'
>

export type ComputerEaseInvoiceParams = {
  invoiceDate: Moment
  dueDate: Moment
  taxGroup: TaxGroupProperties | null
} & integrationTypes.ComputerEaseContractMetadata

type PayAppWithComputerEaseParams = {
  payApp: PayAppForComputerEaseExport
  params: ComputerEaseInvoiceParams
}

const XML_DATE_FORMAT = 'YYYY-MM-DD'

async function createComputerEaseBatchImportXml(
  payApps: PayAppWithComputerEaseParams[]
): Promise<string> {
  const invoices = {
    import: {
      $: { type: 'batchinvoices' },
      invoice: payApps.map(({ payApp, params }) => {
        const { customerId, jobId, invoiceDate, dueDate, arAccount, salesAccount, taxGroup } =
          params
        return {
          customerid: customerId,
          jobid: jobId,
          duedate: dueDate.format(XML_DATE_FORMAT),
          invoicedate: invoiceDate.format(XML_DATE_FORMAT),
          ...(!taxGroup && {
            nontaxablesalesamount: centsToDollars(
              payApp.currentBilled + payApp.previousRetentionBilled
            ).toFixed(2),
          }),
          ...(taxGroup && {
            taxablesalesamount: centsToDollars(
              payApp.currentBilled + payApp.previousRetentionBilled
            ).toFixed(2),
            salestaxamount: centsToDollars(payApp.amountDueTaxAmount).toFixed(2),
            salestaxaccount: taxGroup.name,
          }),
          retentionamount: centsToDollars(payApp.currentRetention).toFixed(2),
          araccount: arAccount,
          salesaccount: salesAccount,
        }
      }),
    },
  }

  const xml2js = await import('xml2js')
  const xmlBuilder = new xml2js.Builder()
  const xml = xmlBuilder.buildObject(invoices)

  return xml
}

/**
 * Creates and downloads a ComputerEase XML representing a single pay app.
 */
export function createAndDownloadComputerEaseXml(
  payApps: PayAppWithComputerEaseParams[],
  t: TFunction
) {
  const filename = t('projects.subcontractors.pay_app.invoice.export.computer_ease.bulk_file_name')

  createComputerEaseBatchImportXml(payApps)
    .then((xml) => {
      const blob = new Blob([xml], { type: 'text/xml;charset=utf-8;' })
      saveAs(blob, `${filename}.xml`)
    })
    .catch((err) => {
      console.error('Error creating ComputerEase XML', err)
    })
}

export function getGeneralContractorCustomerIdFromComputerEaseMapping(
  integrationMappings: integrationTypes.CompanyIntegrationMappings,
  generalContractorId: string
) {
  const generalContractorMappings = integrationMappings.generalContractorMappings ?? []
  const generalContractorMapping = generalContractorMappings.find(
    (mapping) => mapping.companyId === generalContractorId
  )
  const mappings = generalContractorMapping?.mappings ?? []
  const computerEaseMapping = mappings.find((mapping) => mapping.type === 'computerEase') as
    | integrationTypes.GeneralContractorMappingComputerEase
    | undefined
  if (!computerEaseMapping) {
    return null
  }
  return computerEaseMapping.customerId
}

export function getDefaultComputerEaseInvoiceParams({
  payApp,
  company,
  contract,
  project,
  timeZone,
}: {
  payApp: Pick<PayAppForProgress, 'billingEnd' | 'lastSubmitted'>
  company: Pick<CompanyForCompanyContext, 'agingIntervalType' | 'integrationMappings'> | null
  contract?: ContractForProjectContext | ContractForBulkExport
  project: Pick<MinimalProjectProperties, 'projectNumber' | 'generalContractor'>
  timeZone: string
}) {
  const paymentTerms = _.isNumber(contract?.paymentTerms)
    ? contract.paymentTerms
    : DEFAULT_GC_PAYMENT_TERMS
  const { invoiceDate, dueDate } = getDefaultInvoiceDates({
    agingIntervalType: company?.agingIntervalType ?? DEFAULT_AGING_INTERVAL_TYPE,
    billingEnd: moment.tz(payApp.billingEnd, timeZone),
    timeZone,
    paymentTerms,
    submittedAt: payApp.lastSubmitted
      ? moment.tz(payApp.lastSubmitted.statusUpdatedAt, timeZone)
      : null,
  })

  // If a customerID has been stored on the GC's ComputerEase integration mappings, use that as
  // the default
  let customerId = ''
  if (company && project.generalContractor) {
    const mappingCustomerId = getGeneralContractorCustomerIdFromComputerEaseMapping(
      company.integrationMappings,
      project.generalContractor.company.id
    )
    if (mappingCustomerId) {
      customerId = mappingCustomerId
    }
  }

  return {
    customerId,
    jobId: contract?.internalProjectNumber ?? project.projectNumber,
    invoiceDate,
    dueDate,
    arAccount: '',
    salesAccount: '',
    taxGroup:
      // We can only write a single tax group, so if the contracth as multiple tax groups then we
      // will just sync pre-tax values only
      contract?.taxCalculationType === TaxCalculationType.SINGLE_TAX_GROUP
        ? contract.defaultTaxGroup
        : null,
  }
}
