import { parsePhoneNumber } from 'siteline-common-all'

/** Returns a more readable formatted phone number, for standard US phone numbers */
export function formatPhoneNumber(phoneNumber: string): string {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
  if (!parsedPhoneNumber) {
    return ''
  }
  return parsedPhoneNumber.formatNational()
}
