import { Reference } from '@apollo/client'
import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OnboardedProjectComplianceStatus,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { PendingFile } from '../../components/billing/backup/attachments/FileDragUpload'
import { LegalRequirementForProjectSettings } from '../../components/vendors/Vendors.lib'
import { DatePickerValue } from '../components/DatePickerInput'
import {
  ComplianceStatus,
  Contract,
  ContractForComplianceProjectHome,
  ContractForVendorsProjectHome,
  DocumentExpirationFrequency,
  DocumentType,
  DocumentTypesDocument,
  GetContractForProjectSettingsQuery,
  GetContractForVendorsProjectHomeDocument,
  GetContractsForComplianceQuery,
  LegalDocumentProperties,
  LegalRequirement,
  LegalRequirementProperties,
  ProjectLegalRequirementsDocument,
  ProjectLegalRequirementsQuery,
  StoredFileType,
  VendorContract,
  VendorLegalRequirementProperties,
  VendorLegalRequirementsDocument,
  VendorLegalRequirementsQuery,
  useDeleteVendorLegalRequirementMutation,
} from '../graphql/apollo-operations'
import { isLegalDocumentComplete } from './LegalDocument'
import { NUM_MAJORITY_WEEKDAYS, getFirstDaysOfWeekInMonth, weekdaysBetween } from './Time'

export enum ExpirationFrequencyType {
  RECURRING = 'recurring',
  STANDARD = 'standard',
}

export const recurringLegalDocumentExpirationFrequencies = [
  DocumentExpirationFrequency.END_OF_MONTH,
  DocumentExpirationFrequency.MONTHLY,
  DocumentExpirationFrequency.QUARTERLY,
  DocumentExpirationFrequency.WEEKLY,
]

export const nonRecurringLegalDocumentExpirationFrequencies = [
  DocumentExpirationFrequency.USER_INPUT,
  DocumentExpirationFrequency.NEVER,
]

/** Determines whether the given frequency is recurring */
export function isRecurringFrequency(frequency: DocumentExpirationFrequency) {
  return recurringLegalDocumentExpirationFrequencies.includes(frequency)
}

/** String format to use for storing legal requirement periods that the user marks as skipped */
export const SKIPPED_LEGAL_REQUIREMENT_PERIOD_FORMAT = 'YYYYMMDD'

/**
 * Returns true if the requirement is standard, returns false if the requirement is recurring.
 * Note: this function assumes that YEARLY is not a recurring frequency.
 */
export function isStandardRequirement(requirement: Pick<LegalRequirement, 'expirationFrequency'>) {
  return !recurringLegalDocumentExpirationFrequencies.includes(requirement.expirationFrequency)
}

export interface LegalRequirementFile {
  name: string
  file: File
  periodStart?: DatePickerValue
  periodEnd?: DatePickerValue
}

/** Returns the start date for a form based on its end date */
export function getPeriodStartFromPeriodEnd(
  expirationFrequency: DocumentExpirationFrequency,
  periodEnd?: moment.Moment
) {
  if (!periodEnd) {
    return undefined
  }

  switch (expirationFrequency) {
    case DocumentExpirationFrequency.MONTHLY: {
      if (periodEnd.isSame(periodEnd.clone().endOf('month'))) {
        // I.e. April 1 - April 30 instead of March 31 - April 30
        return periodEnd.clone().startOf('month')
      }
      return periodEnd.clone().subtract(1, 'month').add(1, 'day')
    }
    case DocumentExpirationFrequency.END_OF_MONTH: {
      return periodEnd.clone().startOf('month')
    }
    case DocumentExpirationFrequency.WEEKLY: {
      return periodEnd.clone().subtract(6, 'days')
    }
    case DocumentExpirationFrequency.QUARTERLY: {
      if (periodEnd.isSame(periodEnd.clone().endOf('month'), 'day')) {
        // I.e. Feb 1 - April 30 instead of Jan 31 - April 30
        return periodEnd.clone().startOf('month').subtract(2, 'months')
      }
      return periodEnd.clone().subtract(3, 'months').add(1, 'day')
    }
    case DocumentExpirationFrequency.YEARLY: {
      return periodEnd.clone().subtract(1, 'year').add(1, 'day')
    }
    case DocumentExpirationFrequency.NEVER:
    case DocumentExpirationFrequency.USER_INPUT: {
      return undefined
    }
  }
}

/** Gets the form from a form ID */
export function getFormFromPeriodEnd(
  requirement: LegalRequirementProperties,
  timeZone: string,
  t: TFunction,
  periodEnd?: moment.Moment
): LegalRequirementForm {
  let document = requirement.documents.find(
    (doc) =>
      doc.periodEnd && periodEnd && moment.tz(doc.periodEnd, timeZone).isSame(periodEnd, 'date')
  )
  if (
    !document &&
    requirement.expirationFrequency === DocumentExpirationFrequency.NEVER &&
    requirement.documents.length > 0
  ) {
    document = requirement.documents[0]
  }
  return {
    title: getLegalRequirementFormTitle(requirement, t, moment.tz(timeZone), periodEnd),
    periodStart: getPeriodStartFromPeriodEnd(requirement.expirationFrequency, periodEnd),
    periodEnd,
    document,
  }
}

/** Returns the period end for the oldest incomplete form for a requirement */
export function getOldestIncompletePeriodEnd(
  requirement: LegalRequirementProperties,
  timeZone: string,
  /** Exposed only for testing */
  now?: moment.Moment
) {
  const allPeriodEnds = getLegalRequirementPeriodEnds(requirement, timeZone, false, now)
  if (allPeriodEnds.length === 0) {
    return null
  }
  const incompletePeriodEnds = allPeriodEnds.filter(
    (date) =>
      !requirement.documents.some(
        (doc) => doc.periodEnd && moment.tz(doc.periodEnd, timeZone).isSame(date, 'date')
      )
  )
  const earliestPeriodEnd = moment.min(incompletePeriodEnds)
  return earliestPeriodEnd
}

/**
 * The form for a single time period belonging to a legal requirement. Corresponds
 * to one document that will be created when the form is uploaded or digitally
 * filled out, but the completed document may not yet exist if the requirement is incomplete.
 */
export interface LegalRequirementForm {
  /** A title for the individual document required (e.g. "Week of Jan 7") */
  title: string
  periodStart?: moment.Moment
  periodEnd?: moment.Moment

  /** A completed document, if one exists for this period */
  document?: LegalDocumentProperties
}

/** Filters a list of legal requirement periods to only ones that haven't been explicitly skipped */
function filterNonSkippedPeriodEnds(
  periodEnds: moment.Moment[],
  skippedPeriods: readonly string[],
  timeZone: string
) {
  return periodEnds.filter(
    (periodEnd) =>
      !skippedPeriods.some((skippedPeriodEnd) =>
        moment
          .tz(skippedPeriodEnd, SKIPPED_LEGAL_REQUIREMENT_PERIOD_FORMAT, timeZone)
          .isSame(periodEnd, 'day')
      )
  )
}

/**
 * Returns a list of end dates for all periods since the requirement started up
 * to the current date, excluding periods that have been explicitly skipped
 * @internal
 */
export function getLegalRequirementPeriodEnds(
  requirement: Pick<
    LegalRequirementProperties,
    'expirationFrequency' | 'startDate' | 'endDate' | 'skippedPeriods' | 'documents'
  >,
  timeZone: string,
  /** If true, includes skipped periods. False by default. */
  includeSkipped?: boolean,
  /** Exposed only for testing */
  now?: moment.Moment
) {
  const today = now ?? moment.tz(timeZone)
  const periodEnds: moment.Moment[] = []
  const startDate = requirement.startDate ? moment.tz(requirement.startDate, timeZone) : null
  const endDate = requirement.endDate ? moment.tz(requirement.endDate, timeZone) : null
  switch (requirement.expirationFrequency) {
    case DocumentExpirationFrequency.MONTHLY: {
      if (!startDate) {
        return []
      }
      const periodStart = startDate.clone()
      while (periodStart.isSameOrBefore(today, 'day')) {
        // Period ends next month, 1 day earlier (i.e. Oct 25 -> Nov 24)
        const periodEnd = periodStart.clone().add(1, 'month').subtract(1, 'day')
        if (!endDate || periodEnd.isSameOrBefore(endDate, 'day')) {
          periodEnds.push(periodEnd)
          periodStart.add(1, 'month')
        } else {
          break
        }
      }
      return includeSkipped
        ? periodEnds
        : filterNonSkippedPeriodEnds(periodEnds, requirement.skippedPeriods, timeZone)
    }
    case DocumentExpirationFrequency.END_OF_MONTH: {
      if (!startDate) {
        return []
      }
      const periodStart = startDate.clone().startOf('month')
      while (periodStart.isSameOrBefore(today, 'day')) {
        const periodEnd = periodStart.clone().endOf('month')
        if (!endDate || periodEnd.isSameOrBefore(endDate, 'day')) {
          periodEnds.push(periodEnd)
          periodStart.add(1, 'month')
        } else {
          break
        }
      }
      return includeSkipped
        ? periodEnds
        : filterNonSkippedPeriodEnds(periodEnds, requirement.skippedPeriods, timeZone)
    }
    case DocumentExpirationFrequency.QUARTERLY: {
      if (!startDate) {
        return []
      }
      const periodStart = startDate.clone()
      while (periodStart.isSameOrBefore(today, 'day')) {
        // Period ends next quarter, 1 day earlier (i.e. Jan 25 -> Apr 24)
        const periodEnd = periodStart.clone().add(3, 'months').subtract(1, 'day')
        if (!endDate || periodEnd.isSameOrBefore(endDate, 'day')) {
          periodEnds.push(periodEnd)
          periodStart.add(3, 'months')
        } else {
          break
        }
      }
      const lastPeriodEnd = periodStart.clone().subtract(1, 'day')
      // If the last period is expiring, add the next period
      if (
        isPeriodExpiring(lastPeriodEnd, timeZone, now) &&
        startDate.isSameOrBefore(today, 'day')
      ) {
        periodEnds.push(lastPeriodEnd.clone().add(3, 'months'))
      }
      return includeSkipped
        ? periodEnds
        : filterNonSkippedPeriodEnds(periodEnds, requirement.skippedPeriods, timeZone)
    }
    case DocumentExpirationFrequency.WEEKLY: {
      if (!startDate) {
        return []
      }
      const month = startDate.clone()
      while (month.isSameOrBefore(today, 'month')) {
        const firstDaysOfWeekInMonth = getFirstDaysOfWeekInMonth(startDate.day(), month)
        const firstDaysOfWeekInRange = firstDaysOfWeekInMonth.filter(
          (date) =>
            date.isSameOrAfter(startDate, 'day') &&
            date.isSameOrBefore(today, 'day') &&
            (!endDate || date.isSameOrBefore(endDate, 'day'))
        )
        const periodEndsInRange = firstDaysOfWeekInRange.map((date) => date.add(6, 'days'))
        periodEnds.push(...periodEndsInRange)
        month.add(1, 'month')
      }
      return includeSkipped
        ? periodEnds
        : filterNonSkippedPeriodEnds(periodEnds, requirement.skippedPeriods, timeZone)
    }
    case DocumentExpirationFrequency.YEARLY: {
      if (!startDate) {
        return []
      }
      const periodStart = startDate.clone()
      while (periodStart.isSameOrBefore(today, 'day')) {
        const periodEnd = periodStart.clone().add(1, 'year').subtract(1, 'day')
        if (!endDate || periodEnd.isSameOrBefore(endDate, 'day')) {
          periodEnds.push(periodEnd.clone())
          periodStart.add(1, 'year')
        } else {
          break
        }
      }
      const lastPeriodEnd = periodStart.clone().subtract(1, 'day')
      // If the last period is expiring, add the next period
      if (
        isPeriodExpiring(lastPeriodEnd, timeZone, now) &&
        startDate.isSameOrBefore(today, 'day')
      ) {
        periodEnds.push(lastPeriodEnd.clone().add(1, 'year'))
      }
      return includeSkipped
        ? periodEnds
        : filterNonSkippedPeriodEnds(periodEnds, requirement.skippedPeriods, timeZone)
    }
    case DocumentExpirationFrequency.USER_INPUT: {
      // For a user input requirement, return the end periods of any documents
      // completed with a user-entered date
      return requirement.documents
        .map((doc) => (doc.periodEnd ? moment.tz(doc.periodEnd, timeZone) : undefined))
        .filter((date): date is moment.Moment => !!date)
    }
    case DocumentExpirationFrequency.NEVER: {
      if (endDate) {
        return requirement.skippedPeriods.length === 0 ? [endDate] : []
      }
      return []
    }
  }
}

/** Returns true if two period ends correspond to the same form */
export function isSameFormPeriod(
  firstPeriodEnd: moment.Moment | undefined,
  secondPeriodEnd: moment.Moment | undefined
) {
  if (!firstPeriodEnd && !secondPeriodEnd) {
    return true
  }
  if (firstPeriodEnd && secondPeriodEnd && firstPeriodEnd.isSame(secondPeriodEnd, 'date')) {
    return true
  }
  return false
}

/** Returns a unique legal requirement form name based on the period it covers */
export function getLegalRequirementFormTitle(
  requirement: Pick<LegalRequirement, 'name' | 'expirationFrequency'>,
  t: TFunction,
  today: moment.Moment,
  periodEnd?: moment.Moment
): string {
  if (!periodEnd) {
    return requirement.name
  }
  switch (requirement.expirationFrequency) {
    case DocumentExpirationFrequency.WEEKLY: {
      // Include the year if not the current year
      const format = today.isSame(periodEnd, 'year') ? 'MMM DD' : 'MMM DD, YYYY'
      return t('projects.subcontractors.legal_requirement.week_of', {
        week: periodEnd.clone().subtract(6, 'days').format(format),
      })
    }
    case DocumentExpirationFrequency.YEARLY: {
      if (periodEnd.isSame(periodEnd.clone().endOf('year'), 'date')) {
        return periodEnd.year().toString()
      }
      const startingYear = periodEnd.clone().subtract(1, 'year').year()
      return `${startingYear.toString()}–${periodEnd.year().toString()}`
    }
    case DocumentExpirationFrequency.MONTHLY: {
      // If the period end is the end of the month, the requirement only spans the current month so
      // we don't need to show both months in the name
      if (periodEnd.isSame(periodEnd.clone().endOf('month'), 'day')) {
        const format = today.isSame(periodEnd, 'year') ? 'MMMM' : 'MMMM YYYY'
        return periodEnd.format(format)
      }

      const startingMonth = periodEnd.clone().subtract(1, 'month').format('MMMM')
      const endFormat = today.isSame(periodEnd, 'year') ? 'MMMM' : 'MMMM YYYY'
      return `${startingMonth}–${periodEnd.format(endFormat)}`
    }
    case DocumentExpirationFrequency.END_OF_MONTH: {
      const format = today.isSame(periodEnd, 'year') ? 'MMMM' : 'MMMM YYYY'
      return periodEnd.format(format)
    }
    case DocumentExpirationFrequency.QUARTERLY: {
      const format = today.isSame(periodEnd, 'year') ? 'MMMM' : 'MMMM YYYY'
      const startingMonth = getPeriodStartFromPeriodEnd(requirement.expirationFrequency, periodEnd)
      if (!startingMonth) {
        // This should never occur, but gracefully return a readable title if something is off
        return periodEnd.format(format)
      }
      return `${startingMonth.format(format)}–${periodEnd.format(format)}`
    }
    case DocumentExpirationFrequency.NEVER:
    case DocumentExpirationFrequency.USER_INPUT:
      return requirement.name
  }
}

/**
 * Whether or not this period is expiring soon. Should match the logic for when we start
 * sending notifications for USER_INPUT requirements in `getLegalRequirementErrorMessages` in
 * `notifications/src/emails/weekly-emails` for when we start sending reminders.
 */
export function isPeriodExpiring(
  periodEnd: moment.Moment,
  timeZone: string,
  /** Exposed only for testing */
  now?: moment.Moment
) {
  const today = now ?? moment.tz(timeZone)
  const dateDiff = today.diff(periodEnd, 'days')
  const NUM_DAYS_IN_WEEK = 7
  return dateDiff <= 0 && dateDiff > NUM_DAYS_IN_WEEK * -5
}

/** Returns a list of all forms for a legal requirement */
export function getLegalRequirementOutstandingForms(
  requirement: LegalRequirementProperties,
  t: TFunction,
  timeZone: string,
  vendorContractId?: string,
  /** Exposed only for testing */
  now?: moment.Moment
): LegalRequirementForm[] {
  const today = now ?? moment.tz(timeZone)
  const documents = requirement.documents.filter(
    (document) => document.vendorContract?.id === vendorContractId
  )
  // For "Never" expiring requirements, return a form only if none have been completed
  if (requirement.expirationFrequency === DocumentExpirationFrequency.NEVER) {
    return documents.length === 0 && requirement.skippedPeriods.length === 0
      ? [
          {
            title: getLegalRequirementFormTitle(requirement, t, today, today),
          },
        ]
      : []
  }
  // For "User input" requirements, return a form if the requirement is incomplete or every
  // document is expired or expiring soon
  if (requirement.expirationFrequency === DocumentExpirationFrequency.USER_INPUT) {
    const showForm =
      documents.length === 0 ||
      documents.every(
        (doc) =>
          !doc.periodEnd ||
          moment.tz(doc.periodEnd, timeZone).isBefore(today, 'day') ||
          isPeriodExpiring(moment.tz(doc.periodEnd, timeZone), timeZone, now)
      )
    const skippedRequirement = requirement.skippedPeriods.length > 0
    return showForm && !skippedRequirement
      ? [
          {
            title: getLegalRequirementFormTitle(requirement, t, today, today),
          },
        ]
      : []
  }
  const periods = getLegalRequirementPeriodEnds(requirement, timeZone, false, now)
  const outstandingPeriods = periods.filter(
    (periodEnd) =>
      !documents.some(
        (doc) => doc.periodEnd && moment.tz(doc.periodEnd, timeZone).isSame(periodEnd, 'day')
      )
  )
  const forms = outstandingPeriods.map((periodEnd) => {
    const periodStart = getPeriodStartFromPeriodEnd(requirement.expirationFrequency, periodEnd)
    return {
      title: getLegalRequirementFormTitle(requirement, t, today, periodEnd),
      periodStart,
      periodEnd,
    }
  })
  return _.orderBy(forms, (form) => form.periodEnd, 'desc')
}

/** Returns the number of "outstanding" forms for a legal requirement */
export function getLegalRequirementOutstandingCount(
  requirement: LegalRequirementProperties,
  t: TFunction,
  timeZone: string,
  vendorContractId?: string,
  /** Exposed only for testing */
  now?: moment.Moment
) {
  if (isStandardRequirement(requirement)) {
    const expirationDate = getLegalRequirementExpirationDate(
      requirement,
      timeZone,
      vendorContractId
    )
    const isCompliant =
      expirationDate === 'COMPLIANT' ||
      !expirationDate ||
      expirationDate.isSameOrAfter(now ?? moment.tz(timeZone), 'date')
    // A standard requirement should only ever have one document outstanding at
    // a time per vendor, if it is incomplete or expired
    return isCompliant ? 0 : 1
  }
  const outstandingForms = getLegalRequirementOutstandingForms(
    requirement,
    t,
    timeZone,
    vendorContractId,
    now
  )
  return outstandingForms.length
}

/**
 * Returns the latest expiration date of a standard requirement's documents, 'COMPLIANT' if
 * a document has no expiration, or null if the requirement has no documents
 */
export function getLegalRequirementExpirationDate(
  requirement: Pick<LegalRequirementProperties, 'startDate' | 'skippedPeriods' | 'documents'>,
  timeZone: string,
  vendorContractId?: string
) {
  const today = moment.tz(timeZone)
  // If the requirement has not yet started, consider it compliant
  if (requirement.startDate && moment.tz(requirement.startDate, timeZone).isAfter(today, 'date')) {
    return 'COMPLIANT'
  }
  // If any document is non-expiring, or the customer has skipped this requirement,
  // consider it compliant
  const hasNonExpiringVendorDocument = requirement.documents.some(
    (doc) => doc.vendorContract?.id === vendorContractId && !doc.periodEnd
  )
  const hasSkipped = requirement.skippedPeriods.length > 0
  if (hasNonExpiringVendorDocument || hasSkipped) {
    return 'COMPLIANT'
  }
  const documentExpirations = requirement.documents
    .filter((document) => document.vendorContract?.id === vendorContractId)
    .map((doc) => doc.periodEnd && moment.tz(doc.periodEnd, timeZone))
    .filter((date): date is moment.Moment => !!date)
  return documentExpirations.length > 0 ? moment.max(documentExpirations) : null
}

export enum VendorRequirementStatus {
  UP_TO_DATE,
  OUTSTANDING,
}

interface VendorLegalRequirementStatus {
  status: VendorRequirementStatus
  count?: number
}

/** Partitions a list of legal requirements into standard and recurring */
export function partitionLegalRequirementsByType(requirements: LegalRequirementProperties[]) {
  const sortedRequirements = _.orderBy(requirements, [(req) => req.name], ['asc'])
  return _.partition(sortedRequirements, isStandardRequirement)
}

export function getLegalRequirementStatus(
  requirement: Pick<
    LegalRequirementProperties,
    'expirationFrequency' | 'startDate' | 'endDate' | 'skippedPeriods' | 'documents'
  >,
  timeZone: string
): ComplianceStatus {
  const today = moment.tz(timeZone)

  switch (requirement.expirationFrequency) {
    case DocumentExpirationFrequency.NEVER:
    case DocumentExpirationFrequency.USER_INPUT:
    case DocumentExpirationFrequency.YEARLY: {
      const expirationDate = getLegalRequirementExpirationDate(requirement, timeZone)
      if (expirationDate === 'COMPLIANT') {
        return ComplianceStatus.COMPLIANT
      }
      if (!expirationDate || expirationDate.isBefore(today, 'date')) {
        return ComplianceStatus.EXPIRED
      } else if (isPeriodExpiring(expirationDate, timeZone)) {
        return ComplianceStatus.EXPIRING
      } else {
        return ComplianceStatus.COMPLIANT
      }
    }
    case DocumentExpirationFrequency.END_OF_MONTH:
    case DocumentExpirationFrequency.MONTHLY:
    case DocumentExpirationFrequency.WEEKLY:
    case DocumentExpirationFrequency.QUARTERLY: {
      const periodsRequired = getLegalRequirementPeriodEnds(requirement, timeZone)
      const missingPeriods = periodsRequired.filter((periodEnd) => {
        return !requirement.documents.some(
          (doc) => doc.periodEnd && moment.tz(doc.periodEnd, timeZone).isSame(periodEnd, 'date')
        )
      })
      if (missingPeriods.length > 0) {
        const anyPeriodPastDue = missingPeriods.some((periodEnd) => {
          // Intentionally use 'month' as granularity here because we don't consider weekly
          // requirements past due until the new month
          let isPastDue = periodEnd.isBefore(today, 'month')
          // For weekly requirements, also include periods that end this month
          // but have a majority of weekdays in the previous month
          if (
            requirement.expirationFrequency === DocumentExpirationFrequency.WEEKLY &&
            periodEnd.isSame(today, 'month')
          ) {
            const numWeekdaysThisMonth = weekdaysBetween(today.clone().startOf('month'), periodEnd)
            if (numWeekdaysThisMonth < NUM_MAJORITY_WEEKDAYS) {
              isPastDue = true
            }
          }
          return isPastDue
        })
        if (anyPeriodPastDue) {
          return ComplianceStatus.EXPIRED
        } else {
          return ComplianceStatus.EXPIRING
        }
      } else {
        return ComplianceStatus.COMPLIANT
      }
    }
  }
}

/**
 * Returns the status of a standard (USER_INPUT or NEVER) legal requirement for vendors, based on
 * the status of each vendor's documents. Includes a count of how many documents are expiring or expired.
 */
export function getVendorStandardLegalRequirementStatus(
  requirement: Pick<LegalRequirementProperties, 'startDate' | 'documents'>,
  vendorRequirements: VendorLegalRequirementProperties[],
  timeZone: string,
  /** Exposed only for testing */
  now?: moment.Moment
): VendorLegalRequirementStatus {
  const today = now ?? moment.tz(timeZone)
  // If the requirement has not yet started, consider it compliant
  if (requirement.startDate && moment.tz(requirement.startDate, timeZone).isAfter(today, 'date')) {
    return { status: VendorRequirementStatus.UP_TO_DATE }
  }
  const areAllCompliant = vendorRequirements.every((vendorRequirement) => {
    const vendorDocuments = requirement.documents.filter(
      (document) => document.vendorContract?.id === vendorRequirement.vendorContract.id
    )
    return (
      // A standard requirement is compliant if it never expires or has been skipped
      vendorDocuments.some(
        (doc) =>
          (isLegalDocumentComplete(doc) && !doc.periodEnd) ||
          vendorRequirement.skippedPeriods.length > 0
      )
    )
  })
  if (areAllCompliant) {
    return { status: VendorRequirementStatus.UP_TO_DATE }
  }
  const vendorDocumentExpirations = vendorRequirements.map((vendorRequirement) => {
    const vendorDocuments = requirement.documents.filter(
      (document) => document.vendorContract?.id === vendorRequirement.vendorContract.id
    )
    const documentExpirations = vendorDocuments
      .map((doc) => doc.periodEnd && moment.tz(doc.periodEnd, timeZone))
      .filter((date): date is moment.Moment => !!date)
    return documentExpirations.length > 0 ? moment.max(documentExpirations) : null
  })
  const numOutstanding = _.sumBy(vendorDocumentExpirations, (expiration) => {
    // Add one for every vendor whose latest document is expired, expiring, or incomplete
    const isOutstanding =
      expiration === null ||
      expiration.isBefore(today, 'day') ||
      isPeriodExpiring(expiration, timeZone)
    return isOutstanding ? 1 : 0
  })
  if (numOutstanding > 0) {
    return { status: VendorRequirementStatus.OUTSTANDING, count: numOutstanding }
  }
  return { status: VendorRequirementStatus.UP_TO_DATE }
}

export function getVendorLegalRequirementStatus(
  requirement: LegalRequirementProperties,
  vendorRequirements: VendorLegalRequirementProperties[],
  timeZone: string,
  /** Exposed only for testing */
  now?: moment.Moment
): VendorLegalRequirementStatus {
  switch (requirement.expirationFrequency) {
    case DocumentExpirationFrequency.NEVER:
    case DocumentExpirationFrequency.USER_INPUT: {
      return getVendorStandardLegalRequirementStatus(requirement, vendorRequirements, timeZone, now)
    }
    case DocumentExpirationFrequency.END_OF_MONTH:
    case DocumentExpirationFrequency.MONTHLY:
    case DocumentExpirationFrequency.WEEKLY:
    case DocumentExpirationFrequency.QUARTERLY:
    case DocumentExpirationFrequency.YEARLY: {
      const periodsRequired = getLegalRequirementPeriodEnds(requirement, timeZone, false, now)
      const missingVendorPeriods = vendorRequirements.map((vendorRequirement) => {
        const nonSkippedPeriods = filterNonSkippedPeriodEnds(
          periodsRequired,
          vendorRequirement.skippedPeriods,
          timeZone
        )
        return nonSkippedPeriods.filter((periodEnd) => {
          const hasVendorDocument = requirement.documents.some(
            (doc) =>
              isLegalDocumentComplete(doc) &&
              doc.vendorContract?.id === vendorRequirement.vendorContract.id &&
              doc.periodEnd &&
              moment.tz(doc.periodEnd, timeZone).isSame(periodEnd, 'day')
          )
          return !hasVendorDocument
        })
      })
      const numDocumentsOutstanding = missingVendorPeriods
        .filter((periods) => periods.length > 0)
        .flat().length
      if (numDocumentsOutstanding > 0) {
        return { status: VendorRequirementStatus.OUTSTANDING, count: numDocumentsOutstanding }
      } else {
        return { status: VendorRequirementStatus.UP_TO_DATE }
      }
    }
  }
}

/** Returns the subcontractor legal requirements on a contract */
export function getSubcontractorLegalRequirements<
  T extends { isVendorRequirement: boolean },
>(contract?: { legalRequirements: T[] | readonly T[] }) {
  return (contract?.legalRequirements ?? []).filter(
    // Filter out vendor requirements
    (requirement) => !requirement.isVendorRequirement
  )
}

/** Returns the vendor legal requirements on a contract */
export function getVendorLegalRequirements<T extends { isVendorRequirement: boolean }>(contract?: {
  legalRequirements: T[] | readonly T[]
}) {
  return (contract?.legalRequirements ?? []).filter(
    // Include only vendor requirements
    (requirement) => requirement.isVendorRequirement
  )
}

/** The maximum date allowed for MONTHLY requirements */
export const MAX_MONTHLY_REQUIREMENT_DATE = 28
/** The max possible date is used to set end of month  */
export const END_OF_MONTH_DATE = 31

/** Returns a single expiration frequency to use for a document type */
export function getDocumentTypeDefaultExpirationFrequency(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.CERTIFIED_PAYROLL:
    case DocumentType.DAILY_REPORT:
      return DocumentExpirationFrequency.WEEKLY
    case DocumentType.INVOICE:
      return DocumentExpirationFrequency.MONTHLY
    case DocumentType.CERTIFICATE_OF_INSURANCE:
    case DocumentType.LABOR_RATES:
      return DocumentExpirationFrequency.USER_INPUT
    case DocumentType.OTHER:
      return DocumentExpirationFrequency.MONTHLY
  }
}

/** Returns all vendor expiration frequencies supported for a type of document */
export function getAllDocumentTypeExpirationFrequencies(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.CERTIFIED_PAYROLL:
    case DocumentType.DAILY_REPORT:
      return [DocumentExpirationFrequency.WEEKLY]
    case DocumentType.INVOICE:
      return [DocumentExpirationFrequency.MONTHLY, DocumentExpirationFrequency.END_OF_MONTH]
    case DocumentType.LABOR_RATES:
      return [DocumentExpirationFrequency.USER_INPUT]
    case DocumentType.CERTIFICATE_OF_INSURANCE:
    case DocumentType.OTHER:
      // QUARTERLY is not supported for vendor legal requirements
      return [
        DocumentExpirationFrequency.WEEKLY,
        DocumentExpirationFrequency.MONTHLY,
        DocumentExpirationFrequency.END_OF_MONTH,
        DocumentExpirationFrequency.YEARLY,
        DocumentExpirationFrequency.USER_INPUT,
        DocumentExpirationFrequency.NEVER,
      ]
  }
}

export function getFrequencyTypeFromDocument(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.CERTIFICATE_OF_INSURANCE:
    case DocumentType.LABOR_RATES:
    case DocumentType.CERTIFIED_PAYROLL:
    case DocumentType.DAILY_REPORT:
    case DocumentType.INVOICE:
    case DocumentType.OTHER:
      return ExpirationFrequencyType.RECURRING
  }
}

interface UseDeleteVendorLegalRequirementParams {
  contract?:
    | ContractForVendorsProjectHome
    | GetContractForProjectSettingsQuery['contractByProjectId']
}

export function useDeleteVendorLegalRequirement({
  contract,
}: UseDeleteVendorLegalRequirementParams) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  const [deleteLegalRequirement] = useDeleteVendorLegalRequirementMutation({
    update(cache, { data: requirementData }) {
      if (!requirementData || !contract) {
        return
      }

      cache.modify<WritableDeep<Contract>>({
        id: cache.identify(contract),
        fields: {
          legalRequirements(existingRequirements, { readField, toReference }) {
            const refs = toReferences(existingRequirements, toReference)
            return refs.filter(
              (requirement) =>
                readField('id', requirement) !== requirementData.deleteVendorLegalRequirement.id
            )
          },
        },
      })

      // Remove the requirement from any vendor contract that had it checked
      contract.vendorContracts.forEach((vendorContract) => {
        cache.modify<WritableDeep<VendorContract>>({
          id: cache.identify(vendorContract),
          fields: {
            vendorLegalRequirements(existingRequirements, { readField, toReference }) {
              const refs = toReferences(existingRequirements, toReference)
              return refs.filter((vendorRequirement) => {
                const legalRequirementRef = readField('legalRequirement', vendorRequirement) as
                  | Reference
                  | undefined
                if (!legalRequirementRef) {
                  return false
                }
                return (
                  requirementData.deleteVendorLegalRequirement.id !==
                  readField('id', legalRequirementRef)
                )
              })
            },
          },
        })

        // Remove the requirement from the vendor documents query
        const queryData: VendorLegalRequirementsQuery | null = cache.readQuery({
          query: VendorLegalRequirementsDocument,
          variables: { vendorIds: [vendorContract.vendor.id] },
        })
        if (queryData) {
          cache.writeQuery({
            query: VendorLegalRequirementsDocument,
            variables: { vendorIds: [vendorContract.vendor.id] },
            data: {
              vendorLegalRequirements: queryData.vendorLegalRequirements.filter(
                (requirement) =>
                  requirement.legalRequirement.id !==
                  requirementData.deleteVendorLegalRequirement.id
              ),
            },
          })
        }
      })

      // Remove the requirement from the project documents query
      const queryData: ProjectLegalRequirementsQuery | null = cache.readQuery({
        query: ProjectLegalRequirementsDocument,
        variables: { input: { projectIds: [contract.project.id], companyId: contract.company.id } },
      })
      if (queryData) {
        cache.writeQuery({
          query: ProjectLegalRequirementsDocument,
          variables: {
            input: { projectIds: [contract.project.id], companyId: contract.company.id },
          },
          data: {
            projectLegalRequirements: queryData.projectLegalRequirements.filter(
              (requirement) =>
                requirement.legalRequirement.id !== requirementData.deleteVendorLegalRequirement.id
            ),
          },
        })
      }
    },
    refetchQueries: [
      ...(contract
        ? [
            { query: DocumentTypesDocument, variables: { companyId: contract.company.id } },
            // This query doesn't always re-render even when the cache is updating, so explicitly
            // trigger a refetch to ensure the deletion is reflected in the UI
            {
              query: GetContractForVendorsProjectHomeDocument,
              variables: {
                input: { projectId: contract.project.id, companyId: contract.company.id },
              },
            },
          ]
        : []),
    ],
  })

  const handleDeleteRequirement = useCallback(
    async (requirement: LegalRequirementForProjectSettings) => {
      try {
        await deleteLegalRequirement({
          variables: { id: requirement.id },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteVendorLegalRequirement: { __typename: 'DeletionResult', id: requirement.id },
          },
        })
        snackbar.showSuccess(t('vendor_home.project_settings.deleted_requirement'))
      } catch (err) {
        snackbar.showError(err.message)
      }
    },
    [deleteLegalRequirement, snackbar, t]
  )

  return handleDeleteRequirement
}

export type ComplianceLegalRequirement =
  ContractForComplianceProjectHome['legalRequirements'][number]

export enum ComplianceProjectOnboardingStep {
  LEGAL_REQUIREMENTS = 'legalRequirements',
  GC_RECIPIENTS = 'gcRecipients',
  FINISH_ONBOARDING = 'finishOnboarding',
}

const onboardingTaskToStatusKey: Record<
  ComplianceProjectOnboardingStep,
  keyof OnboardedProjectComplianceStatus
> = {
  [ComplianceProjectOnboardingStep.LEGAL_REQUIREMENTS]: 'addedLegalRequirements',
  [ComplianceProjectOnboardingStep.GC_RECIPIENTS]: 'addedGcRecipients',
  [ComplianceProjectOnboardingStep.FINISH_ONBOARDING]: 'completedOnboarding',
}

const ORDERED_ONBOARDING_TASKS = [
  ComplianceProjectOnboardingStep.LEGAL_REQUIREMENTS,
  ComplianceProjectOnboardingStep.GC_RECIPIENTS,
  ComplianceProjectOnboardingStep.FINISH_ONBOARDING,
]

/** Used to track onboarding progress in the compliance module */
export function nextIncompleteOnboardingTask(
  onboardedStatus: OnboardedProjectComplianceStatus,
  fromTask?: ComplianceProjectOnboardingStep | null
) {
  let index = 0
  if (fromTask) {
    index = ORDERED_ONBOARDING_TASKS.indexOf(fromTask) + 1
  }
  while (index < ORDERED_ONBOARDING_TASKS.length) {
    const currentTask = ORDERED_ONBOARDING_TASKS[index]
    const statusKey = onboardingTaskToStatusKey[currentTask]
    if (onboardedStatus[statusKey] === false) {
      return currentTask
    }
    index++
  }
  return ORDERED_ONBOARDING_TASKS[index]
}

/**
 * In the vendors module we use the requirement `type` to promote consistency & organization. This allows
 * us to categorically organize the vendors module document tracker. In the compliance module, we do not
 * have a document tracker and there is a greater need for flexibility. Therefore, all compliance legal
 * requirements are typed as "other". The following "quick add" options are buttons in the UI that when
 * clicked will pre-populate the "add legal requirement" dialog with default settings.
 */
export enum ComplianceRequirementQuickAddType {
  GENERAL_LIABILITY_INSURANCE = 'GENERAL_LIABILITY_INSURANCE',
  CERTIFICATE_OF_INSURANCE = 'CERTIFICATE_OF_INSURANCE',
  CERTIFIED_PAYROLL = 'CERTIFIED_PAYROLL',
  AUTOMOBILE_LIABILITY_INSURANCE = 'AUTOMOBILE_LIABILITY_INSURANCE',
  WORKERS_COMPENSATION_INSURANCE = 'WORKERS_COMPENSATION_INSURANCE',
  OTHER = 'OTHER',
}

export const ORDERED_QUICK_ADD_TYPES = [
  ComplianceRequirementQuickAddType.AUTOMOBILE_LIABILITY_INSURANCE,
  ComplianceRequirementQuickAddType.CERTIFICATE_OF_INSURANCE,
  ComplianceRequirementQuickAddType.CERTIFIED_PAYROLL,
  ComplianceRequirementQuickAddType.GENERAL_LIABILITY_INSURANCE,
  ComplianceRequirementQuickAddType.WORKERS_COMPENSATION_INSURANCE,
].sort()

export function getDefaultComplianceExpirationFrequency(
  requirementName: ComplianceRequirementQuickAddType
) {
  switch (requirementName) {
    case ComplianceRequirementQuickAddType.AUTOMOBILE_LIABILITY_INSURANCE:
    case ComplianceRequirementQuickAddType.GENERAL_LIABILITY_INSURANCE:
    case ComplianceRequirementQuickAddType.WORKERS_COMPENSATION_INSURANCE:
      return DocumentExpirationFrequency.YEARLY
    case ComplianceRequirementQuickAddType.CERTIFICATE_OF_INSURANCE:
      return DocumentExpirationFrequency.USER_INPUT
    case ComplianceRequirementQuickAddType.CERTIFIED_PAYROLL:
      return DocumentExpirationFrequency.WEEKLY
    case ComplianceRequirementQuickAddType.OTHER:
      return DocumentExpirationFrequency.NEVER
  }
}

export const RECURRING_FREQUENCIES = [
  DocumentExpirationFrequency.MONTHLY,
  DocumentExpirationFrequency.WEEKLY,
  DocumentExpirationFrequency.QUARTERLY,
  DocumentExpirationFrequency.YEARLY,
  DocumentExpirationFrequency.END_OF_MONTH,
]

export const COMPLIANCE_RECURRING_PERIODS = [
  DocumentExpirationFrequency.WEEKLY,
  DocumentExpirationFrequency.MONTHLY,
  DocumentExpirationFrequency.QUARTERLY,
  DocumentExpirationFrequency.YEARLY,
] as const

const FILE_TYPE_TO_SUFFIX: Record<StoredFileType, string> = {
  [StoredFileType.DOC]: '.doc',
  [StoredFileType.DOCX]: '.docx',
  [StoredFileType.JPEG]: '.jpeg',
  [StoredFileType.PDF]: '.pdf',
  [StoredFileType.PNG]: '.png',
  [StoredFileType.XLS]: '.xls',
  [StoredFileType.XLSX]: '.xlsx',
}

export const SUFFIX_TO_FILE_TYPE: Record<string, StoredFileType> = _.invert(
  FILE_TYPE_TO_SUFFIX
) as Record<string, StoredFileType>

export function createFormTemplate(pendingFile: PendingFile) {
  if (!pendingFile.file) {
    return undefined
  }
  // Use the name entered into the input rather than the name of the file itself, and append
  // extension type if it's missing (e.g., append ".pdf" if the file type is PDF and it's missing)
  const file = pendingFile.file
  const fileExtension = FILE_TYPE_TO_SUFFIX[pendingFile.type]
  const fileNameTail = pendingFile.name.slice(-fileExtension.length)
  const fileName =
    fileExtension && fileNameTail !== fileExtension
      ? pendingFile.name + fileExtension
      : pendingFile.name
  return new File([file], fileName)
}

export function getRecurringRequirementHelperText({
  frequency,
  startDate,
  t,
}: {
  frequency: DocumentExpirationFrequency
  startDate: Moment
  t: TFunction
}) {
  const i18nBase =
    'projects.subcontractors.legal_requirement.onboarding.add_requirement_dialog.period_helper_text'
  const periodStart = startDate.clone()
  const periodEnd = startDate.clone()
  switch (frequency) {
    case DocumentExpirationFrequency.MONTHLY:
      periodEnd.add(1, 'month').subtract(1, 'day')
      return t(i18nBase, {
        startDate: periodStart.format('MMMM D'),
        endDate: periodEnd.format('MMMM D'),
      })
    case DocumentExpirationFrequency.END_OF_MONTH:
      periodStart.startOf('month')
      periodEnd.endOf('month')
      return t(i18nBase, {
        startDate: periodStart.format('MMMM D'),
        endDate: periodEnd.format('MMMM D'),
      })
    case DocumentExpirationFrequency.WEEKLY:
      periodEnd.add(6, 'days')
      return t(i18nBase, {
        startDate: periodStart.format('dddd, MMMM D'),
        endDate: periodEnd.format('dddd, MMMM D'),
      })
    case DocumentExpirationFrequency.QUARTERLY:
      periodEnd.add(3, 'months').subtract(1, 'day')
      return t(i18nBase, {
        startDate: periodStart.format('MMMM D, YYYY'),
        endDate: periodEnd.format('MMMM D, YYYY'),
      })
    case DocumentExpirationFrequency.YEARLY:
      periodEnd.add(1, 'year').subtract(1, 'day')
      return t(i18nBase, {
        startDate: periodStart.format('MMMM D, YYYY'),
        endDate: periodEnd.format('MMMM D, YYYY'),
      })
    case DocumentExpirationFrequency.NEVER:
    case DocumentExpirationFrequency.USER_INPUT:
      return ''
  }
}
export type ComplianceContract = GetContractsForComplianceQuery['contracts'][number]
