import { TFunction } from 'i18next'
import _ from 'lodash'
import { Dispatch } from 'react'
import {
  ProgressEventPayload,
  RetentionTrackingLevel,
  SetLineItemPreviousBilledPayload,
  SetLineItemTotalValuePayload,
  SetStoredMaterialBilledAndInstalledPayload,
  StoredMaterialsCarryoverType,
  TypedProgressEvent,
  formatCentsToDollars,
  percentToDecimal,
  roundCents,
} from 'siteline-common-all'
import {
  InvoiceAction,
  RetentionView,
  StoredMaterialsView,
  TaxesView,
} from '../../components/billing/invoice/InvoiceReducer'
import { PayAppForProgress } from '../../components/billing/invoice/LumpSumPayAppInvoice'
import { SpreadsheetColumn } from '../components/Spreadsheet/Spreadsheet.lib'
import {
  BillingType,
  SovLineItemProgressEventProperties,
  SovLineItemProgressEventType,
  SovLineItemProgressProperties,
  WorksheetLineItemProgressProperties,
} from '../graphql/apollo-operations'
import { getLumpSumInvoiceColumns } from './LumpSumInvoice'
import {
  balanceToFinish,
  currentPercentComplete,
  currentWorksheetProgressPercentComplete,
} from './PayApp'
import { getUnitPriceInvoiceColumns } from './UnitPriceInvoice'

export enum BaseInvoiceColumn {
  CODE = 'code',
  NAME = 'name',
  COST_CODE = 'costCode',
  PROGRESS_BILLED = 'progressBilled',
  STORED_MATERIALS = 'storedMaterialBilled',
  RETENTION_PERCENT = 'progressRetentionPercent',
  TAXES = 'taxes',
  HISTORY = 'history',
}

export type InvoiceColumnsProps = {
  disableEditing?: boolean
  isRetentionOnly?: boolean
  /** For STANDARD or LINE_ITEM tracking, how to show the retention amount ($ or %) */
  retentionView?: RetentionView
  onRetentionViewChange?: (retentionView: RetentionView) => void
  /** For unit price invoices, this is the metric that stored materials is expressed in (units or dollars) */
  storedMaterialsView: StoredMaterialsView
  onStoredMaterialsViewChange: (storedMaterialsView: StoredMaterialsView) => void
  /** For SINGLE_TAX_GROUP and MULTIPLE_TAX_GROUPS tax calculation, we show a taxes column */
  taxesView?: TaxesView
  onTaxesViewChange?: (taxesView: TaxesView) => void
  dispatch: Dispatch<InvoiceAction>
  trackingType: RetentionTrackingLevel
  shouldIncludeCostCode: boolean
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
}

/** Returns the list of columns to show on the pay app invoice table */
export function getInvoiceColumns(
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE,
  t: TFunction,
  props: InvoiceColumnsProps
): SpreadsheetColumn[] {
  switch (billingType) {
    case BillingType.LUMP_SUM:
      return getLumpSumInvoiceColumns(t, props)
    case BillingType.UNIT_PRICE:
      return getUnitPriceInvoiceColumns(t, props)
  }
}

/**
 * We want to compute the newProgressBilled using the newPercentComplete
 * We know that the newPercentComplete/100 = newTotalBilled/totalValue
 * We also know that newTotalBilled = previousBilled + storedMaterialsBilled + newProgressBilled
 * Therefore, some arithmetic gets us to the math:
 * newProgressBilled = (newPercentComplete / 100) * totalValue - previousBilled - storedMaterialsBilled
 *
 * @param progress The progress being changed
 * @param newPercentComplete The new percent complete for the progress
 */
export function getProgressBilledFromPercentComplete(
  progress: SovLineItemProgressProperties,
  newPercentComplete: number
) {
  const currentPercent = currentPercentComplete(progress)
  if (currentPercent === newPercentComplete) {
    // This is needed to handle rounding errors. If the percent complete isn't changed, without
    // this, the progress billed could still be changed because of fractional %s. For example,
    // if you have $39 billed out of $100,000, that's 0.039% which rounds to 0.4% since we only
    // show 2 decimal places. Then, if the user just hits enter twice, effectively setting the
    // percent complete from 0.4% to 0.4%, the progress billed changes to $40. Instead, we should
    // keep the progress billed at $39.
    return progress.progressBilled
  }
  const nonProgressBilled = progress.previousBilled + progress.storedMaterialBilled
  const newProgressBilled =
    percentToDecimal(newPercentComplete) * progress.totalValue - nonProgressBilled
  return roundCents(newProgressBilled)
}

/**
 * Equivalent function to `getProgressBilledFromPercentComplete`, but for worksheet line item
 * progress
 */
export function getWorksheetProgressBilledFromPercentComplete(
  progress: WorksheetLineItemProgressProperties,
  newPercentComplete: number
) {
  const currentPercent = currentWorksheetProgressPercentComplete(progress)
  if (currentPercent === newPercentComplete) {
    // Avoids rounding errors (see explanation above in `getProgressBilledFromPercentComplete`)
    return progress.progressBilled
  }
  const nonProgressBilled = progress.previousBilled
  const newProgressBilled =
    percentToDecimal(newPercentComplete) * progress.totalValue - nonProgressBilled
  return roundCents(newProgressBilled)
}

/** Returns a description for a progress history event */
export function getProgressEventDescription(
  isChangeOrder: boolean,
  event: SovLineItemProgressEventProperties,
  t: TFunction
) {
  const i18nBase = 'projects.subcontractors.pay_app.progress.events'
  const name = event.isAdmin
    ? t(`${i18nBase}.admin`)
    : `${event.createdBy.firstName} ${event.createdBy.lastName}`

  const typedEvent = event as TypedProgressEvent<SovLineItemProgressEventProperties>
  switch (typedEvent.type) {
    case SovLineItemProgressEventType.RETENTION_ADJUSTED: {
      const retentionReleased =
        typedEvent.metadata.oldRetentionHeld - typedEvent.metadata.newRetentionHeld
      if (retentionReleased > 0) {
        return t(`${i18nBase}.${typedEvent.type}.released`, {
          name,
          retentionReleased: formatCentsToDollars(retentionReleased, true),
        })
      } else {
        return t(`${i18nBase}.${typedEvent.type}.held`, {
          name,
          retentionHeld: formatCentsToDollars(-1 * retentionReleased, true),
        })
      }
    }
    case SovLineItemProgressEventType.CREATE_LINE_ITEM: {
      const type = isChangeOrder ? 'CREATE_CHANGE_ORDER' : 'CREATE_LINE_ITEM'
      return t(`${i18nBase}.${type}`, { name })
    }
    case SovLineItemProgressEventType.SET_PROGRESS_BILLED:
    case SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED:
    case SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED_AND_INSTALLED:
    case SovLineItemProgressEventType.SET_LINE_ITEM_CODE:
    case SovLineItemProgressEventType.SET_LINE_ITEM_NAME:
    case SovLineItemProgressEventType.SET_LINE_ITEM_COST_CODE:
    case SovLineItemProgressEventType.SET_LINE_ITEM_ORDER:
    case SovLineItemProgressEventType.SET_LINE_ITEM_TOTAL_VALUE:
    case SovLineItemProgressEventType.SET_LINE_ITEM_PREVIOUS_BILLED:
    case SovLineItemProgressEventType.RESET_FROM_PREVIOUS_PAY_APP:
    case SovLineItemProgressEventType.SET_LINE_ITEM_UNIT_NAME:
    case SovLineItemProgressEventType.SET_LINE_ITEM_UNIT_PRICE:
      return t(`${i18nBase}.${typedEvent.type}`, { name })
  }
}

export type HistoryProgressProperties = {
  scheduledValue: number
  previousBilled: number
  percentComplete: number
  progressBilled: number
  storedMaterialBilled: number
  /** Applicable to manual stored material mode */
  materialsStored: number | null
  balanceToFinish: number
  retentionHeld: number | undefined
}

/**
 * Get the initial value of total value by grabbing the earliest "old" value
 * from the update events.
 */
function getInitialScheduledValue(
  progress: SovLineItemProgressProperties,
  events: SovLineItemProgressEventProperties[]
): number {
  const typedEvents = events as TypedProgressEvent<SovLineItemProgressEventProperties>[]
  const earliestEvent = typedEvents.find(
    (event) => event.type === SovLineItemProgressEventType.SET_LINE_ITEM_TOTAL_VALUE
  )

  if (earliestEvent !== undefined) {
    const typedEvent = earliestEvent as SetLineItemTotalValuePayload
    return typedEvent.metadata.oldLineItemTotalValue
  }
  return progress.totalValue
}

/**
 * Get the initial value of previous billed by grabbing the earliest "old" value
 * from the update events.
 */
function getInitialPreviousBilled(
  progress: SovLineItemProgressProperties,
  events: SovLineItemProgressEventProperties[]
): number {
  const typedEvents = events as TypedProgressEvent<SovLineItemProgressEventProperties>[]
  const earliestEvent = typedEvents.find(
    (event) => event.type === SovLineItemProgressEventType.SET_LINE_ITEM_PREVIOUS_BILLED
  )

  if (earliestEvent !== undefined) {
    const typedEvent = earliestEvent as SetLineItemPreviousBilledPayload
    return typedEvent.metadata.oldLineItemPreviousBilled
  }
  return progress.previousBilled
}

/**
 * Get the initial value of previously stored materials by grabbing the earliest "old" value
 * from the update events. Note that pre-siteline stored materials is only applicable to manual
 * tracking. If the project is set up with automatic stored materials carryover type, this value
 * will be 0.
 */
function getInitialPreviouslyStoredMaterials(
  progress: SovLineItemProgressProperties,
  events: SovLineItemProgressEventProperties[],
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
): number {
  if (storedMaterialsCarryoverType === StoredMaterialsCarryoverType.AUTOMATIC) {
    return 0
  }
  const typedEvents = events as TypedProgressEvent<SovLineItemProgressEventProperties>[]
  const earliestEvent = typedEvents.find(
    (event) => event.type === SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED_AND_INSTALLED
  )
  if (earliestEvent !== undefined) {
    const typedEvent = earliestEvent as SetStoredMaterialBilledAndInstalledPayload
    return typedEvent.metadata.oldStoredMaterialBilled
  }
  return progress.sovLineItem.previousMaterialsInStorage
}

/**
 * Replays all events up until a specific point in time to show what a specific SovLineItemProgress
 * looked like at that point in time.
 *
 * @param progress This is the progres that we are replaying history for
 * @param events The history of changes, sorted newest to oldest change
 * @param index The index in the array that we will stop at
 */
export function calculateProgressFromEvent({
  progress,
  events,
  index,
  storedMaterialsCarryoverType,
}: {
  progress: SovLineItemProgressProperties
  events: SovLineItemProgressEventProperties[]
  index: number
  storedMaterialsCarryoverType: StoredMaterialsCarryoverType
}): HistoryProgressProperties {
  const initialScheduledValue = getInitialScheduledValue(progress, events)
  const initialPreviousBilled = getInitialPreviousBilled(progress, events)
  const initialMaterialsStored =
    initialPreviousBilled > 0
      ? getInitialPreviouslyStoredMaterials(progress, events, storedMaterialsCarryoverType)
      : 0

  const result: HistoryProgressProperties = {
    scheduledValue: initialScheduledValue,
    previousBilled: initialPreviousBilled,
    materialsStored: initialMaterialsStored,
    // An SovLineItemProgress begins with nothing billed
    percentComplete: 0,
    progressBilled: 0,
    storedMaterialBilled: 0,
    balanceToFinish: 0,
    retentionHeld: undefined,
  }

  // Replay the events one by one up until the index. Since the array is sorted to have the most
  // recent values first, we need to start from the end of the array and play them backwards
  for (let eventIdx = events.length - 1; eventIdx >= index; eventIdx--) {
    const event = events[eventIdx] as Omit<
      SovLineItemProgressEventProperties,
      'type' | 'metadata'
    > &
      ProgressEventPayload
    switch (event.type) {
      case SovLineItemProgressEventType.SET_PROGRESS_BILLED:
        result.progressBilled = event.metadata.newProgressBilled
        result.retentionHeld = event.metadata.newRetentionHeld
        break
      case SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED:
        result.storedMaterialBilled = event.metadata.newStoredMaterialBilled
        result.retentionHeld = event.metadata.newRetentionHeld
        break
      case SovLineItemProgressEventType.SET_STORED_MATERIAL_BILLED_AND_INSTALLED:
        result.storedMaterialBilled = event.metadata.newStoredMaterialBilled
        result.materialsStored = event.metadata.newMaterialsInStorage
        result.retentionHeld = event.metadata.newRetentionHeld
        break
      case SovLineItemProgressEventType.RETENTION_ADJUSTED:
        result.retentionHeld = event.metadata.newRetentionHeld
        break
      case SovLineItemProgressEventType.SET_LINE_ITEM_TOTAL_VALUE:
        result.scheduledValue = event.metadata.newLineItemTotalValue
        break
      case SovLineItemProgressEventType.SET_LINE_ITEM_PREVIOUS_BILLED:
        result.previousBilled = event.metadata.newLineItemPreviousBilled
        break
      case SovLineItemProgressEventType.RESET_FROM_PREVIOUS_PAY_APP:
        result.progressBilled = 0
        result.storedMaterialBilled = 0
        break
      case SovLineItemProgressEventType.CREATE_LINE_ITEM:
      case SovLineItemProgressEventType.SET_LINE_ITEM_CODE:
      case SovLineItemProgressEventType.SET_LINE_ITEM_NAME:
      case SovLineItemProgressEventType.SET_LINE_ITEM_COST_CODE:
      case SovLineItemProgressEventType.SET_LINE_ITEM_ORDER:
      case SovLineItemProgressEventType.SET_LINE_ITEM_UNIT_NAME:
      case SovLineItemProgressEventType.SET_LINE_ITEM_UNIT_PRICE:
        // Nothing to do, does not affect progress
        break
    }
  }

  // Overwrite the original progress object with the new values and recalculate percent complete
  const historicalProgress = { ...progress, ...result }
  result.percentComplete = currentPercentComplete(historicalProgress)
  result.balanceToFinish = balanceToFinish(historicalProgress)

  return result
}

/** Iterates through all line items in the pay app sov to determine if any include a cost code */
export function doesInvoiceHaveCostCodes(payApp: PayAppForProgress) {
  return payApp.progress.some(({ sovLineItem }) => {
    return _.isString(sovLineItem.costCode) && sovLineItem.costCode.length > 0
  })
}

/** Returns true if two progress line items do not belong to the same group (or are both ungrouped) */
export function areNotInSameLineItemGroup(
  firstProgress: SovLineItemProgressProperties,
  secondProgress: SovLineItemProgressProperties
) {
  return (
    firstProgress.sovLineItem.sovLineItemGroup?.id !==
    secondProgress.sovLineItem.sovLineItemGroup?.id
  )
}
