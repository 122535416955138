/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import * as developmentConfig from '../../common/config/config.development'
import * as productionConfig from '../../common/config/config.production'
import * as stagingConfig from '../../common/config/config.staging'

const apiEnvFromMeta = import.meta.env?.VITE_API_ENV
export const API_ENV = typeof apiEnvFromMeta === 'string' ? apiEnvFromMeta : 'local'

const sentryReleaseFromMeta = import.meta.env?.VITE_SENTRY_RELEASE
export const SENTRY_RELEASE =
  typeof sentryReleaseFromMeta === 'string' ? sentryReleaseFromMeta : undefined

let apiConfig
if (API_ENV === 'production') {
  apiConfig = productionConfig
} else if (API_ENV === 'staging') {
  apiConfig = stagingConfig
} else {
  apiConfig = developmentConfig
}

export const {
  apiBaseUrl,
  firebaseConfig,
  attachmentsBaseUrl,
  pdfBaseUrl,
  reportingBaseUrl,
  googleMapsApiKey,
  segmentKey,
  segmentCdnUrl,
  segmentApiHost,
  subscriptionsBaseUrl,
  logRocketApiUrl,
  sentryTunnel,
} = apiConfig

export const signinEmailOrigin = 'support@siteline.com'
export const termsOfServiceUrl = 'https://siteline.com/terms'
export const privacyPolicyUrl = 'https://siteline.com/privacy'
export const sentryDsn = 'https://513a34a157094f7093372be6ef060003@o428324.ingest.sentry.io/5374497'
export const logRocketProject = '1lnfgz/siteline'

const IS_DEV = import.meta.env?.DEV ?? true

const webProtocol = IS_DEV ? 'http' : 'https'
const socketsProtocol = IS_DEV ? 'ws' : 'wss'
export const config = {
  url: {
    GRAPHQL_API_URL: `${webProtocol}://${apiBaseUrl}/graphql`,
    CHAT_API_URL: `${webProtocol}://${apiBaseUrl}/chat`,
    SUBSCRIPTIONS_URL: `${socketsProtocol}://${subscriptionsBaseUrl}/subscriptions`,
  },
  support: {
    email: 'support@siteline.com',
    phone: '16503601267', // Zendesk support number
  },
}

// Set to true to enable maintenance mode
// Note that it's set to `let` on purpose so that eslint doesn't tag if (IS_MAINTENANCE_ENABLED)
// as unnecessary.
// eslint-disable-next-line prefer-const
export let IS_MAINTENANCE_ENABLED = false
