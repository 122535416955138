import { ApolloCache } from '@apollo/client'
import { NavigateOptions } from '@tanstack/react-router'
import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback } from 'react'
import { IntegrationType, LegalDocumentStatus, normalizeString } from 'siteline-common-all'
import { IntegrationMappingsTextura } from 'siteline-common-all/src/types/integration'
import {
  OnboardedProjectVendorsStatus,
  evictWithGc,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import { useProjectContext } from '../../common/contexts/ProjectContext'
import * as fragments from '../../common/graphql/Fragments'
import {
  Contract,
  CreateVendorContactMutation,
  DocumentExpirationFrequency,
  DocumentRequestStatus,
  GetCompanyContractsForVendorsQuery,
  GetContractForProjectSettingsQuery,
  GetContractForVendorsProjectHomeQuery,
  GetVendorLienWaiversByMonthDocument,
  GetVendorsDocument,
  GetVendorsQuery,
  LegalRequirementProperties,
  LienWaiverForTrackerProperties,
  MinimalIntegrationProperties,
  MinimalVendorContractProperties,
  MinimalVendorProperties,
  ProjectLegalRequirementsDocument,
  Vendor,
  VendorContractProperties,
  VendorLegalRequirementProperties,
  VendorLegalRequirementsDocument,
  useAddVendorsToProjectMutation,
  useCreateVendorContactMutation,
  useUploadLegalDocumentsMutation,
} from '../../common/graphql/apollo-operations'
import {
  LegalDocumentStatusInfo,
  VendorLegalDocument,
  getVendorLegalDocumentStatus,
  isLegalDocumentComplete,
  userToMinimalUserInfo,
} from '../../common/util/LegalDocument'
import {
  SKIPPED_LEGAL_REQUIREMENT_PERIOD_FORMAT,
  getLegalRequirementFormTitle,
  getLegalRequirementOutstandingForms,
  getPeriodStartFromPeriodEnd,
  nonRecurringLegalDocumentExpirationFrequencies,
} from '../../common/util/LegalRequirement'

export enum VendorsPathType {
  PROJECTS = 'projects',
  VENDORS = 'vendors',
  TRACKER = 'tracker',
}
export enum VendorsProjectTab {
  LIEN_WAIVERS = 'lien-waivers',
  DOCUMENTS = 'documents',
  SETTINGS = 'settings',
}
export enum VendorsProjectSetup {
  SETUP = 'setup',
}
export enum VendorsTrackerView {
  PROJECTS = 'projects',
  VENDORS = 'vendors',
}
// Setup isn't an actual tab in the lower tier projects module, but it is a url that the user
// can land on if they haven't finished lower tiers project setup
type VendorsProjectUrlType = VendorsProjectTab | VendorsProjectSetup

export const vendorsProjectTabs = [
  VendorsProjectTab.LIEN_WAIVERS,
  VendorsProjectTab.DOCUMENTS,
  VendorsProjectTab.SETTINGS,
]

type VendorsPathParams =
  | {
      pathType: VendorsPathType.TRACKER
      viewBy?: VendorsTrackerView
      expandedId?: string
    }
  | {
      pathType: VendorsPathType.PROJECTS
      projectId: string
      projectTab?: VendorsProjectUrlType
      requirementId?: string
    }
  | {
      pathType: VendorsPathType.VENDORS
      vendorId: string
    }

/** Returns a path to the corresponding route within the vendors module */
export function getVendorsPath(
  params: VendorsPathParams = { pathType: VendorsPathType.TRACKER }
): NavigateOptions {
  if (params.pathType === VendorsPathType.PROJECTS) {
    const projectTab = params.projectTab ?? VendorsProjectTab.LIEN_WAIVERS
    return {
      to: '/vendors/projects/$projectId/$tab',
      params: {
        projectId: params.projectId,
        tab: projectTab,
      },
      search: { requirementId: params.requirementId },
    }
  }

  if (params.pathType === VendorsPathType.VENDORS) {
    return {
      to: '/vendors/$vendorId',
      params: { vendorId: params.vendorId },
    }
  }

  const viewBy = params.viewBy ?? VendorsTrackerView.PROJECTS
  if (viewBy === VendorsTrackerView.PROJECTS) {
    return { to: '/vendors/projects', search: { contractId: params.expandedId } }
  } else {
    return { to: '/vendors/vendors', search: { vendorId: params.expandedId } }
  }
}

/** Returns a status for the lien waiver and additional info on the status */
export function getLienWaiverStatus(
  lienWaiver: Pick<
    LienWaiverForTrackerProperties,
    'lastSyncIfSuccessful' | 'lastSendTime' | 'uploadedFile' | 'amount' | 'defaultAmount'
  >,
  lienWaiverRequests: readonly Pick<
    LienWaiverForTrackerProperties['lienWaiverRequests'][number],
    'updatedAt' | 'actionTakenAt' | 'vendorContact' | 'statusLogs'
  >[],
  timeZone: string
): LegalDocumentStatusInfo {
  // The overall status comes from the most complete request. Start with a NOT_REQUESTED
  // lien waiver and update the status with each consecutive action to build the overall status.
  let statusInfo: LegalDocumentStatusInfo = {
    status: LegalDocumentStatus.NOT_REQUESTED,
    actions: [],
    amount: lienWaiver.defaultAmount ?? lienWaiver.amount ?? undefined,
    timeZone,
  }

  // Include actions for every request, reminder, view, and completion
  const requestActions = lienWaiverRequests.flatMap((request) => {
    const actions = []

    // Separate SENT actions into initial requests and reminders
    const sentStatusLogs = request.statusLogs.filter(
      (statusLog) => statusLog.status === DocumentRequestStatus.SENT
    )
    const sortedSentStatusLogs = _.orderBy(
      sentStatusLogs,
      (statusLog) => moment.tz(statusLog.statusUpdatedAt, timeZone),
      'asc'
    )
    if (sortedSentStatusLogs.length > 0) {
      actions.push(
        {
          action: LegalDocumentStatus.REQUESTED,
          actionTakenAt: moment.tz(sortedSentStatusLogs[0].statusUpdatedAt, timeZone),
          actionTakenBy: request.vendorContact,
        },
        ...sortedSentStatusLogs.slice(1).map((statusLog) => ({
          action: LegalDocumentStatus.REMINDED,
          actionTakenAt: moment.tz(statusLog.statusUpdatedAt, timeZone),
          actionTakenBy: request.vendorContact,
        }))
      )
    }

    const viewedAndSignedActions = _.chain(request.statusLogs)
      .map((statusLog) => {
        let action
        if (statusLog.status === DocumentRequestStatus.VIEWED) {
          action = LegalDocumentStatus.VIEWED
        } else if (statusLog.status === DocumentRequestStatus.COMPLETE) {
          action = LegalDocumentStatus.SIGNED
        } else {
          return null
        }
        return {
          action,
          actionTakenAt: moment.tz(statusLog.statusUpdatedAt, timeZone),
          actionTakenBy: request.vendorContact,
        }
      })
      .compact()
      .value()
    actions.push(...viewedAndSignedActions)

    return actions
  })

  // Use the latest status log to determine the overall status (which may be overwritten
  // if the lien waiver has been completed and/or submitted)
  const latestAction = _.maxBy(requestActions, (action) => action.actionTakenAt.unix())
  if (latestAction) {
    statusInfo = { ...statusInfo, status: latestAction.action, actions: requestActions }
  }

  // If a file was uploaded by the sub, update the status and add an action
  const hasVendorCompleted =
    latestAction !== undefined && latestAction.action === LegalDocumentStatus.SIGNED
  if (lienWaiver.uploadedFile && !hasVendorCompleted) {
    const uploadedAt = moment.tz(lienWaiver.uploadedFile.createdAt, timeZone)
    // Only add an action if this isn't the upload from a vendor. Only way to check is by
    // comparing the action time with
    statusInfo = {
      ...statusInfo,
      status: LegalDocumentStatus.UPLOADED,
      actions: [
        ...statusInfo.actions,
        {
          action: LegalDocumentStatus.UPLOADED,
          actionTakenAt: uploadedAt,
          actionTakenBy: lienWaiver.uploadedFile.uploadedBy
            ? userToMinimalUserInfo(lienWaiver.uploadedFile.uploadedBy)
            : undefined,
        },
      ],
    }
  }

  // If the lien waiver has been submitted, update the status and add an action
  if (lienWaiver.lastSendTime) {
    statusInfo = {
      ...statusInfo,
      status: LegalDocumentStatus.SUBMITTED,
      actions: [
        ...statusInfo.actions,
        {
          action: LegalDocumentStatus.SUBMITTED,
          actionTakenAt: moment.tz(lienWaiver.lastSendTime, timeZone),
        },
      ],
    }
  }

  // If the lien waiver has been synced, update the status and add an action
  const lastSync = lienWaiver.lastSyncIfSuccessful
  if (lastSync) {
    // Sync is a special case, because it doesn't come from the request's status logs
    statusInfo = {
      ...statusInfo,
      status: LegalDocumentStatus.SYNCED,
      actions: [
        ...statusInfo.actions,
        {
          action: LegalDocumentStatus.SYNCED,
          actionTakenAt: moment.tz(lastSync.createdAt, timeZone),
        },
      ],
    }
  }

  return statusInfo
}

/** An element ID for a vendor details period detail card, used for scrolling */
export function getVendorDetailsPeriodCardId(period: string) {
  return `vendor-details-${period}`
}

export function getCreateVendorContactCacheUpdate(vendor?: MinimalVendorProperties) {
  return (
    cache: ApolloCache<CreateVendorContactMutation>,
    { data }: { data?: CreateVendorContactMutation | null }
  ) => {
    if (!data || !vendor) {
      return
    }

    cache.modify<WritableDeep<Vendor>>({
      id: cache.identify(vendor),
      fields: {
        contacts(existingContacts, { toReference }) {
          const newContactRef = cache.writeFragment({
            data: data.createVendorContact,
            fragment: fragments.vendorContact,
            fragmentName: 'VendorContactProperties',
          })
          const refs = toReferences(existingContacts, toReference)
          return _.compact([...refs, newContactRef])
        },
      },
    })
  }
}

/** Utility hook for creating a vendor contact and updating the cache */
export function useCreateVendorContact(vendor?: MinimalVendorProperties) {
  return useCreateVendorContactMutation({
    update: getCreateVendorContactCacheUpdate(vendor),
  })
}

/** Returns true if a period for a recurring legal requirement has been skipped */
function isPeriodSkipped(
  periodEnd: Moment | undefined,
  skippedPeriods: string[] | readonly string[],
  timeZone: string
) {
  return (
    !!periodEnd &&
    skippedPeriods.some((skippedPeriod) => {
      const period = moment.tz(skippedPeriod, SKIPPED_LEGAL_REQUIREMENT_PERIOD_FORMAT, timeZone)
      return periodEnd.isSame(period, 'date')
    })
  )
}

/** Returns the period string to use for a single period of a requirement */
export function getPeriodForRequirement(
  requirement: Pick<LegalRequirementProperties, 'name' | 'expirationFrequency' | 'type'>,
  periodStart: Moment | undefined,
  periodEnd: Moment | undefined,
  t: TFunction,
  timeZone: string
) {
  const today = moment.tz(timeZone)
  if (requirement.expirationFrequency === DocumentExpirationFrequency.USER_INPUT && periodEnd) {
    const expiresThisYear = periodEnd.isSame(today, 'year')
    const date = periodEnd.format(expiresThisYear ? 'MMM D' : 'MMM D, YYYY')
    return periodEnd.isSameOrAfter(today)
      ? t('vendor_home.vendor_documents.expires_date', { date })
      : t('vendor_home.vendor_documents.expired_date', { date })
  }
  if (!nonRecurringLegalDocumentExpirationFrequencies.includes(requirement.expirationFrequency)) {
    return getLegalRequirementFormTitle(requirement, t, today, periodEnd)
  }
  return ''
}

/**
 * Returns a set of VendorLegalDocument objects from VendorLegalRequirements, where a VendorLegalDocument corresponds
 * to the document (which may not yet exist) for a single period as dictated by the requirement's frequency
 */
export function getVendorLegalDocuments<T extends VendorLegalRequirementProperties>(
  vendorRequirements: T[],
  getVendor: (requirement: T) => MinimalVendorProperties,
  t: TFunction
): VendorLegalDocument[] {
  const requirementDocuments = vendorRequirements.flatMap((vendorRequirement) => {
    const requirement = vendorRequirement.legalRequirement
    const timeZone = requirement.timeZone
    const vendor = getVendor(vendorRequirement)
    return requirement.documents
      .filter((document) => document.vendorContract?.id === vendorRequirement.vendorContract.id)
      .map((document) => {
        const periodStart = document.periodStart
          ? moment.tz(document.periodStart, timeZone)
          : undefined
        const periodEnd = document.periodEnd ? moment.tz(document.periodEnd, timeZone) : undefined
        return {
          title: requirement.name,
          periodStart,
          periodEnd,
          document,
          vendorContractId: vendorRequirement.vendorContract.id,
          vendor,
          contractId: requirement.contract.id,
          projectId: requirement.contract.project.id,
          projectName: requirement.contract.project.name,
          period: getPeriodForRequirement(requirement, periodStart, periodEnd, t, timeZone),
          status: getVendorLegalDocumentStatus(
            document,
            document.legalDocumentRequests,
            requirement.expirationFrequency,
            timeZone
          ),
          timeZone,
          isSkippedPeriod: isPeriodSkipped(periodEnd, vendorRequirement.skippedPeriods, timeZone),
          legalRequirement: requirement,
          vendorRequirement,
        }
      })
  })
  const outstandingDocuments = vendorRequirements
    .flatMap((vendorRequirement) => {
      const requirement = vendorRequirement.legalRequirement
      const timeZone = requirement.timeZone
      const vendor = getVendor(vendorRequirement)
      const forms = getLegalRequirementOutstandingForms(
        requirement,
        t,
        timeZone,
        vendorRequirement.vendorContract.id
      )
      return forms.map((form) => ({
        ...form,
        title: requirement.name,
        vendor,
        vendorContractId: vendorRequirement.vendorContract.id,
        contractId: requirement.contract.id,
        projectId: requirement.contract.project.id,
        projectName: requirement.contract.project.name,
        period: getPeriodForRequirement(requirement, form.periodStart, form.periodEnd, t, timeZone),
        status: { status: LegalDocumentStatus.NOT_REQUESTED, actions: [], timeZone },
        timeZone,
        isSkippedPeriod: isPeriodSkipped(
          form.periodEnd,
          vendorRequirement.skippedPeriods,
          timeZone
        ),
        legalRequirement: requirement,
        vendorRequirement,
      }))
    })
    // USER_INPUT requirements may have duplicates if a form has been requested but not completed,
    // as no expiration will be set yet, so remove forms that match an existing document
    .filter((form) => {
      if (
        form.vendorRequirement.legalRequirement.expirationFrequency !==
        DocumentExpirationFrequency.USER_INPUT
      ) {
        return true
      }
      return !requirementDocuments.some(
        (document) =>
          document.vendorContractId === form.vendorContractId &&
          document.legalRequirement.id === form.legalRequirement.id &&
          !document.periodEnd &&
          !form.periodEnd
      )
    })
  return [...outstandingDocuments, ...requirementDocuments]
}

/** Handle cache updates for the update legal documents mutation */
export function useUploadLegalDocuments() {
  const [uploadLegalDocumentsInternal] = useUploadLegalDocumentsMutation()

  async function uploadLegalDocuments(
    vendorRequirement: VendorLegalRequirementProperties,
    files: File[],
    periodStart: Moment | undefined,
    periodEnd: Moment | undefined
  ) {
    const documents = files.map((file) => {
      // If a start date is provided, use it; otherwise, determine the period start
      // based on the period end (if provided)
      const filePeriodStart = periodStart
        ? periodStart.toISOString()
        : getPeriodStartFromPeriodEnd(
            vendorRequirement.legalRequirement.expirationFrequency,
            periodEnd
          )?.toISOString()
      return {
        file,
        name: file.name,
        periodStart: filePeriodStart,
        periodEnd: periodEnd?.toISOString(),
      }
    })
    return uploadLegalDocumentsInternal({
      variables: {
        input: {
          legalRequirementId: vendorRequirement.legalRequirement.id,
          documents,
          vendorContractId: vendorRequirement.vendorContract.id,
        },
      },
      update(cache, { data }) {
        if (!data) {
          return
        }

        cache.modify({
          id: cache.identify(vendorRequirement),
          fields: {
            skippedPeriods() {
              return periodEnd
                ? vendorRequirement.skippedPeriods.filter(
                    (period) => period !== periodEnd.format(SKIPPED_LEGAL_REQUIREMENT_PERIOD_FORMAT)
                  )
                : []
            },
          },
        })
      },
    })
  }

  return [uploadLegalDocuments]
}

/**
 * Given a list of vendor requirements, return the subset of vendors that match the legal requirement and
 * have a document for the given period, as well as the vendor's documents that match that period
 */
export function getVendorsForRequirementPeriod(
  vendorRequirements: VendorLegalRequirementProperties[],
  requirementId: string,
  periodEnd: string | null,
  timeZone: string,
  t: TFunction
) {
  return _.chain(vendorRequirements)
    .map((vendorRequirement) => {
      // Exclude vendor requirements that aren't for the selected legal requirement
      if (vendorRequirement.legalRequirement.id !== requirementId) {
        return null
      }
      const completedVendorDocuments = vendorRequirement.legalRequirement.documents.filter(
        (document) =>
          document.vendorContract?.id === vendorRequirement.vendorContract.id &&
          isLegalDocumentComplete(document)
      )
      // Exclude vendors that don't have any completed forms
      if (completedVendorDocuments.length === 0) {
        return null
      }
      const periodDocuments = completedVendorDocuments.filter((document) => {
        if (
          vendorRequirement.legalRequirement.expirationFrequency ===
          DocumentExpirationFrequency.NEVER
        ) {
          return true
        }
        const documentPeriod = getPeriodForRequirement(
          vendorRequirement.legalRequirement,
          document.periodStart ? moment.tz(document.periodStart, timeZone) : undefined,
          document.periodEnd ? moment.tz(document.periodEnd, timeZone) : undefined,
          t,
          timeZone
        )
        return documentPeriod === periodEnd
      })
      // Exclude vendors that don't have any completed forms for the selected period, if recurring
      if (periodDocuments.length === 0) {
        return null
      }
      return {
        vendor: vendorRequirement.vendorContract.vendor,
        documents: periodDocuments,
      }
    })
    .compact()
    .value()
}

type MinimalVendor = Pick<MinimalVendorProperties, 'id' | 'name'>
type SortableVendorContract = Pick<MinimalVendorContractProperties, 'id'> & {
  vendor: MinimalVendor
  lowerTierTo: (Pick<MinimalVendorContractProperties, 'id'> & { vendor: MinimalVendor }) | null
}

/** Sorts a list of vendor contracts alphabetically, with sub-tiers appearing below their parent */
export function sortByVendorContract<K, T extends SortableVendorContract>(
  sortable: readonly K[],
  getVendorContract: (sortable: K) => T | null,
  order: 'asc' | 'desc' = 'asc'
) {
  return _.orderBy(
    sortable,
    [
      (sortableItem) => {
        const vendorContract = getVendorContract(sortableItem)
        // Use the name of the vendor, or if this is a third-tier vendor use the name
        // of the second-tier vendor first
        if (vendorContract?.lowerTierTo) {
          return normalizeString(vendorContract.lowerTierTo.vendor.name)
        }
        return normalizeString(vendorContract?.vendor.name ?? '')
      },
      // Put the second-tier vendor before any third-tiers
      (sortableItem) => {
        const vendorContract = getVendorContract(sortableItem)
        return vendorContract?.lowerTierTo === null ? 0 : 1
      },
      // Order third-tiers by name
      (sortableItem) => {
        const vendorContract = getVendorContract(sortableItem)
        return normalizeString(vendorContract?.vendor.name ?? '')
      },
    ],
    [order, 'asc', order]
  )
}

export enum VendorProjectOnboardingStep {
  LIEN_WAIVERS = 'lienWaivers',
  LEGAL_REQUIREMENTS = 'legalRequirements',
  VENDORS = 'vendors',
  GC_RECIPIENTS = 'gcRecipients',
  FINISH_ONBOARDING = 'finishOnboarding',
}

const onboardingTaskToStatusKey: Record<
  VendorProjectOnboardingStep,
  keyof OnboardedProjectVendorsStatus
> = {
  [VendorProjectOnboardingStep.LIEN_WAIVERS]: 'addedVendorLienWaivers',
  [VendorProjectOnboardingStep.LEGAL_REQUIREMENTS]: 'addedLegalRequirements',
  [VendorProjectOnboardingStep.VENDORS]: 'addedVendors',
  [VendorProjectOnboardingStep.GC_RECIPIENTS]: 'addedGcRecipients',
  [VendorProjectOnboardingStep.FINISH_ONBOARDING]: 'completedOnboarding',
}

const ORDERED_ONBOARDING_TASKS = [
  VendorProjectOnboardingStep.LIEN_WAIVERS,
  VendorProjectOnboardingStep.LEGAL_REQUIREMENTS,
  VendorProjectOnboardingStep.VENDORS,
  VendorProjectOnboardingStep.GC_RECIPIENTS,
  VendorProjectOnboardingStep.FINISH_ONBOARDING,
]

export function nextIncompleteOnboardingTask(
  onboardedStatus: OnboardedProjectVendorsStatus,
  fromTask?: VendorProjectOnboardingStep | null
) {
  let index = 0
  if (fromTask) {
    // From the previous step, move the index to the next
    index = ORDERED_ONBOARDING_TASKS.indexOf(fromTask) + 1
  }
  while (index < ORDERED_ONBOARDING_TASKS.length) {
    const currentTask = ORDERED_ONBOARDING_TASKS[index]
    const statusKey = onboardingTaskToStatusKey[currentTask]
    if (onboardedStatus[statusKey] === false) {
      return currentTask
    }
    index++
  }
  return ORDERED_ONBOARDING_TASKS[index]
}

export type ContractForVendorsProjectHome =
  GetContractForVendorsProjectHomeQuery['contractByProjectId']

export type ContractForProjectSettings = GetContractForProjectSettingsQuery['contractByProjectId']

export type ContractForBulkApAddVendors = GetCompanyContractsForVendorsQuery['contracts'][number]

export type LegalRequirementForProjectSettings =
  ContractForProjectSettings['legalRequirements'][number]

/** Handles adding existing vendors to a project */
export function useAddVendorsToProject() {
  const { id: projectId } = useProjectContext()
  const { companyId } = useCompanyContext()

  const snackbar = useSitelineSnackbar()

  const [addVendorsToProject] = useAddVendorsToProjectMutation({})

  return useCallback(
    async ({
      vendorIds,
      lowerTierToVendorContractId,
      onError,
      allVendors,
      contract,
    }: {
      vendorIds: string[]
      lowerTierToVendorContractId?: string
      allVendors: GetVendorsQuery['vendors']
      contract?:
        | ContractForVendorsProjectHome
        | ContractForProjectSettings
        | ContractForBulkApAddVendors
      /**
       * If undefined, generic error handling will be applied. If null, the error will be thrown.
       * If a function is provided, the error will be caught and passed to the provided function.
       */
      onError?: ((error: Error) => void) | null
    }): Promise<VendorContractProperties[] | undefined | void> => {
      if (!contract) {
        return
      }
      try {
        const allVendorIds = allVendors.map(({ id }) => id)
        const { data } = await addVendorsToProject({
          variables: {
            input: {
              contractId: contract.id,
              vendorIds,
              lowerTierToVendorContractId,
            },
          },
          update(cache, { data }) {
            if (!data) {
              return
            }

            const newRefs = data.addVendorsToProject.map((addVendorContract) =>
              cache.writeFragment({
                data: addVendorContract,
                fragment: fragments.vendorContract,
                fragmentName: 'VendorContractProperties',
              })
            )

            // Evict the paginated vendors queries to ensure the new vendor is included in the list
            evictWithGc(cache, (evict) =>
              evict({ id: 'ROOT_QUERY', fieldName: 'paginatedVendorContracts' })
            )

            cache.modify<WritableDeep<Contract>>({
              id: cache.identify(contract),
              fields: {
                vendorContracts(existingVendorContracts, { readField, toReference }) {
                  const refs = toReferences(existingVendorContracts, toReference)
                  const existingRefs = refs.filter(
                    (vendorContract) =>
                      !data.addVendorsToProject.some(
                        (newVendorContract) =>
                          newVendorContract.id === readField('id', vendorContract)
                      )
                  )
                  return _.compact([...existingRefs, ...newRefs])
                },
              },
            })
          },
          refetchQueries: [
            ...(allVendorIds.length > 0
              ? [
                  {
                    query: GetVendorLienWaiversByMonthDocument,
                    variables: { input: { vendorIds: allVendorIds, companyId } },
                  },
                ]
              : []),
            { query: VendorLegalRequirementsDocument, variables: { vendorIds } },
            {
              query: ProjectLegalRequirementsDocument,
              variables: { input: { projectIds: [projectId], companyId } },
            },
            { query: GetVendorsDocument, variables: { input: { companyId } } },
          ],
        })
        return [...(data?.addVendorsToProject ?? [])]
      } catch (error) {
        if (onError === null) {
          throw error
        } else if (onError === undefined) {
          snackbar.showError(error.message)
          return
        }
        onError(error)
      }
    },
    [addVendorsToProject, companyId, projectId, snackbar]
  )
}

export function sitelineVendorIdsFromIntegration(integration: MinimalIntegrationProperties | null) {
  if (!integration) {
    return new Set<string>()
  }
  switch (integration.type) {
    case IntegrationType.TEXTURA: {
      const mappings = integration.mappings as IntegrationMappingsTextura
      const vendorContractIds = mappings.vendorContracts.map((mapping) => mapping.sitelineVendorId)
      return new Set(vendorContractIds)
    }
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.ACUMATICA:
    case IntegrationType.VISTA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.TEST:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.CMIC:
      // Textura is currently the only GC portal supporting vendor linking
      return new Set<string>()
  }
}
