import { Container } from '@mui/material'
import { Breakpoint, Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { DebouncedFunc } from 'lodash'
import { useCallback } from 'react'
import { Waypoint } from 'react-waypoint'
import { colors, makeStylesFast } from 'siteline-common-web'
import { HEADER_HEIGHT, TOP_HEADER_HEIGHT, defaultMinHeight, themeSpacing } from '../themes/Main'
import { SitelineFooter } from './SitelineFooter'
import { VerticalTab, VerticalTabs } from './SitelineTabs'

export const HIDE_VERTICAL_TABS_BREAKPOINT = 'lg'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    backgroundColor: colors.grey10,
    minHeight: defaultMinHeight(),
    '& .content': {
      padding: theme.spacing(0, 1, 0, 2),
      display: 'flex',
      '& .sectionCards': {
        flexGrow: 1,
        overflow: 'hidden',
        '& .sectionCard': {
          border: `1px solid ${colors.grey20}`,
          borderRadius: theme.spacing(0.5),
          backgroundColor: colors.white,
          padding: theme.spacing(3, 4),
          marginBottom: theme.spacing(1),
        },
      },
    },
  },
  sidebar: {
    minWidth: '22%',
    '& .inner': {
      position: 'sticky',
      top: TOP_HEADER_HEIGHT + HEADER_HEIGHT + themeSpacing(3),
      '& .MuiTabs-indicator': {
        backgroundColor: colors.blue50,
        minHeight: 24,
        maxHeight: 24,
        transform: 'translateY(6px)',
      },
      '& .MuiTab-root': {
        paddingLeft: theme.spacing(2.5),
        marginBottom: theme.spacing(1),
        minHeight: 32,
        '&:last-child': {
          marginBottom: theme.spacing(0),
        },
        '&.Mui-selected': {
          color: colors.blue50,
        },
      },
    },
    [theme.breakpoints.down(HIDE_VERTICAL_TABS_BREAKPOINT)]: {
      display: 'none',
    },
  },
}))

type SidebarTab<T> = {
  key: string
  label: string
  value: T
}

interface SidebarProps<T> {
  tabs: SidebarTab<T>[]
  selectedSection: T
  onSelectedSectionChange: (section: T) => void
}

/** The side navigation menu for the container below  */
function Sidebar<T>({ tabs, selectedSection, onSelectedSectionChange }: SidebarProps<T>) {
  const classes = useStyles()

  const handleChange = useCallback(
    (_event: unknown, toSection: T) => onSelectedSectionChange(toSection),
    [onSelectedSectionChange]
  )

  return (
    // Wrap tabs in a div since the sticky position won't work if the parent is a flexbox
    <div className={classes.sidebar}>
      <div className="inner">
        <VerticalTabs
          indicatorColor="primary"
          textColor="primary"
          orientation="vertical"
          value={selectedSection}
          onChange={handleChange}
        >
          {tabs.map(({ key, value, label }) => (
            <VerticalTab key={key} value={value} label={label} />
          ))}
        </VerticalTabs>
      </div>
    </div>
  )
}

interface ContainerWithSideTabsProps<T> extends React.HTMLProps<HTMLDivElement> {
  sidebarTabs: SidebarTab<T>[]
  selectedSection: T
  onSelectedSectionChange: (value: T) => void
  handleScrollToSection: (section: T) => void
  handleScrollSectionIntoView: DebouncedFunc<(section: T) => void>
  isWaypointDisabled: boolean
  disableWaypoint: () => void
  sections: {
    section: T
    content: React.ReactNode
  }[]
  maxWidth?: false | Breakpoint
}

/** Renders a container with navigational tabs on the left */
export function ContainerWithSideTabs<T>(props: ContainerWithSideTabsProps<T>) {
  const classes = useStyles()
  const {
    sidebarTabs,
    selectedSection,
    onSelectedSectionChange,
    handleScrollToSection,
    handleScrollSectionIntoView,
    isWaypointDisabled,
    disableWaypoint,
    className,
    sections,
    maxWidth = 'lg',
    ...rest
  } = props

  const handleEnterTopWaypoint = useCallback(() => {
    if (!isWaypointDisabled) {
      onSelectedSectionChange(sidebarTabs[0].value)
      disableWaypoint()
    }
  }, [disableWaypoint, isWaypointDisabled, onSelectedSectionChange, sidebarTabs])

  const handleEnterBottomWaypoint = useCallback(() => {
    if (!isWaypointDisabled) {
      onSelectedSectionChange(sidebarTabs[sidebarTabs.length - 1].value)
      disableWaypoint()
    }
  }, [disableWaypoint, isWaypointDisabled, onSelectedSectionChange, sidebarTabs])

  const handleSectionChange = useCallback(
    (section: T) => {
      onSelectedSectionChange(section)
      handleScrollToSection(section)
    },
    [handleScrollToSection, onSelectedSectionChange]
  )

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Waypoint
          onEnter={handleEnterTopWaypoint}
          topOffset={TOP_HEADER_HEIGHT}
          fireOnRapidScroll={false}
        />
        <Container className="content" maxWidth={maxWidth} disableGutters>
          <Sidebar
            tabs={sidebarTabs}
            selectedSection={selectedSection}
            onSelectedSectionChange={handleSectionChange}
          />
          <div className="sectionCards">
            {sections.map(({ section, content }) => (
              <div className="sectionCard" key={String(section)}>
                <Waypoint
                  onEnter={() => handleScrollSectionIntoView(section)}
                  topOffset="40%"
                  bottomOffset="55%"
                  fireOnRapidScroll={false}
                >
                  <div className={String(section)}>{content}</div>
                </Waypoint>
              </div>
            ))}
          </div>
        </Container>
        <Waypoint onEnter={handleEnterBottomWaypoint} fireOnRapidScroll={false} />
      </div>
      <SitelineFooter backgroundColor={colors.grey10} />
    </>
  )
}
