import { TFunction } from 'i18next'
import _ from 'lodash'
import { makeNumericCollator } from 'siteline-common-all'
import { SovLineItemProperties } from '../graphql/apollo-operations'
import { EditingSovLineItem } from './ManageSov'

/**
 * Given a code (string), returns the next consecutive code. This is called by `getNextCode`.
 * If `type` is 'previous', returns the previous (decremented) code. By default, returns the next code.
 * ex:
 * incrementCode('001') => '002'
 * incrementCode('21-001-1') => '21-001-2'
 * incrementCode('21-001-1', 'previous') => '21-001-0'
 *
 * @internal
 */
export function incrementCode(
  code: string | undefined,
  type: 'previous' | 'next' = 'next',
  incrementor = 1
): string {
  // If the latest internal number ends in digits, use only those digits and copy over
  // the rest. This is to handle cases like 21-001-1, 21-001-2, etc
  const match = code?.match(/[0-9]+$/)
  if (!code || !match) {
    return ''
  }

  const codeToIncrement = match[0]
  const newCode =
    type === 'previous'
      ? Number(codeToIncrement) - incrementor
      : Number(codeToIncrement) + incrementor
  if (isNaN(newCode)) {
    return ''
  }

  const numLeadingZeroesToAdd = codeToIncrement.length - newCode.toString().length
  // Add 1 since join is called n-1 times (where array length = n)
  const newCodeString = Array(numLeadingZeroesToAdd + 1).join('0') + newCode.toString()
  const prefix = code.slice(0, match.index)
  return prefix + newCodeString
}

/**
 * Predict the next code based on existing codes by incrementing from the "maximum" code.
 *
 * ex:
 * getNextCode(['001', '002', '003']) => '004'
 * getNextCode(['21-001-1', '21-001-2']) => '21-001-3'
 **/
export function getNextCode(existingCodes: string[]): string {
  const COLLATOR = makeNumericCollator()
  const sorted = existingCodes.sort(COLLATOR.compare)
  const latestCode = sorted.pop()
  return incrementCode(latestCode)
}

/** Returns the total original value of an SOV, i.e. the sum of all non-change order line items */
export function originalContractValue(
  sovLineItems: Pick<SovLineItemProperties, 'isChangeOrder' | 'latestTotalValue'>[]
) {
  return _.sumBy(sovLineItems, (sovLineItem) =>
    sovLineItem.isChangeOrder ? 0 : sovLineItem.latestTotalValue
  )
}

/** Returns the total value of an SOV, including original line items and change orders */
export function contractSumToDate(sovLineItems: Pick<SovLineItemProperties, 'latestTotalValue'>[]) {
  return _.sumBy(sovLineItems, (sovLineItem) => sovLineItem.latestTotalValue)
}

/**
 * Returns true if an array contains one or more codes, and each one is sequential from the first
 * to the last (where "sequential" is defined by our logic for incrementing codes)
 * @internal
 */
export function areCodesSequential(codes: string[]) {
  if (codes.length === 0) {
    return false
  }
  return codes.every((code, index) => {
    return index === codes.length - 1 || incrementCode(code) === codes[index + 1]
  })
}

export type LineItemForRenumbering = Pick<EditingSovLineItem, 'id' | 'code'>

type RenumberHintContent<T extends LineItemForRenumbering> = {
  body: string
  action: string
  lineItemsToRenumberedLineItems: (lineItems: T[]) => T[]
  showAtRowId: string
}

const sovI18nBase = 'projects.subcontractors.sov'

/**
 * Given an ordered list of line items and a code update for a line item in the list, returns the
 * content for a spreadsheet hint to show if it makes sense to suggest automatically renumbering the
 * line items.  Returns null otherwise.
 *
 * We show the hint if the line items starting from the edited one were previously in sequential
 * order, and the new code is off by one from the previous logical first code. For example:
 * - If the codes were previously [1, 2, 3, 4] and are now [2, 2, 3, 4], we suggest renumbering to
 * make the list [2, 3, 4, 5]
 * - If the codes were previously [2, 3, 4, 5] and are now [1, 3, 4, 5], we suggest renumbering to
 * make the list [1, 2, 3, 4]
 * - If the line item had no code previously and the list is now [1, 1, 2, 3], we suggest renumbering
 * to make the list [1, 2, 3, 4] (e.g. if the first line item were inserted above the existing list)
 */
export function renumberingHintForEditedCode<T extends LineItemForRenumbering>({
  lineItemIndex,
  sovLineItems,
  newCode,
  t,
}: {
  lineItemIndex: number
  sovLineItems: T[]
  newCode: string
  t: TFunction
}): RenumberHintContent<T> | null {
  const hasNextLineItem = lineItemIndex + 1 < sovLineItems.length
  if (!hasNextLineItem) {
    // If this is the last item in the list, we'll never suggest renumbering
    return null
  }

  // The code may be empty, e.g. if the line item was just inserted
  const oldCode = sovLineItems[lineItemIndex].code

  if (oldCode) {
    // If there was a code previously that has been edited, we suggest renumbering if:
    // 1. All line items were previously sequential, starting from the edited line item
    // 2. The newly entered code is one above OR below the previous code
    const followingLineItemCodes = sovLineItems
      .slice(lineItemIndex + 1)
      .map((lineItem) => lineItem.code)
    const wereLineItemsSequential = areCodesSequential([oldCode, ...followingLineItemCodes])
    const isIncrementingCode = newCode === incrementCode(oldCode)
    const isDecrementingCode = incrementCode(newCode) === oldCode
    const shouldSuggestRenumber =
      (isIncrementingCode || isDecrementingCode) && wereLineItemsSequential
    if (!shouldSuggestRenumber) {
      return null
    }
  } else {
    // If there was no code previously, we want to handle the case where a new line item has been
    // inserted within a previously ordered list. We suggest renumbering if both:
    // 1. The newly entered code is equal to the following line item's code
    // 2. All following line items are sequential
    const isIncrementingCode = sovLineItems[lineItemIndex + 1].code === newCode
    const followingLineItemCodes = sovLineItems
      .slice(lineItemIndex + 1)
      .map((lineItem) => lineItem.code)
    const areFollowingLineItemsSequential = areCodesSequential(followingLineItemCodes)
    const shouldSuggestRenumber = isIncrementingCode && areFollowingLineItemsSequential
    if (!shouldSuggestRenumber) {
      return null
    }
  }

  const showAtRowId = _.get(sovLineItems, [lineItemIndex, 'id'], null)
  if (!showAtRowId) {
    return null
  }

  return {
    showAtRowId,
    body: oldCode
      ? t(`${sovI18nBase}.renumbering_your_line_items`)
      : t(`${sovI18nBase}.keep_sequential`),
    action: t(`${sovI18nBase}.renumber`),
    lineItemsToRenumberedLineItems: (lineItems: T[]) => {
      const renumberedLineItems = lineItems.slice(lineItemIndex + 1).map((lineItem, index) => ({
        ...lineItem,
        code: incrementCode(newCode, 'next', index + 1),
      }))
      const newLineItems = [...lineItems]
      newLineItems.splice(lineItemIndex + 1, renumberedLineItems.length, ...renumberedLineItems)
      return newLineItems
    },
  }
}

/**
 * Given an ordered list of line items and a reordered line item in the list, returns the
 * content for a spreadsheet hint to show if it makes sense to suggest automatically renumbering the
 * line items.  Returns null otherwise.
 *
 * We show the hint if the line items starting from the edited one were previously in sequential
 * order and the line item was moved to a new location within that range.
 */
export function renumberingHintForReorderedLineItems<T extends LineItemForRenumbering>({
  toRowIndex,
  fromRowIndex,
  fromLineItemIndex,
  toLineItemIndex,
  originalLineItems,
  reorderedLineItems,
  t,
}: {
  toRowIndex: number
  fromRowIndex: number
  fromLineItemIndex: number
  toLineItemIndex: number
  originalLineItems: T[]
  reorderedLineItems: T[]
  t: TFunction
}) {
  // We always suggest renumbering the full range of line items relevant to the reorder, which means
  // it may start from the new position (if the line item was moved up in the list) or the original
  // position (if the line item was moved down in the list)
  const fromIndex = toRowIndex > fromRowIndex ? fromLineItemIndex : toLineItemIndex
  const previousCodes = originalLineItems.slice(fromIndex).map((lineItem) => lineItem.code)
  const wereCodesSequential = areCodesSequential(previousCodes)
  const newCodes = reorderedLineItems.slice(fromIndex).map((lineItem) => lineItem.code)
  const didCodesChange = !_.isEqual(previousCodes, newCodes)
  if (!wereCodesSequential || !didCodesChange) {
    return null
  }

  const showHintAtRowId = _.get(reorderedLineItems, [fromIndex, 'id'], null)
  if (!showHintAtRowId) {
    return null
  }

  return {
    showAtRowId: reorderedLineItems[fromIndex].id,
    body: t(`${sovI18nBase}.no_longer_sequential`),
    action: t(`${sovI18nBase}.renumber`),
    lineItemsToRenumberedLineItems: (lineItems: T[]) => {
      const firstCode = previousCodes[0]
      const renumberedLineItems = lineItems.slice(fromIndex).map((lineItem, index) => ({
        ...lineItem,
        code: incrementCode(firstCode, 'next', index),
      }))
      const allLineItems = [...lineItems]
      allLineItems.splice(fromIndex, renumberedLineItems.length, ...renumberedLineItems)
      return allLineItems
    },
  }
}
