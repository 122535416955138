import { TFunction } from 'i18next'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingType,
  CHANGE_ORDER_REQUEST_NAME_MAX_CHARS,
  ChangeOrderRequestEventType,
  TypedChangeOrderRequestEvent,
  formatCentsToDollars,
  safeDivide,
  sortByInternalNumber,
} from 'siteline-common-all'
import {
  bold,
  evictWithGc,
  getColumnSizesFromRows,
  leftAlign,
  number,
  saveAs,
  string,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { CellObject, WorkSheet } from 'xlsx-js-style'
import { useSitelineConfirmation } from '../components/SitelineConfirmation'
import {
  ChangeOrderRequestActivityDocument,
  ChangeOrderRequestEventProperties,
  ChangeOrderRequestForSigningQuery,
  ChangeOrderRequestInput,
  ChangeOrderRequestProperties,
  ChangeOrderRequestStatus,
  MinimalChangeOrderRequestProperties,
  NewChangeOrderRequestCommentDocument,
  NewChangeOrderRequestCommentSubscription,
  NewChangeOrderRequestEventDocument,
  UserProperties,
  useChangeOrderRequestActivityQuery,
  useRejectChangeOrderRequestMutation,
} from '../graphql/apollo-operations'
import {
  ImportTemplateDataType,
  ImportTemplateHeaderToColumnConfig,
  jsonToImportMetadata,
} from './ImportFromTemplate'

const importChangeOrderLog18nBase = 'projects.subcontractors.change_order_requests.import.error'

enum ChangeOrderLogHeader {
  DATE_CREATED = 'Date created',
  NAME = 'Name',
  INTERNAL_NUMBER = 'Internal number',
  GC_NUMBER = 'GC number',
  REASON = 'Reason',
  // This column does NOT appear in the import template, but is included in the export
  STATUS = 'Status',
  UNIT_NAME = 'Unit of measure',
  UNIT_PRICE = 'Unit price',
  UNIT_QUANTITY = 'Unit quantity',
  PROPOSED_AMOUNT = 'Proposed amount',
  GC_APPROVED_AMOUNT = 'GC approved amount',
  SCHEDULE_IMPACT = 'Schedule impact (days)',
  DATE_SUBMITTED = 'Date submitted',
  DATE_APPROVED = 'Date approved',
}

const UNIT_PRICE_HEADER_COLUMNS = [
  ChangeOrderLogHeader.UNIT_NAME,
  ChangeOrderLogHeader.UNIT_PRICE,
  ChangeOrderLogHeader.UNIT_QUANTITY,
]

type ChangeOrderRequestMetadata = Omit<ChangeOrderRequestInput, 'amount'> & {
  approvedAmount?: number
  unitQuantity?: number
}

type ChangeOrderLogImportHeader = Exclude<ChangeOrderLogHeader, ChangeOrderLogHeader.STATUS>

const headersToColumnConfig = (
  t: TFunction,
  timeZone: string
): ImportTemplateHeaderToColumnConfig<ChangeOrderLogImportHeader, ChangeOrderRequestMetadata> => ({
  [ChangeOrderLogHeader.DATE_CREATED]: {
    metadataKey: 'createdDate',
    isRequired: false,
    dataType: ImportTemplateDataType.DATE,
    defaultValue: moment.tz(timeZone),
  },
  [ChangeOrderLogHeader.NAME]: {
    metadataKey: 'name',
    isRequired: true,
    dataType: ImportTemplateDataType.STRING,
    validate: (name: string) => {
      if (name.length > CHANGE_ORDER_REQUEST_NAME_MAX_CHARS) {
        return t(`${importChangeOrderLog18nBase}.name_too_long`, {
          name,
          length: CHANGE_ORDER_REQUEST_NAME_MAX_CHARS,
        })
      }
      return null
    },
  },
  [ChangeOrderLogHeader.INTERNAL_NUMBER]: {
    metadataKey: 'internalNumber',
    isRequired: false,
    dataType: ImportTemplateDataType.STRING,
  },
  [ChangeOrderLogHeader.GC_NUMBER]: {
    metadataKey: 'generalContractorNumber',
    isRequired: false,
    dataType: ImportTemplateDataType.STRING,
  },
  [ChangeOrderLogHeader.REASON]: {
    metadataKey: 'reason',
    isRequired: false,
    dataType: ImportTemplateDataType.STRING,
  },
  [ChangeOrderLogHeader.UNIT_NAME]: {
    metadataKey: 'unitName',
    isRequired: false,
    dataType: ImportTemplateDataType.STRING,
  },
  [ChangeOrderLogHeader.UNIT_PRICE]: {
    metadataKey: 'unitPrice',
    isRequired: false,
    dataType: ImportTemplateDataType.DOLLAR,
  },
  [ChangeOrderLogHeader.UNIT_QUANTITY]: {
    metadataKey: 'unitQuantity',
    isRequired: false,
    dataType: ImportTemplateDataType.QUANTITY,
  },
  [ChangeOrderLogHeader.PROPOSED_AMOUNT]: {
    metadataKey: 'proposedAmount',
    isRequired: false,
    dataType: ImportTemplateDataType.DOLLAR,
  },
  [ChangeOrderLogHeader.GC_APPROVED_AMOUNT]: {
    metadataKey: 'approvedAmount',
    isRequired: false,
    dataType: ImportTemplateDataType.DOLLAR,
  },
  [ChangeOrderLogHeader.SCHEDULE_IMPACT]: {
    metadataKey: 'scheduleImpact',
    isRequired: false,
    dataType: ImportTemplateDataType.NUMBER,
  },
  [ChangeOrderLogHeader.DATE_SUBMITTED]: {
    metadataKey: 'submittedDate',
    isRequired: false,
    dataType: ImportTemplateDataType.DATE,
  },
  [ChangeOrderLogHeader.DATE_APPROVED]: {
    metadataKey: 'approvedDate',
    isRequired: false,
    dataType: ImportTemplateDataType.DATE,
  },
})

/** Converts a JSON object to a change order log */
export function jsonToChangeOrderLog(
  json: (string | number | undefined)[][],
  t: TFunction,
  timeZone: string
): ChangeOrderRequestInput[] {
  const metadata = jsonToImportMetadata({
    json,
    headersToColumnConfig: headersToColumnConfig(t, timeZone),
    t,
    timeZone,
  })
  return metadata.map((metadata) => {
    const { approvedAmount, proposedAmount, ...rest } = metadata
    return {
      ...rest,
      amount: approvedAmount ?? proposedAmount,
    }
  })
}

/** Statuses to filter by that don't directly correspond to a status in the database */
export enum ChangeOrderRequestPseudoStatus {
  ADDED_TO_SOV = 'ADDED_TO_SOV',
  PROCEEDING_WITH_WORK = 'PROCEEDING_WITH_WORK',
}

function formatChangeOrderSummaryValue(value: number): string {
  return formatCentsToDollars(value, true)
}

const markProceedingWithWork = (label: string) => {
  if (label === '') {
    return ''
  }
  return label + '***'
}

type LeadPM = Pick<UserProperties, 'firstName' | 'lastName'>

/** Returns a callback function for rejecting a change order request */
export function useRejectChangeOrderRequest() {
  const { t } = useTranslation()
  const { confirm } = useSitelineConfirmation()
  const snackbar = useSitelineSnackbar()
  const [rejectChangeOrderRequest] = useRejectChangeOrderRequestMutation()

  const i18nBase = 'projects.subcontractors.change_order_requests.table'

  return useCallback(
    async (changeOrderRequest: MinimalChangeOrderRequestProperties) => {
      const { linkedChangeOrderRequests } = changeOrderRequest
      const rejectCor = async () => {
        try {
          await rejectChangeOrderRequest({
            variables: {
              id: changeOrderRequest.id,
            },
            refetchQueries: [
              {
                query: ChangeOrderRequestActivityDocument,
                variables: {
                  changeOrderRequestId: changeOrderRequest.id,
                },
              },
            ],
            update(cache) {
              evictWithGc(cache, (evict) => {
                linkedChangeOrderRequests.forEach((cor) => {
                  evict({ id: cache.identify(cor), fieldName: 'linkedChangeOrderRequests' })
                })
              })
            },
          })
          snackbar.showSuccess(t(`${i18nBase}.success`))
        } catch (error) {
          snackbar.showError(error.message)
        }
      }
      if (linkedChangeOrderRequests.length) {
        confirm({
          title: t(`${i18nBase}.confirm_reject_title`),
          details: t(`${i18nBase}.confirm_reject_details`),
          maxWidth: 'sm',
          callback: (confirmed) => {
            if (confirmed) {
              rejectCor()
            }
          },
        })
      } else {
        rejectCor()
      }
    },
    [confirm, rejectChangeOrderRequest, snackbar, t]
  )
}

type ChangeOrderRequestWithAttachment = Pick<
  ChangeOrderRequestForSigningQuery['changeOrderRequest'],
  'attachments'
>

/**
 * Returns true if a change order request has viewable forms (either a template or backup that isn't
 * internal-only)
 */
export function canGenerateChangeOrderRequestForms(
  changeOrderRequest: ChangeOrderRequestWithAttachment &
    Pick<ChangeOrderRequestProperties, 'contract'>
) {
  const formTemplate = changeOrderRequest.contract.changeOrderRequestTemplate
  if (formTemplate && !formTemplate.isCustomerReady) {
    // If there is a form template but it isn't ready yet, don't allow generating forms
    // (even if there are attachments, since the user did specify a form to use)
    return false
  }
  const nonInternalAttachments = changeOrderRequest.attachments.filter(
    (attachment) => !attachment.isInternalOnly
  )
  return nonInternalAttachments.length > 0 || formTemplate !== null
}

/**
 * Returns the sum of amounts for outstanding change order requests (submitted only). Note: change
 * order requests whose status is authorized to proceed can be considered submitted.
 **/
export function totalRequestedAmount(
  changeOrderRequests: Pick<ChangeOrderRequestProperties, 'status' | 'amount'>[]
) {
  return _.sumBy(changeOrderRequests, ({ status, amount }) => {
    const changeOrderRequestAmount = amount ?? 0
    if (
      status === ChangeOrderRequestStatus.SUBMITTED ||
      status === ChangeOrderRequestStatus.AUTHORIZED_TO_PROCEED
    ) {
      return changeOrderRequestAmount
    }
    return 0
  })
}

/** Returns the sum of amounts for approved change order requests from among a group of CORs */
export function totalApprovedAmount(
  changeOrderRequests: Pick<ChangeOrderRequestProperties, 'status' | 'amount'>[]
) {
  return _.sumBy(changeOrderRequests, (changeOrderRequest) => {
    switch (changeOrderRequest.status) {
      case ChangeOrderRequestStatus.DRAFT:
      case ChangeOrderRequestStatus.SIGNED:
      case ChangeOrderRequestStatus.REJECTED:
      case ChangeOrderRequestStatus.SUBMITTED:
      case ChangeOrderRequestStatus.AUTHORIZED_TO_PROCEED:
        return 0
      case ChangeOrderRequestStatus.APPROVED:
        return changeOrderRequest.amount ?? 0
    }
  })
}

/** Returns the sum of amounts for approved change order requests from among a group of CORs */
export function totalAuthorizedToProceed(
  changeOrderRequests: Pick<ChangeOrderRequestProperties, 'status' | 'amount'>[]
) {
  return _.sumBy(changeOrderRequests, (changeOrderRequest) => {
    if (changeOrderRequest.status === ChangeOrderRequestStatus.AUTHORIZED_TO_PROCEED) {
      return changeOrderRequest.amount ?? 0
    }
    return 0
  })
}

/** Returns the sum of amounts for change order requests whose status is proceeding with work */
export function totalProceedingWithWork(
  changeOrderRequests: Pick<ChangeOrderRequestProperties, 'amount' | 'proceedingWithWork'>[]
) {
  return _.sumBy(changeOrderRequests, (changeOrderRequest) => {
    if (changeOrderRequest.proceedingWithWork) {
      return changeOrderRequest.amount ?? 0
    } else {
      return 0
    }
  })
}

/** All status options to show in the COR filter, in the order they should be shown */
export const CHANGE_ORDER_REQUEST_STATUS_OPTIONS = [
  ChangeOrderRequestPseudoStatus.PROCEEDING_WITH_WORK,
  ChangeOrderRequestStatus.DRAFT,
  ChangeOrderRequestStatus.SIGNED,
  ChangeOrderRequestStatus.SUBMITTED,
  ChangeOrderRequestStatus.AUTHORIZED_TO_PROCEED,
  ChangeOrderRequestStatus.REJECTED,
  ChangeOrderRequestStatus.APPROVED,
  ChangeOrderRequestPseudoStatus.ADDED_TO_SOV,
]

/** Includes any status from submitted onward */
export const COR_SUBMITTED_STATUSES = [
  ChangeOrderRequestStatus.APPROVED,
  ChangeOrderRequestStatus.REJECTED,
  ChangeOrderRequestStatus.AUTHORIZED_TO_PROCEED,
  ChangeOrderRequestStatus.SUBMITTED,
]

function formatDateForExcelExport({
  date,
  shouldAddTimeStamp,
  timeZone,
}: {
  date?: string
  shouldAddTimeStamp: boolean
  timeZone: string
}) {
  const dateMoment = date ? moment.tz(date, timeZone) : moment.tz(timeZone)
  const format = shouldAddTimeStamp ? 'MM/DD/YY [at] h:mma z' : 'MM/DD/YY'
  return dateMoment.format(format)
}

interface ChangeOrderLogToXlsxArgs {
  changeOrderRequests: MinimalChangeOrderRequestProperties[]
  timeZone: string
  t: TFunction
  leadPMs: LeadPM[]
  projectName: string
  projectNumber: string
  gcName: string
  totalAmountRequested: number
  totalAmountApproved: number
  totalAmountProceedingWithWork: number
  totalAmountAuthorizedToProceed: number
  originalContractAmount: number
  contractSumAmount: number
  isLogVisibleToGc: boolean
  billingType: BillingType
}

/**
 * Exports change order log data to an excel worksheet that can be rendered to XLSX or CSV.
 */
async function changeOrderLogDataToWorksheet({
  changeOrderRequests,
  timeZone,
  t,
  leadPMs,
  projectName,
  projectNumber,
  gcName,
  totalAmountRequested,
  totalAmountApproved,
  totalAmountProceedingWithWork,
  totalAmountAuthorizedToProceed,
  originalContractAmount,
  contractSumAmount,
  isLogVisibleToGc,
  billingType,
}: ChangeOrderLogToXlsxArgs): Promise<WorkSheet> {
  const i18nBase = 'projects.subcontractors.change_order_requests.table.excel_export'
  const shouldIncludeUnitPriceColumns = billingType === BillingType.UNIT_PRICE

  const rows: CellObject[][] = []

  // Header
  const formattedGenerationDate = formatDateForExcelExport({ timeZone, shouldAddTimeStamp: true })

  rows.push([bold(string(t(`${i18nBase}.title`)))])
  rows.push([string(t(`${i18nBase}.generated`, { date: formattedGenerationDate }))])
  rows.push([])

  // Project info
  const leadPMsFormatted = leadPMs.map((pm) => `${pm.firstName} ${pm.lastName}`)
  const leadPMsString = leadPMsFormatted.join(', ')
  const leadPMCount = leadPMs.length

  rows.push([bold(string(t(`${i18nBase}.project_name`))), leftAlign(string(projectName))])
  rows.push([bold(string(t(`${i18nBase}.project_number`))), leftAlign(string(projectNumber))])
  rows.push([bold(string(t(`${i18nBase}.gc`))), leftAlign(string(gcName))])
  rows.push([
    bold(string(t(`${i18nBase}.lead_pms`, { count: leadPMCount }))),
    leftAlign(string(leadPMsString)),
  ])
  rows.push([])

  // Change order stats
  rows.push([
    bold(string(t(`${i18nBase}.total_requested`))),
    leftAlign(string(formatChangeOrderSummaryValue(totalAmountRequested))),
  ])
  rows.push([
    bold(string(t(`${i18nBase}.total_approved`))),
    leftAlign(string(formatChangeOrderSummaryValue(totalAmountApproved))),
  ])
  rows.push([
    bold(string(t(`${i18nBase}.original_contract`))),
    leftAlign(string(formatChangeOrderSummaryValue(originalContractAmount))),
  ])
  rows.push([
    bold(string(t(`${i18nBase}.contract_sum_to_date`))),
    leftAlign(string(formatChangeOrderSummaryValue(contractSumAmount))),
  ])
  rows.push([
    bold(string(t(`${i18nBase}.authorized_to_proceed`))),
    leftAlign(string(formatChangeOrderSummaryValue(totalAmountAuthorizedToProceed))),
  ])
  rows.push([
    bold(string(t(`${i18nBase}.proceeding_with_work`))),
    leftAlign(string(formatChangeOrderSummaryValue(totalAmountProceedingWithWork))),
  ])
  rows.push([])

  // Change order log table
  const filteredColumns = shouldIncludeUnitPriceColumns
    ? Object.values(ChangeOrderLogHeader)
    : Object.values(ChangeOrderLogHeader).filter(
        (headerValue) => !UNIT_PRICE_HEADER_COLUMNS.includes(headerValue)
      )
  const tableHeaders = filteredColumns.map((value) => bold(string(value)))

  const sortedChangeOrderRequests = sortByInternalNumber(changeOrderRequests)
  const tableRows: CellObject[][] = _.chain(sortedChangeOrderRequests)
    .map(
      ({
        proposedAt,
        status,
        statusChangedAt,
        proposedAmount,
        amount,
        sovLineItems,
        name,
        internalNumber,
        generalContractorNumber,
        createdAt,
        visibleToGc,
        proceedingWithWork,
        reason,
        scheduleImpact,
        unitName,
        unitPrice,
      }) => {
        // Exclude from log if the log is being shared with the GC and the COR is internal only (!visibleToGc)
        if (isLogVisibleToGc && !visibleToGc) {
          return null
        }

        const isApproved = status === ChangeOrderRequestStatus.APPROVED
        const isAddedToSov = isApproved && sovLineItems.length > 0

        const row: CellObject[] = []
        for (const header of Object.values(ChangeOrderLogHeader)) {
          switch (header) {
            case ChangeOrderLogHeader.DATE_CREATED: {
              const formattedCreatedAt = formatDateForExcelExport({
                date: createdAt,
                timeZone,
                shouldAddTimeStamp: false,
              })
              row.push(string(formattedCreatedAt))
              break
            }
            case ChangeOrderLogHeader.NAME: {
              const changeOrderName = proceedingWithWork ? markProceedingWithWork(name) : name
              row.push(string(changeOrderName))
              break
            }
            case ChangeOrderLogHeader.INTERNAL_NUMBER: {
              const numberString = internalNumber ?? ''
              const changeOrderNumber = proceedingWithWork
                ? markProceedingWithWork(numberString)
                : numberString
              row.push(string(changeOrderNumber))
              break
            }
            case ChangeOrderLogHeader.GC_NUMBER: {
              const gcNumberString = generalContractorNumber ?? ''
              const gcNumber = proceedingWithWork
                ? markProceedingWithWork(gcNumberString)
                : gcNumberString
              row.push(string(gcNumber))
              break
            }
            case ChangeOrderLogHeader.REASON:
              row.push(string(reason ?? ''))
              break
            case ChangeOrderLogHeader.STATUS: {
              const pseudoStatus = isAddedToSov
                ? ChangeOrderRequestPseudoStatus.ADDED_TO_SOV
                : status
              const statusString = t(
                `projects.subcontractors.change_order_requests.status.${pseudoStatus}`
              )
              const changeOrderStatus = proceedingWithWork
                ? markProceedingWithWork(statusString)
                : statusString
              row.push(string(changeOrderStatus))
              break
            }
            case ChangeOrderLogHeader.UNIT_NAME: {
              if (shouldIncludeUnitPriceColumns) {
                row.push(string(unitName ?? ''))
              }
              break
            }
            case ChangeOrderLogHeader.UNIT_PRICE: {
              if (shouldIncludeUnitPriceColumns) {
                const formattedUnitPrice = formatCentsToDollars(unitPrice ?? 0, true)
                row.push(string(formattedUnitPrice))
              }
              break
            }
            case ChangeOrderLogHeader.UNIT_QUANTITY: {
              if (shouldIncludeUnitPriceColumns) {
                const unitQuantity =
                  amount !== null && unitPrice !== null ? safeDivide(amount, unitPrice, 0) : 0
                row.push(number(unitQuantity))
              }
              break
            }
            case ChangeOrderLogHeader.PROPOSED_AMOUNT: {
              const proposedAmountInCents = proposedAmount ?? amount ?? 0
              const formattedProposedAmount = formatCentsToDollars(proposedAmountInCents, true)
              row.push(string(formattedProposedAmount))
              break
            }
            case ChangeOrderLogHeader.GC_APPROVED_AMOUNT: {
              const approvedAmountInCents = amount ?? 0
              const formattedApprovedAmount = isApproved
                ? formatCentsToDollars(approvedAmountInCents, true)
                : ''
              row.push(string(formattedApprovedAmount))
              break
            }
            case ChangeOrderLogHeader.SCHEDULE_IMPACT: {
              const scheduleImpactString = scheduleImpact ? String(scheduleImpact) : ''
              row.push(string(scheduleImpactString))
              break
            }
            case ChangeOrderLogHeader.DATE_SUBMITTED: {
              const submittedAt = proposedAt
                ? formatDateForExcelExport({
                    date: proposedAt,
                    timeZone,
                    shouldAddTimeStamp: false,
                  })
                : ''
              row.push(string(submittedAt))
              break
            }
            case ChangeOrderLogHeader.DATE_APPROVED: {
              const approvedAt = isApproved
                ? formatDateForExcelExport({
                    date: statusChangedAt,
                    timeZone,
                    shouldAddTimeStamp: false,
                  })
                : ''
              row.push(string(approvedAt))
              break
            }
          }
        }
        return row
      }
    )
    .compact()
    .value()

  rows.push(tableHeaders)
  rows.push(...tableRows)

  const { utils } = await import('xlsx-js-style')
  const sheet = utils.json_to_sheet(rows, { skipHeader: true })
  const autoFitRows = [tableHeaders, ...tableRows]
  sheet['!cols'] = getColumnSizesFromRows(autoFitRows)
  return sheet
}

export async function exportChangeOrderLogDataToXlsx(
  args: ChangeOrderLogToXlsxArgs
): Promise<void> {
  const i18nBase = 'projects.subcontractors.change_order_requests.table.excel_export'
  const { utils, write } = await import('xlsx-js-style')
  const sheet = await changeOrderLogDataToWorksheet(args)
  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, sheet, 'Change Order Log')
  const out = write(workbook, { type: 'array' })
  const blob = new Blob([out], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  })
  saveAs(blob, args.t(`${i18nBase}.excel_filename`, { projectName: args.projectName }))
}

/** Used for filtering change order request activities that have comments attached */
export function doesChangeOrderRequestEventHaveInternalComment(
  event: ChangeOrderRequestEventProperties
) {
  const typedEvent = event as TypedChangeOrderRequestEvent<ChangeOrderRequestEventProperties>
  // Use a temp variable with an intialized state so any new event types added in the future won't
  // cause this util to error
  let hasInternalComment = false
  switch (typedEvent.type) {
    case ChangeOrderRequestEventType.REQUESTED_REVIEW:
      hasInternalComment = typedEvent.metadata.message.length > 0
      break
    case ChangeOrderRequestEventType.REVERTED_TO_DRAFT:
      hasInternalComment = !!typedEvent.metadata.reason && typedEvent.metadata.reason.length > 0
      break
    case ChangeOrderRequestEventType.ADDED_BACKUP:
    case ChangeOrderRequestEventType.CHANGE_ORDER_ADDED_TO_SOV:
    case ChangeOrderRequestEventType.CHANGE_ORDER_APPROVED:
    case ChangeOrderRequestEventType.CHANGE_ORDER_AUTHORIZED_TO_PROCEED:
    case ChangeOrderRequestEventType.CHANGE_ORDER_CREATED:
    case ChangeOrderRequestEventType.CHANGE_ORDER_IMPORTED:
    case ChangeOrderRequestEventType.CHANGE_ORDER_REJECTED:
    case ChangeOrderRequestEventType.CHANGE_ORDER_SIGNED:
    case ChangeOrderRequestEventType.CHANGE_ORDER_SUBMITTED:
    case ChangeOrderRequestEventType.EDITED_AMOUNT:
    case ChangeOrderRequestEventType.EDITED_GENERAL_CONTRACTOR_NUMBER:
    case ChangeOrderRequestEventType.EDITED_INTERNAL_NOTES:
    case ChangeOrderRequestEventType.EDITED_INTERNAL_NUMBER:
    case ChangeOrderRequestEventType.EDITED_NAME:
    case ChangeOrderRequestEventType.EDITED_REASON:
    case ChangeOrderRequestEventType.EDITED_SCHEDULE_IMPACT:
    case ChangeOrderRequestEventType.GROUPED_ADDED_BACKUP:
    case ChangeOrderRequestEventType.GROUPED_EDITED_AMOUNT:
    case ChangeOrderRequestEventType.GROUPED_EDITED_GENERAL_CONTRACTOR_NUMBER:
    case ChangeOrderRequestEventType.GROUPED_EDITED_INTERNAL_NOTES:
    case ChangeOrderRequestEventType.GROUPED_EDITED_INTERNAL_NUMBER:
    case ChangeOrderRequestEventType.GROUPED_EDITED_NAME:
    case ChangeOrderRequestEventType.GROUPED_EDITED_REASON:
    case ChangeOrderRequestEventType.GROUPED_EDITED_SCHEDULE_IMPACT:
    case ChangeOrderRequestEventType.LINKED_TO_CHANGE_ORDER_REQUESTS:
    case ChangeOrderRequestEventType.REMOVED_BACKUP:
    case ChangeOrderRequestEventType.SET_INVISIBLE_TO_GC:
    case ChangeOrderRequestEventType.SET_VISIBLE_TO_GC:
    case ChangeOrderRequestEventType.TOGGLED_PROCEEDING_WITH_WORK:
    case ChangeOrderRequestEventType.UNLINKED_FROM_CHANGE_ORDER_REQUESTS:
    case ChangeOrderRequestEventType.UPLOADED_SCO_FILE:
      hasInternalComment = false
      break
  }
  return hasInternalComment
}

/** Handles querying and subscribing to change order request activities. Used on the change order request details page */
export function useChangeOrderRequestActivitiesSubscription(changeOrderRequestId: string) {
  const { data, loading, subscribeToMore } = useChangeOrderRequestActivityQuery({
    variables: { changeOrderRequestId },
  })

  const subscribeToChangeOrderRequestActivities = useCallback(() => {
    subscribeToMore({
      document: NewChangeOrderRequestEventDocument,
      variables: { changeOrderRequestId },
    })

    subscribeToMore({
      document: NewChangeOrderRequestCommentDocument,
      variables: { changeOrderRequestId },
      updateQuery: (prev, { subscriptionData }) => {
        const newData = subscriptionData.data as unknown as
          | NewChangeOrderRequestCommentSubscription
          | undefined
        if (!newData) {
          return prev
        }

        const newComment = newData.newChangeOrderRequestComment

        return {
          ...prev,
          changeOrderRequest: {
            ...prev.changeOrderRequest,
            comments: _.uniqBy(
              [newComment, ...prev.changeOrderRequest.comments],
              (comment) => comment.id
            ),
          },
        }
      },
    })
  }, [changeOrderRequestId, subscribeToMore])

  // Subscribe to change order request activities on mount
  // Note: we only want to subscribe to a change order request's activities a single time. Pay attention
  // to the dependecies of subscribeToChangeOrderRequestActivities, as this will affect how often this
  // useEffect gets triggered
  useEffect(() => {
    subscribeToChangeOrderRequestActivities()
  }, [subscribeToChangeOrderRequestActivities])

  return { data, loading }
}
