import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LaunchIcon from '@mui/icons-material/Launch'
import { Button, ButtonProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useCallback } from 'react'
import { integrationTypes } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'
import { getSyncActionButtonMetrics } from '../../../common/util/Integration'
import { trackActionButtonClickedAfterIntegrationSync } from '../../../common/util/MetricsTracking'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 2, 0, 2),
    '&.MuiDialogContent-root': {
      padding: theme.spacing(5, 7, 0, 7),
    },
    '& .image': {
      display: 'flex',
      margin: theme.spacing(0, 'auto', 2, 'auto'),
    },
    '& .textSubtitle': {
      paddingTop: theme.spacing(3),
    },
    '& .description': {
      padding: theme.spacing(2, 3),
      whiteSpace: 'pre-wrap',
    },
    '& .errors': {
      padding: theme.spacing(2, 0, 4, 0),
    },
    '& .error': {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: theme.spacing(2),
      '& .errorSubtitle': {
        paddingBottom: theme.spacing(0.5),
      },
    },
    '& .errorIcon': {
      color: colors.red50,
      marginRight: theme.spacing(1),
    },
    '& .actions': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 0, 0),
      '& >:not(:first-of-type)': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}))

type WriteSyncResultScreenError = {
  subTitle: string
  description: string
}

export type WriteSyncResultScreenAction = {
  title: string
  onClick: () => void

  // If true, the action opens a link in a new tab
  opensLinkInNewTab?: boolean

  // Defaults to contained
  variant?: ButtonProps['variant']

  // Defaults to primary
  color?: ButtonProps['color']
}

export type WriteSyncResultScreenText = {
  actions: WriteSyncResultScreenAction[]
  title: string
  error?: WriteSyncResultScreenError
  description?: string
  extra?: JSX.Element
  icon?: JSX.Element
  subTitle?: string
  closeLabel: string
}

interface WriteSyncResultScreenProps {
  integration: MinimalIntegrationProperties
  projectId: string
  payload: integrationTypes.WriteSyncPayload
  text: WriteSyncResultScreenText
  onClose: () => void
}

/** Screen shown when a sync request comes back from the server (success or fail). */
export function WriteSyncResultScreen({
  integration,
  projectId,
  payload,
  text,
  onClose,
}: WriteSyncResultScreenProps) {
  const classes = useStyles()
  const integrationName = integration.shortName

  const onClick = useCallback(
    (action: WriteSyncResultScreenAction) => {
      action.onClick()
      trackActionButtonClickedAfterIntegrationSync(
        getSyncActionButtonMetrics({
          projectId,
          integrationName,
          buttonAction: action.title,
          payload,
        })
      )
    },
    [integrationName, payload, projectId]
  )

  const closeButtonLevel = text.actions.length > 0 ? 'secondary' : 'primary'

  return (
    <div className={classes.root}>
      {text.icon && text.icon}
      <SitelineText variant="h1" bold align="center">
        {text.title}
      </SitelineText>
      {text.subTitle && (
        <SitelineText variant="h3" align="center" bold className="textSubtitle">
          {text.subTitle}
        </SitelineText>
      )}
      {text.description && (
        <SitelineText variant="body1" color="grey50" align="center" className="description">
          {text.description}
        </SitelineText>
      )}
      {text.extra}
      {text.error && (
        <div className="errors">
          <div className="error">
            <ErrorOutlineIcon className="errorIcon" />
            <div>
              <SitelineText variant="h3" className="errorSubtitle" bold>
                {text.error.subTitle}
              </SitelineText>
              <SitelineText variant="secondary" color="grey90">
                {text.error.description}
              </SitelineText>
            </div>
          </div>
        </div>
      )}
      <div className="actions">
        <Button
          onClick={onClose}
          variant={closeButtonLevel === 'primary' ? 'contained' : 'outlined'}
          color={closeButtonLevel}
        >
          {text.closeLabel}
        </Button>
        {text.actions.map((action) => (
          <Button
            key={action.title}
            onClick={() => onClick(action)}
            variant={action.variant ?? 'contained'}
            color={action.color ?? 'primary'}
            startIcon={action.opensLinkInNewTab ? <LaunchIcon /> : undefined}
          >
            {action.title}
          </Button>
        ))}
      </div>
    </div>
  )
}
