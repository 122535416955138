import { Autocomplete, Button, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyIntegrationMetadataSageIntacct, makeNumericCollator } from 'siteline-common-all'
import {
  colors,
  IntegrationGeneralLedgerAccount,
  IntegrationType,
  makeStylesFast,
  SitelineText,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { SitelineAlert } from '../../common/components/SitelineAlert'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import {
  useGetIntegrationGeneralLedgerAccountsQuery,
  useUpdateCompanyIntegrationMetadataMutation,
} from '../../common/graphql/apollo-operations'
import { findCompanyIntegrationOfType } from '../../common/util/Integration'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .flex': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .editButton': {
      color: colors.grey50,
      margin: theme.spacing(-1, -1, 0, 0),
    },
    '& .saveButton': {
      margin: theme.spacing(-1, 0, 0, 2),
    },
    '& .heading': {
      alignItems: 'center',
      '& .Siteline-h4': {
        margin: theme.spacing(1, 0),
      },
    },
    '& .subtitle': {
      display: 'block',
      margin: theme.spacing(1, 0),
      whiteSpace: 'pre-wrap',
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 0),
      '&.editing': {
        padding: theme.spacing(0.5, 0),
      },
      '& .label': {
        minWidth: 300,
        marginRight: theme.spacing(3),
      },
      '& .input': {
        width: 300,
      },
      '& .tooltipIcon': {
        fontSize: 16,
      },
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
  },
  bold: {
    fontWeight: 600,
  },
}))

const i18nBase = 'settings.sage_intacct'
const collator = makeNumericCollator()

// The code for sage intacct ledger accounts needs to come first because
// Sage Intacct ledger accounts are selected by numeric range
function getSageIntacctLedgerAccountLabel(option: IntegrationGeneralLedgerAccount): string {
  return `${option.code} · ${option.name}`
}

/** Company-wide integration settings, currently for Sage Intacct only */
export function SageIntacctCompanySettings() {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { company } = useCompanyContext()
  const [updateCompanyIntegrationMetadata] = useUpdateCompanyIntegrationMetadataMutation()
  const companyIntegration = useMemo(() => {
    return findCompanyIntegrationOfType(company, IntegrationType.SAGE_INTACCT)
  }, [company])
  const initialMetadata: CompanyIntegrationMetadataSageIntacct = useMemo(() => {
    if (!companyIntegration) {
      // This code should not be reachable, since this section is only mounted if a Sage Intacct
      // company integration exists
      throw new Error('No Sage Intacct company integration found')
    }
    const mappings = companyIntegration.metadata as
      | CompanyIntegrationMetadataSageIntacct
      | undefined
    return mappings ?? {}
  }, [companyIntegration])
  const [metadata, setMetadata] = useState<CompanyIntegrationMetadataSageIntacct>(initialMetadata)

  const { data: accountsData, loading: loadingAccounts } =
    useGetIntegrationGeneralLedgerAccountsQuery({
      variables: { companyIntegrationId: companyIntegration?.id ?? '' },
      skip: companyIntegration === undefined || !isEditing,
    })
  const ledgerAccounts = useMemo(() => {
    const accounts = accountsData?.integrationGeneralLedgerAccounts ?? []
    const sorted = [...accounts].sort((a, b) => collator.compare(a.code, b.code))
    return sorted
  }, [accountsData])

  const startAccount = useMemo(() => {
    return (
      ledgerAccounts.find(
        (account) =>
          account.integrationAccountId ===
          metadata.costLedgerAccountRangeStart?.agaveLedgerAccountId
      ) ?? null
    )
  }, [ledgerAccounts, metadata.costLedgerAccountRangeStart?.agaveLedgerAccountId])

  const endAccount = useMemo(() => {
    return (
      ledgerAccounts.find(
        (account) =>
          account.integrationAccountId === metadata.costLedgerAccountRangeEnd?.agaveLedgerAccountId
      ) ?? null
    )
  }, [ledgerAccounts, metadata.costLedgerAccountRangeEnd?.agaveLedgerAccountId])

  const isInvalidRange = useMemo(() => {
    if (!startAccount || !endAccount) {
      return false
    }
    const startIndex = ledgerAccounts.findIndex(
      (account) => account.integrationAccountId === startAccount.integrationAccountId
    )
    const endIndex = ledgerAccounts.findIndex(
      (account) => account.integrationAccountId === endAccount.integrationAccountId
    )
    return startIndex > endIndex
  }, [ledgerAccounts, startAccount, endAccount])

  const onEditOrCancel = useCallback(() => {
    // If we were editing already and we cancel, then we should reset the state back to default
    if (isEditing) {
      setMetadata(initialMetadata)
    }
    setIsEditing(!isEditing)
  }, [initialMetadata, isEditing])

  const handleSave = useCallback(async () => {
    if (!companyIntegration) {
      // Should not be possible, as this card would not be rendered if there was no existing
      // Sage Intacct company integration
      return
    }
    try {
      await updateCompanyIntegrationMetadata({
        variables: { input: { companyIntegrationId: companyIntegration.id, metadata } },
      })
      setIsEditing(false)
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [metadata, companyIntegration, snackbar, updateCompanyIntegrationMetadata])

  const disableSave = useMemo(
    () =>
      !metadata.costLedgerAccountRangeStart ||
      !metadata.costLedgerAccountRangeEnd ||
      loadingAccounts ||
      isInvalidRange,
    [
      isInvalidRange,
      loadingAccounts,
      metadata.costLedgerAccountRangeEnd,
      metadata.costLedgerAccountRangeStart,
    ]
  )

  return (
    <div className={classes.root}>
      <div className="heading flex">
        <SitelineText variant="h3" bold color="grey90">
          {t('settings.navigation.sageIntacct')}
        </SitelineText>
        <div>
          <Button variant="text" color="secondary" onClick={onEditOrCancel} className="editButton">
            {isEditing ? t('common.actions.cancel') : t('common.actions.edit')}
          </Button>
          {isEditing && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={disableSave}
              className="saveButton"
            >
              {t('common.actions.save')}
            </Button>
          )}
        </div>
      </div>
      <SitelineText variant="body2" color="grey50" className="subtitle">
        {t(`${i18nBase}.description`)}
      </SitelineText>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText variant="body1" color="grey50" className="label">
          {t(`${i18nBase}.cost_account_start`)}
        </SitelineText>
        {isEditing ? (
          <Autocomplete
            loading={loadingAccounts}
            value={startAccount ?? undefined}
            disableClearable={false}
            fullWidth
            freeSolo={false}
            options={ledgerAccounts}
            autoHighlight
            openOnFocus
            onChange={(ev, option) =>
              setMetadata({
                ...metadata,
                costLedgerAccountRangeStart: option
                  ? {
                      agaveLedgerAccountId: option.integrationAccountId,
                      sageLedgerAccountName: option.name,
                      sageLedgerAccountCode: option.code,
                    }
                  : undefined,
              })
            }
            renderInput={(params) => {
              return (
                <TextField
                  variant="outlined"
                  placeholder={t(`${i18nBase}.search_accounts`)}
                  {...params}
                />
              )
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.integrationAccountId}>
                <SitelineText variant="secondary">
                  {getSageIntacctLedgerAccountLabel(option)}
                </SitelineText>
              </li>
            )}
            getOptionLabel={getSageIntacctLedgerAccountLabel}
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.costLedgerAccountRangeStart ? 'grey90' : 'grey50'}
          >
            {metadata.costLedgerAccountRangeStart
              ? `${metadata.costLedgerAccountRangeStart.sageLedgerAccountCode} · ${metadata.costLedgerAccountRangeStart.sageLedgerAccountName}`
              : '–'}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText variant="body1" color="grey50" className="label">
          {t(`${i18nBase}.cost_account_end`)}
        </SitelineText>
        {isEditing ? (
          <Autocomplete
            loading={loadingAccounts}
            value={endAccount ?? undefined}
            disableClearable={false}
            fullWidth
            freeSolo={false}
            options={ledgerAccounts}
            autoHighlight
            openOnFocus
            onChange={(ev, option) =>
              setMetadata({
                ...metadata,
                costLedgerAccountRangeEnd: option
                  ? {
                      agaveLedgerAccountId: option.integrationAccountId,
                      sageLedgerAccountName: option.name,
                      sageLedgerAccountCode: option.code,
                    }
                  : undefined,
              })
            }
            renderInput={(params) => {
              return (
                <TextField
                  variant="outlined"
                  placeholder={t(`${i18nBase}.search_accounts`)}
                  {...params}
                />
              )
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.integrationAccountId}>
                <SitelineText variant="secondary">
                  {getSageIntacctLedgerAccountLabel(option)}
                </SitelineText>
              </li>
            )}
            getOptionLabel={getSageIntacctLedgerAccountLabel}
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.costLedgerAccountRangeEnd ? 'grey90' : 'grey50'}
          >
            {metadata.costLedgerAccountRangeEnd
              ? `${metadata.costLedgerAccountRangeEnd.sageLedgerAccountCode} · ${metadata.costLedgerAccountRangeEnd.sageLedgerAccountName}`
              : '–'}
          </SitelineText>
        )}
      </div>
      {isInvalidRange && (
        <SitelineAlert severity="error">{t(`${i18nBase}.invalid_range`)}</SitelineAlert>
      )}
    </div>
  )
}
