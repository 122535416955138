import moment from 'moment-timezone'

// There are 5 days in a week, so 3 means a majority of the weekdays
export const NUM_MAJORITY_WEEKDAYS = 3
// Assume that the 15th is the middle of every month
export const MIDDLE_OF_MONTH = 15

/**
 * In certain scenarios, we don't need the timezone accounted for when getting the date.
 * NOTE: be cautious when using this to prevent off by 1 date errors (i.e. #1420)
 */
export function getTimeNoTimeZone(date?: string): moment.Moment {
  // eslint-disable-next-line momentjs/no-moment-constructor
  return moment(date)
}

/**
 * Counts the number of weekdays (Mon-Fri) between a given start and end date inclusively.
 * i.e. Given a Mon and Thurs, return 4. Given a Sun and Thurs, return 4. Given a Fri and Sat,
 * return 1. Given a Sat and Sun, return 0
 */
export function weekdaysBetween(startDate: moment.Moment, endDate: moment.Moment): number {
  let start = startDate.clone()
  let weekdayCounter = 0

  while (!start.isAfter(endDate, 'date')) {
    const day = start.day()
    // Day 0 is Sunday and day 6 is Saturday
    if (day !== 0 && day !== 6) {
      weekdayCounter += 1
    }
    start = start.add(1, 'day')
  }
  return weekdayCounter
}

/**
 * A week is contained in a month if at least 3 of the weekdays are in that month. The
 * start and end days of the week are determined by the startDay. If the startDay is
 * a Mon, then the week is Mon-Sun. If the startDay is a Thurs, then the week is Thurs-Wed.
 *
 * @param startDay The day to start each week on from 0 (sunday) to 6 (saturday)
 * @param month Which month to check
 */
export function getFirstDaysOfWeekInMonth(startDay: number, month: moment.Moment): moment.Moment[] {
  const firstDays: moment.Moment[] = []
  const startOfMonth = month.clone().startOf('month')
  const endOfMonth = month.clone().endOf('month')

  let startOfWeek = startOfMonth.clone().day(startDay)
  let endOfWeek = startOfWeek.clone().add(6, 'days')
  // If the week starts on an earlier day than the month, this means the week starts in the prior
  // month. i.e. if the 1st of the month is a Thursday but the start of the week is on Tuesdays
  // then the startOfWeek variable will be the last Tuesday of the previous month. Therefore, we
  // first will check if that overlapping week is part of the month.
  if (startDay < startOfMonth.day()) {
    const numWeekdays = weekdaysBetween(startOfMonth, endOfWeek)
    if (numWeekdays >= NUM_MAJORITY_WEEKDAYS) {
      firstDays.push(startOfWeek.clone())
    }
    startOfWeek = startOfWeek.add(7, 'days')
    endOfWeek = endOfWeek.add(7, 'days')
  }

  // If the week starts on a later day than the month, this means the week will start after a few
  // days of the month have passed. i.e. if the 1st of the month is a Monday but the start of the
  // week is on Wednesdays, then the startOfWeek will ignore the first monday/tuesday. Therefore,
  // we first will check the start of the month to the day before the first week.
  if (startDay > startOfMonth.day()) {
    const previousWeekEnd = startOfWeek.clone().subtract(1, 'day')
    const numWeekdays = weekdaysBetween(startOfMonth, previousWeekEnd)
    if (numWeekdays >= NUM_MAJORITY_WEEKDAYS) {
      firstDays.push(startOfWeek.clone().subtract(7, 'days'))
    }
  }

  // Continue looping to get all the weeks for this month
  while (startOfWeek.isSameOrBefore(endOfMonth, 'day')) {
    const numWeekdays = weekdaysBetween(startOfWeek, endOfWeek)
    if (numWeekdays >= NUM_MAJORITY_WEEKDAYS) {
      firstDays.push(startOfWeek.clone())
    }
    startOfWeek = startOfWeek.add(7, 'days')
    endOfWeek = endOfWeek.add(7, 'days')
    if (endOfWeek.isAfter(endOfMonth)) {
      // If this is the last week of the month, check if there are at least 3 weekdays between the
      // start of the week and the end of the month.
      endOfWeek = endOfMonth.clone()
    }
  }

  return firstDays
}
