import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Contract,
  evictWithGc,
  makeStylesFast,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import {
  BillingType,
  Permission,
  RateTableProperties,
  TaxCalculationType,
  useCreateContractRateTableTaxGroupMutation,
  useDeleteContractRateTableTaxGroupMutation,
  useGetCompanyForTaxGroupsQuery,
  useUpdateContractRateTableTaxGroupMutation,
  useUpdateRateTableTaxCalculationTypeMutation,
} from '../../../common/graphql/apollo-operations'
import { ContractForProjectHome } from '../home/ProjectHome'
import { FeeTables } from '../onboarding/FeeTables'
import { SettingsHeader } from './SettingsHeader'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
    '& .feeTableWrapper': {
      marginTop: theme.spacing(-2),
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings'

interface FeesProps {
  contract: ContractForProjectHome
  rateTable: RateTableProperties
}

/**
 * Fees & taxes section in billing settings that allows you to view & edit fees for t&m projects
 * or non-t&m change order requests that use a pricing table.
 */
export function Fees({ contract, rateTable }: FeesProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()
  const { permissions, companyId } = useCompanyContext()

  const { billingType, rateTableTaxCalculationType } = contract
  const isTimeAndMaterials = billingType === BillingType.TIME_AND_MATERIALS
  const usesTaxes = rateTableTaxCalculationType === TaxCalculationType.MULTIPLE_TAX_GROUPS

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { data } = useGetCompanyForTaxGroupsQuery({ variables: { id: companyId } })
  const [updateRateTableTaxCalculation, { client }] = useUpdateRateTableTaxCalculationTypeMutation()
  const [updateContractRateTableTaxGroup] = useUpdateContractRateTableTaxGroupMutation()
  const [deleteContractRateTableTaxGroup] = useDeleteContractRateTableTaxGroupMutation()
  const [createContractRateTableTaxGroup] = useCreateContractRateTableTaxGroupMutation()

  const canEdit = useMemo(
    () => permissions.includes(Permission.EDIT_PROJECT_SETTINGS),
    [permissions]
  )

  const { taxGroups, rateTableTaxGroups, defaultTaxGroup } = useMemo(() => {
    const companyTaxGroups = data
      ? _.orderBy([...data.company.taxGroups], (group) => group.name)
      : []
    const firstCompanyTaxGroup =
      _.first(companyTaxGroups.filter((taxGroup) => !taxGroup.archivedAt)) ?? null

    return {
      taxGroups: companyTaxGroups,
      rateTableTaxGroups: [...contract.rateTableTaxGroups],
      defaultTaxGroup: contract.rateTableDefaultTaxGroup ?? firstCompanyTaxGroup,
    }
  }, [contract.rateTableDefaultTaxGroup, contract.rateTableTaxGroups, data])

  const handleUpdateCache = useCallback(() => {
    const { cache } = client
    evictWithGc(cache, (evict) => {
      contract.payApps.forEach((payApp) => {
        evict({ id: cache.identify(payApp), fieldName: 'haveContractTaxesChanged' })
      })
      contract.changeOrderRequests.forEach((changeOrderRequest) => {
        evict({
          id: cache.identify(changeOrderRequest),
          fieldName: 'haveContractTaxesChanged',
        })
      })
    })
  }, [client, contract.changeOrderRequests, contract.payApps])

  const handleUpdateDefaultRateTableTaxCalculationType = useCallback(
    async (checked: boolean, defaultTaxGroupId: string | null) => {
      try {
        const updatedCalculationType = checked
          ? TaxCalculationType.MULTIPLE_TAX_GROUPS
          : TaxCalculationType.NONE

        // If checked & no tax group is passed in, store the value that's being
        // displayed in the dropdown (first item in the list of company taxes)
        const updatedDefaultTaxGroupId = checked
          ? (defaultTaxGroupId ?? defaultTaxGroup?.id)
          : defaultTaxGroupId

        await updateRateTableTaxCalculation({
          variables: {
            input: {
              contractId: contract.id,
              taxCalculationType: updatedCalculationType,
              defaultTaxGroupId: updatedDefaultTaxGroupId,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateRateTableTaxCalculationType: {
              ...contract,
              __typename: 'Contract',
              rateTableTaxCalculationType: updatedCalculationType,
            },
          },
          // Handle GC eviction after the request is completed so that the UI updates immediately
          onCompleted: handleUpdateCache,
        })
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [contract, defaultTaxGroup?.id, handleUpdateCache, snackbar, updateRateTableTaxCalculation]
  )

  const updateRateTableTaxGroup = useCallback(
    async (
      rateTableGroupId: string,
      rateTableTaxGroupId: string | null,
      taxGroupId: string | null
    ) => {
      try {
        // If updating to a null tax group id, delete the override
        if (taxGroupId === null && rateTableTaxGroupId !== null) {
          await deleteContractRateTableTaxGroup({
            variables: {
              input: {
                contractRateTableTaxGroupId: rateTableTaxGroupId,
              },
            },
            update: (cache) => {
              cache.modify<WritableDeep<Contract>>({
                id: cache.identify(contract),
                fields: {
                  rateTableTaxGroups(existing, { readField, toReference }) {
                    const refs = toReferences(existing, toReference)
                    return refs.filter((ref) => readField('id', ref) !== rateTableTaxGroupId)
                  },
                },
              })
            },
            // Handle GC eviction after the request is completed so that the UI updates immediately
            onCompleted: handleUpdateCache,
          })
          return
        }
        // If updating to non-null tax group and override already exists, update
        if (rateTableTaxGroupId !== null && taxGroupId !== null) {
          await updateContractRateTableTaxGroup({
            variables: {
              input: {
                contractRateTableTaxGroupId: rateTableTaxGroupId,
                taxGroupId,
              },
            },
            // Handle GC eviction after the request is completed so that the UI updates immediately
            onCompleted: handleUpdateCache,
          })
          return
        }
        // If an override hasn't been created yet, create it
        if (rateTableTaxGroupId === null && taxGroupId !== null) {
          await createContractRateTableTaxGroup({
            variables: {
              input: {
                contractId: contract.id,
                rateTableGroupId,
                taxGroupId,
              },
            },
            // Handle GC eviction after the request is completed so that the UI updates immediately
            onCompleted: handleUpdateCache,
          })
        }
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [
      contract,
      createContractRateTableTaxGroup,
      deleteContractRateTableTaxGroup,
      handleUpdateCache,
      snackbar,
      updateContractRateTableTaxGroup,
    ]
  )

  const taxesProps = useMemo(() => {
    return {
      usesTaxes,
      defaultTaxGroup: usesTaxes ? defaultTaxGroup : null,
      onUpdateTaxes: handleUpdateDefaultRateTableTaxCalculationType,
      onUpdateCategoryTaxes: updateRateTableTaxGroup,
      rateTableTaxGroups,
      companyTaxGroups: taxGroups,
    }
  }, [
    defaultTaxGroup,
    handleUpdateDefaultRateTableTaxCalculationType,
    rateTableTaxGroups,
    taxGroups,
    updateRateTableTaxGroup,
    usesTaxes,
  ])

  const handleEditToggle = useCallback(
    (updateIsEditing: boolean) => {
      const { rateTableTaxGroups, usesTaxes } = taxesProps
      const { rateTableDefaultTaxGroup: contractDefaultTaxGroup } = contract
      const hasTaxes = rateTableTaxGroups.length > 0 || contractDefaultTaxGroup !== null

      if (updateIsEditing) {
        setIsEditing(true)
      } else {
        setIsEditing(false)

        // If the user hasn't actually applied taxes as a default or to any categories, we should update
        // their tax calculation type so that the option displays as unchecked next time they edit the section
        if (!hasTaxes && usesTaxes) {
          handleUpdateDefaultRateTableTaxCalculationType(false, null)
        }
      }
    },
    [contract, handleUpdateDefaultRateTableTaxCalculationType, taxesProps]
  )

  return (
    <div className={classes.root}>
      <SettingsHeader
        title={isTimeAndMaterials ? t(`${i18nBase}.tabs.FEES`) : t(`${i18nBase}.tabs.COR_FEES`)}
        canEdit={canEdit}
        isEditing={isEditing}
        setIsEditing={handleEditToggle}
        bulkSaveProps={null}
      />
      <div className="feeTableWrapper">
        <FeeTables
          contract={contract}
          rateTable={rateTable}
          isEditing={isEditing}
          location="projectSettings"
          taxesProps={taxesProps}
        />
      </div>
    </div>
  )
}
