import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { LegalDocumentStatus } from 'siteline-common-all'
import { DocumentRequestStatus } from 'siteline-common-web'
import {
  DocumentExpirationFrequency,
  LegalDocumentProperties,
  LegalDocumentRequestProperties,
  LegalRequirementProperties,
  MinimalVendorProperties,
  UserProperties,
  VendorLegalRequirementsQuery,
} from '../graphql/apollo-operations'
import { LegalRequirementForm, isPeriodExpiring } from './LegalRequirement'

export const completedDocumentRequestStatuses = [
  DocumentRequestStatus.COMPLETE,
  DocumentRequestStatus.SUBMITTED,
  DocumentRequestStatus.SYNCED,
  DocumentRequestStatus.NOT_NEEDED,
]

/** Returns true if a legal document request has been completed */
export function isLegalDocumentRequestComplete(status: DocumentRequestStatus) {
  return completedDocumentRequestStatuses.includes(status)
}

/** Returns true if a legal document has been uploaded or filled out */
export function isLegalDocumentComplete<
  T extends { status: DocumentRequestStatus },
>(legalDocument: { legalDocumentRequests: readonly T[] | T[]; isUploaded: boolean }) {
  return (
    legalDocument.isUploaded ||
    legalDocument.legalDocumentRequests.some((request) =>
      isLegalDocumentRequestComplete(request.status)
    )
  )
}

export function isLegalDocumentSubmitted<
  T extends { status: DocumentRequestStatus },
>(legalDocument: {
  legalDocumentRequests: readonly T[] | T[]
  lastSendTime: string | null | undefined
}) {
  return (
    legalDocument.lastSendTime ||
    legalDocument.legalDocumentRequests.some(
      (request) => request.status === DocumentRequestStatus.SUBMITTED
    )
  )
}

interface MinimalUserInfo {
  fullName: string
  email: string
}

export interface LegalDocumentStatusInfo {
  status: LegalDocumentStatus
  actions: {
    action: LegalDocumentStatus
    actionTakenAt: Moment
    actionTakenBy?: MinimalUserInfo
  }[]
  timeZone: string
  // Used for expiring documents
  expirationDate?: Moment
  // Used for lien waiver amounts (in cents)
  amount?: number
}

/** Converts a user object to the minimal properties needed for document status */
export function userToMinimalUserInfo(
  user: Pick<UserProperties, 'firstName' | 'lastName' | 'email'>
) {
  return {
    fullName: `${user.firstName} ${user.lastName}`,
    email: user.email,
  }
}

/** Returns a status for the document and additional info on the status */
export function getVendorLegalDocumentStatus(
  legalDocument: Pick<
    LegalDocumentProperties,
    | 'lastSyncIfSuccessful'
    | 'lastSendTime'
    | 'isUploaded'
    | 'uploadedBy'
    | 'createdAt'
    | 'periodEnd'
  >,
  legalDocumentRequests: readonly Pick<
    LegalDocumentRequestProperties,
    'status' | 'updatedAt' | 'actionTakenAt' | 'vendorContact'
  >[],
  expirationFrequency: DocumentExpirationFrequency,
  timeZone: string
): LegalDocumentStatusInfo {
  // If the document has been synced, always show that first
  const lastSync = legalDocument.lastSyncIfSuccessful
  if (lastSync) {
    return {
      status: LegalDocumentStatus.SYNCED,
      actions: [
        {
          action: LegalDocumentStatus.SYNCED,
          actionTakenAt: moment.tz(lastSync.createdAt, timeZone),
        },
      ],
      timeZone,
    }
  }
  // If the document has been submitted, show that
  if (legalDocument.lastSendTime) {
    return {
      status: LegalDocumentStatus.SUBMITTED,
      actions: [
        {
          action: LegalDocumentStatus.SUBMITTED,
          actionTakenAt: moment.tz(legalDocument.lastSendTime, timeZone),
        },
      ],
      timeZone,
    }
  }
  // If the document has been completed, use whichever is latest of any vendor contact submissions
  // or sub uploads
  const completedLegalDocumentRequests = legalDocumentRequests.filter((request) =>
    isLegalDocumentRequestComplete(request.status)
  )
  if (legalDocument.isUploaded || completedLegalDocumentRequests.length > 0) {
    let status: LegalDocumentStatus | null = null
    let actions: LegalDocumentStatusInfo['actions'] | null = null
    const lastVendorCompletedRequest = _.maxBy(completedLegalDocumentRequests, (request) =>
      request.actionTakenAt ? moment.tz(request.actionTakenAt, timeZone).unix() : 0
    )
    const lastVendorCompletedAt = lastVendorCompletedRequest
      ? moment.tz(
          lastVendorCompletedRequest.actionTakenAt ?? lastVendorCompletedRequest.updatedAt,
          timeZone
        )
      : null
    const uploadedAt = legalDocument.isUploaded
      ? moment.tz(legalDocument.createdAt, timeZone)
      : null
    if (lastVendorCompletedAt && uploadedAt) {
      if (lastVendorCompletedAt.isAfter(uploadedAt) && lastVendorCompletedRequest) {
        status = LegalDocumentStatus.COMPLETED
        actions = [
          {
            action: LegalDocumentStatus.COMPLETED,
            actionTakenAt: lastVendorCompletedAt,
            actionTakenBy: lastVendorCompletedRequest.vendorContact,
          },
        ]
      } else {
        status = LegalDocumentStatus.UPLOADED
        actions = [
          {
            action: LegalDocumentStatus.UPLOADED,
            actionTakenAt: uploadedAt,
            actionTakenBy: legalDocument.uploadedBy
              ? userToMinimalUserInfo(legalDocument.uploadedBy)
              : undefined,
          },
        ]
      }
    } else if (lastVendorCompletedAt && lastVendorCompletedRequest) {
      status = LegalDocumentStatus.COMPLETED
      actions = [
        {
          action: LegalDocumentStatus.COMPLETED,
          actionTakenAt: lastVendorCompletedAt,
          actionTakenBy: lastVendorCompletedRequest.vendorContact,
        },
      ]
    } else if (uploadedAt) {
      status = LegalDocumentStatus.UPLOADED
      actions = [
        {
          action: LegalDocumentStatus.UPLOADED,
          actionTakenAt: uploadedAt,
          actionTakenBy: legalDocument.uploadedBy
            ? userToMinimalUserInfo(legalDocument.uploadedBy)
            : undefined,
        },
      ]
    }
    // Check first if the document is expiring; in that case, include the expiration date.
    if (status && actions) {
      if (
        expirationFrequency === DocumentExpirationFrequency.USER_INPUT &&
        legalDocument.periodEnd &&
        isPeriodExpiring(moment.tz(legalDocument.periodEnd, timeZone), timeZone)
      ) {
        return {
          status,
          actions,
          expirationDate: moment.tz(legalDocument.periodEnd, timeZone),
          timeZone,
        }
      }
      return { status, actions, timeZone }
    }
  }

  // Show all requests where the contact viewed the document
  const viewedRequests = legalDocumentRequests.filter(
    (request) => request.status === DocumentRequestStatus.VIEWED
  )
  if (viewedRequests.length > 0) {
    return {
      status: LegalDocumentStatus.VIEWED,
      actions: viewedRequests.map((request) => ({
        action: LegalDocumentStatus.VIEWED,
        actionTakenAt: moment.tz(request.actionTakenAt ?? request.updatedAt, timeZone),
        actionTakenBy: request.vendorContact,
      })),
      timeZone,
    }
  }

  // Show all sent requests
  const sentRequests = legalDocumentRequests.filter(
    (request) => request.status === DocumentRequestStatus.SENT
  )
  if (sentRequests.length > 0) {
    return {
      status: LegalDocumentStatus.REQUESTED,
      actions: sentRequests.map((request) => ({
        action: LegalDocumentStatus.REQUESTED,
        actionTakenAt: moment.tz(request.actionTakenAt ?? request.updatedAt, timeZone),
        actionTakenBy: request.vendorContact,
      })),
      timeZone,
    }
  }

  return {
    status: LegalDocumentStatus.NOT_REQUESTED,
    actions: [],
    timeZone,
  }
}

type VendorLegalRequirementForVendorDocuments =
  VendorLegalRequirementsQuery['vendorLegalRequirements'][number]

export interface VendorLegalDocument extends LegalRequirementForm {
  contractId: string
  projectId: string
  projectName: string
  vendor: MinimalVendorProperties
  vendorContractId: string
  period: string
  status: LegalDocumentStatusInfo
  isSkippedPeriod: boolean
  timeZone: string
  legalRequirement: Pick<
    LegalRequirementProperties,
    'id' | 'name' | 'expirationFrequency' | 'type' | 'timeZone'
  >
  vendorRequirement?: VendorLegalRequirementForVendorDocuments
}
