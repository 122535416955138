import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Button,
  ButtonProps,
  CircularProgress,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStylesFast, SitelineTooltip } from 'siteline-common-web'
import { MINIMUM_SUPPORTED_SCREEN_WIDTH } from '../themes/Main'

const useStyles = makeStylesFast((theme: Theme) => ({
  dropdownMenu: {
    '& .MuiButtonBase-root': {
      display: 'flex',
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1.5),
      },
    },
  },
  dropdownIcon: {
    // Animate the arrow icon flipping around when the menu opens
    transition: theme.transitions.create('transform'),
    '&.menuOpen': {
      // Flip the dropdown arrow upside down when the menu opens
      transform: 'rotate(-180deg)',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    marginLeft: theme.spacing(0.5),
  },
  buttonContainer: {
    display: 'inline-flex',
  },
  button: {
    whiteSpace: 'nowrap',
  },
  buttonWithMargin: {
    marginLeft: theme.spacing(1),
  },
}))

// On reduced screen size, all actions should go in the dropdown
const MIN_DROPDOWN_ACTIONS_REDUCED = 2

export enum ButtonVariant {
  /** Contained, primary */
  PRIMARY = 'primary',
  /** Outlined, secondary */
  SECONDARY = 'secondary',
}

interface SmallScreenProps {
  /**
   * Screen size that triggers ui change
   * @default MINIMUM_SUPPORTED_SCREEN_WIDTH
   */
  minScreenSize?: number
  /** Shortened label used on the dropdown button */
  dropdownLabel: ReactNode
}

export interface DropdownButtonAction {
  label: string
  /** Icon added to the beginning of the button text */
  icon?: ReactNode
  endIcon?: ReactNode
  onClick: () => void | Promise<void>
  disabled?: boolean
  tooltipTitle?: string

  /** Replaces label on reduced screen size */
  smallScreenLabel?: ReactNode

  /**
   * If an action is primary, it will be shown as an individual button unless `allowPrimaryDropdown`
   * is set to true on the `DropdownButton`
   */
  variant?: ButtonVariant
}

interface DropdownButtonProps extends Partial<ButtonProps> {
  actions: DropdownButtonAction[]

  /** Label shown on the button if multiple actions are shown; defaults to "Actions" */
  dropdownLabel?: string

  /** If there are fewer than this many actions, will show them as individual buttons; defaults to 3 */
  minDropdownActions?: number

  /** If true, will include primary actions in the dropdown and make the dropdown button primary */
  allowPrimaryDropdown?: boolean

  /** Optional callback triggered when the menu opens or closes */
  onToggleMenuOpen?: (isOpen: boolean) => void

  /**
   * When provided, these props tell us what content to render on reduced screen sizes.
   * If undefined, no changes are made to the buttons/dropdown at smaller screen sizes
   */
  smallScreenProps?: SmallScreenProps

  MenuProps?: Partial<MenuProps>
}

/** Shows multiple actions in a dropdown, or a single action as a button  */
export function DropdownButton({
  actions,
  dropdownLabel,
  minDropdownActions = 4,
  allowPrimaryDropdown = false,
  onToggleMenuOpen,
  MenuProps,
  smallScreenProps,
  ...props
}: DropdownButtonProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme: Theme = useTheme()
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(smallScreenProps?.minScreenSize ?? MINIMUM_SUPPORTED_SCREEN_WIDTH)
  )

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isLoadingActionIndex, setIsLoadingActionIndex] = useState<number | null>(null)

  const isReducedDisplay = smallScreenProps !== undefined && isSmallScreen

  const handleClick = useCallback(
    async (
      evt: MouseEvent<HTMLElement>,
      actionIndex: number,
      onClick: DropdownButtonAction['onClick']
    ) => {
      evt.preventDefault()
      evt.stopPropagation()
      setAnchorEl(null)
      setIsLoadingActionIndex(actionIndex)
      await onClick()
      setIsLoadingActionIndex(null)
    },
    []
  )

  // If a callback is provided, call it when the menu opens or closes
  useEffect(() => {
    if (onToggleMenuOpen) {
      onToggleMenuOpen(anchorEl !== null)
    }
  }, [onToggleMenuOpen, anchorEl])

  // Show primary buttons to the right when outside the dropdown
  const sortedActions = _.orderBy(actions, (action) =>
    action.variant === ButtonVariant.PRIMARY ? 1 : 0
  )
  let buttonActions: DropdownButtonAction[] = []
  let dropdownActions: DropdownButtonAction[] = []

  // Put all actions in the dropdown if reduced display
  const responsiveMinDropdownActions = isReducedDisplay
    ? MIN_DROPDOWN_ACTIONS_REDUCED
    : minDropdownActions

  // If num actions < `minDropdownActions`, we show as different buttons with at most one as the primary action (but not required)
  if (actions.length < responsiveMinDropdownActions) {
    buttonActions = [...sortedActions]
  } else if (actions.length >= responsiveMinDropdownActions) {
    // If num actions >= minDropdownActions AND with a primary action, we show 2 things: 1 dropdown menu, 1 blue/primary button
    // If num actions >= minDropdownActions and no primary action, we just show 1 dropdown menu
    // Note: if reduced display, always show 1 dropdown menu only
    buttonActions = sortedActions.filter(
      (action) =>
        action.variant === ButtonVariant.PRIMARY && !allowPrimaryDropdown && !isReducedDisplay
    )
    dropdownActions = sortedActions.filter(
      (action) =>
        action.variant !== ButtonVariant.PRIMARY || allowPrimaryDropdown || isReducedDisplay
    )
  }

  const dropdownHasPrimaryAction = dropdownActions.some(
    (action) => action.variant === ButtonVariant.PRIMARY
  )
  const dropdownButtonVariant = dropdownHasPrimaryAction ? 'contained' : 'outlined'
  const dropdownButtonColor = dropdownHasPrimaryAction ? 'primary' : 'secondary'
  const responsiveDropdownLabel = isReducedDisplay ? smallScreenProps.dropdownLabel : dropdownLabel
  const responsiveDropdownTooltip = isReducedDisplay ? dropdownLabel : ''

  return (
    <>
      {dropdownActions.length > 0 && (
        <>
          <Tooltip title={responsiveDropdownTooltip}>
            <Button
              {...props}
              variant={dropdownButtonVariant}
              color={dropdownButtonColor}
              endIcon={
                isLoadingActionIndex !== null ? (
                  <CircularProgress size={16} color="inherit" className={classes.spinner} />
                ) : (
                  <ArrowDropDownIcon
                    className={clsx(classes.dropdownIcon, { menuOpen: anchorEl !== null })}
                  />
                )
              }
              disabled={
                isLoadingActionIndex !== null || dropdownActions.every((action) => action.disabled)
              }
              onClick={(evt) => {
                evt.preventDefault()
                evt.stopPropagation()
                setAnchorEl(evt.currentTarget)
              }}
              className={clsx(classes.button, props.className)}
            >
              {responsiveDropdownLabel ?? t('projects.subcontractors.form_signing.actions')}
            </Button>
          </Tooltip>
          <Menu
            open={anchorEl !== null}
            onClose={(evt: MouseEvent) => {
              evt.preventDefault()
              evt.stopPropagation()
              setAnchorEl(null)
            }}
            anchorEl={anchorEl}
            className={classes.dropdownMenu}
            {...MenuProps}
          >
            {/* Reverse dropdown actions so higher priority actions are at the top */}
            {[...dropdownActions]
              .reverse()
              .map(({ label, icon, endIcon, onClick, disabled, tooltipTitle }, index) => (
                <SitelineTooltip placement="left-start" key={label} title={tooltipTitle ?? null}>
                  <MenuItem
                    key={label}
                    onClick={(evt) => handleClick(evt, index, onClick)}
                    disabled={disabled}
                  >
                    {icon}
                    {label}
                    {endIcon}
                  </MenuItem>
                </SitelineTooltip>
              ))}
          </Menu>
        </>
      )}
      {buttonActions.length > 0 && (
        <div className={classes.buttons}>
          {buttonActions.map((action, index) => {
            const isLoading = isLoadingActionIndex === index
            const isReducedButtonContent = action.smallScreenLabel && isSmallScreen
            const reducedButtonTooltip = isReducedButtonContent ? action.label : ''
            const startIcon = isLoading ? (
              <CircularProgress size={16} color="inherit" className={classes.spinner} />
            ) : (
              action.icon
            )

            return (
              <Tooltip
                title={action.tooltipTitle ?? reducedButtonTooltip}
                placement="bottom"
                key={`${action.label}-${index}`}
              >
                <div
                  className={clsx(classes.buttonContainer, {
                    [classes.buttonWithMargin]: index > 0 || dropdownActions.length > 0,
                  })}
                >
                  <Button
                    {...props}
                    variant={action.variant === ButtonVariant.PRIMARY ? 'contained' : 'outlined'}
                    color={action.variant === ButtonVariant.PRIMARY ? 'primary' : 'secondary'}
                    startIcon={isReducedButtonContent ? undefined : startIcon}
                    endIcon={action.endIcon}
                    disabled={action.disabled || isLoading}
                    onClick={(evt) => handleClick(evt, index, action.onClick)}
                    className={clsx(classes.button, props.className)}
                  >
                    {isReducedButtonContent ? action.smallScreenLabel : action.label}
                  </Button>
                </div>
              </Tooltip>
            )
          })}
        </div>
      )}
    </>
  )
}
