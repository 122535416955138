import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { MouseEvent, ReactNode, useCallback } from 'react'
import { SitelineText, SitelineTooltip, colors, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  contact: {
    whiteSpace: 'pre-wrap',
    lineHeight: 1.5,
    border: `1px solid ${colors.grey30}`,
    backgroundColor: colors.white,
    padding: theme.spacing(1),
    borderRadius: 4,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 40,
    '&.grey': {
      backgroundColor: colors.grey10,
    },
    '& .removeIcon': {
      // Match the padding of the small icon
      margin: -3,
    },
    '&.clickable': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    whiteSpace: 'pre-wrap',
  },
}))

interface RemovableChipProps {
  label: ReactNode
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void
  onRemove?: () => void
  tooltipTitle?: ReactNode
  color?: 'white' | 'grey'
  className?: string
  disabled?: boolean
}

/** A chip displaying a label with a click handler and optional tooltip  */
export function RemovableChip({
  label,
  onClick,
  onRemove,
  tooltipTitle = '',
  color = 'white',
  className,
  disabled,
}: RemovableChipProps) {
  const classes = useStyles()

  const handleRemove = useCallback(
    (evt: MouseEvent<HTMLButtonElement>) => {
      if (!onRemove) {
        return
      }
      evt.stopPropagation()
      evt.preventDefault()
      onRemove()
    },
    [onRemove]
  )

  return (
    <SitelineTooltip
      title={tooltipTitle}
      placement="top-start"
      classes={{ tooltip: classes.tooltip }}
    >
      <div>
        <SitelineText
          endIcon={
            onRemove && (
              <IconButton
                color="secondary"
                onClick={handleRemove}
                size="small"
                className="removeIcon"
                disabled={disabled}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
          onClick={disabled ? undefined : onClick}
          variant="h4"
          bold
          color="grey70"
          className={clsx(classes.contact, className, {
            grey: color === 'grey',
            clickable: onClick !== undefined && !disabled,
          })}
        >
          {label}
        </SitelineText>
      </div>
    </SitelineTooltip>
  )
}
