import _ from 'lodash'
import { POSTAL_CODE_REGEX, STATE_CODE_VALIDATION_REGEX } from 'siteline-common-all'
import { CompanyProperties, LocationInput, LocationProperties } from '../graphql/apollo-operations'
import { getOfficeName } from './Office'

const DEFAULT_COUNTRY = 'United States'

export const makeEmptyLocationInput = (): LocationInput => ({
  nickname: null,
  street1: null,
  street2: null,
  city: '',
  county: null,
  state: '',
  country: DEFAULT_COUNTRY,
  postalCode: null,
  coordinates: { latitude: 0, longitude: 0 },
})

const isEmptyLocation = (location: LocationProperties | LocationInput): boolean => {
  return (
    location.city.trim() === '' && location.state.trim() === '' && location.country.trim() === ''
  )
}

export const isIncompleteLocation = (location: LocationInput): boolean => {
  return (
    location.city.trim() === '' || location.state.trim() === '' || location.country.trim() === ''
  )
}

export const isValidStateCode = (stateCode: string): boolean => {
  return STATE_CODE_VALIDATION_REGEX.test(stateCode)
}

export const isValidLocation = ({ postalCode }: Pick<LocationInput, 'postalCode'>): boolean => {
  return postalCode === null || postalCode === '' || POSTAL_CODE_REGEX.test(postalCode ?? '')
}

export const formatLocationOneLine = (location: LocationProperties | LocationInput): string => {
  // Special case if the location doesn't exist
  if (isEmptyLocation(location)) {
    return ''
  }

  let formatted = ''
  if (location.street1?.trim()) {
    formatted += `${location.street1}, `
    if (location.street2?.trim()) {
      formatted += `${location.street2}, `
    }
  }
  if (location.city.trim()) {
    formatted += `${location.city}, `
  }

  formatted += `${location.state}`
  if (location.postalCode) {
    formatted += ` ${location.postalCode}`
  }
  return formatted
}

export const formatLocationManyLines = (location: LocationProperties | LocationInput): string => {
  // Special case if the location doesn't exist
  if (isEmptyLocation(location)) {
    // Include empty lines so the location still takes up 2 lines
    return '\n\n'
  }

  let formatted = `${location.street1}${location.street2 ? ', ' + location.street2 : ''}`
  formatted += `\n${location.city}, ${location.state}`
  if (location.postalCode) {
    formatted += ` ${location.postalCode}`
  }
  return formatted
}

/** For office locations, where want to to format the location to a single line and prefix with the office name */
export const formatLocationOneLineWithName = (location: LocationProperties) => {
  const locationName = getOfficeName(location)
  const locationOneLine = formatLocationOneLine(location)
  return `${locationName}: ${locationOneLine}`
}

/** Our "create location" request contains optional fields. For any blank fields, we want to replace empty string/ undefined with null */
export const formatLocationWithEmptyFields = (location: LocationInput) => ({
  city: location.city,
  country: location.country,
  state: location.state,
  coordinates: location.coordinates,
  nickname: location.nickname || null,
  street1: location.street1 || null,
  street2: location.street2 || null,
  postalCode: location.postalCode || null,
  county: location.county || null,
})

/**
 * Creates a map of company locations structured in { [id]: formattedLocation } format
 * @example { '1234567': "Airbnb HQ: 888 Brannan Street, San Francisco, CA 94103" }
 */
export const buildFormattedCompanyLocationsMap = (company: CompanyProperties | null) => {
  const locations = company?.locations ?? []
  const locationIds = locations.map(({ id }) => id)
  const formattedLocations = locations.map(formatLocationOneLineWithName)
  return _.zipObject(locationIds, formattedLocations)
}
