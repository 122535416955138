import CheckIcon from '@mui/icons-material/Check'
import { Button, styled, Switch } from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  colors,
  Column,
  fuseSearch,
  Row,
  SitelineSearch,
  SitelineText,
  Spacer,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { SameGcChip } from '../../../common/components/SameGcChip'
import { GreyChip } from '../../../common/components/SitelineChip'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { useSelectProjectFormsMutation } from '../../../common/graphql/apollo-operations'
import { isNonEmptyArray, NonEmptyArray } from '../../../common/util/Array'
import {
  CompanyTemplateCollection,
  CompanyTemplateSet,
  filterCollectionCustomerReadyFormTemplates,
} from '../../../common/util/Forms'
import { trackSelectedProjectForms } from '../../../common/util/MetricsTracking'
import {
  ContractOnboardingFormType,
  invalidateContractsAfterOnboardingStatusChange,
} from '../../../common/util/ProjectOnboarding'
import {
  CompanyTemplateCollections,
  CompanyTemplateSets,
  ContractForCompanyFormSetSelection,
  PreviewCompanyTemplatesDialog,
} from './PreviewCompanyTemplatesDialog'

const StyledSelectCompanyTemplatesDialogInner = styled('div')(({ theme }) => ({
  '& .searchBar': {
    margin: 0,
  },
  '& .templateGroupList': {
    height: 'fit-content',
    maxHeight: 360,
    overflowY: 'scroll',
    marginTop: theme.spacing(2),
  },
  '& .templateGroupRow': {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${colors.grey30}`,
    minHeight: 56,
    height: 'fit-content',
    '& .checkIcon': {
      color: colors.grey30,
      '&.selected': {
        color: colors.blue50,
      },
    },
    '& .previewButton': {
      color: colors.grey50,
    },
    '& .visibleOnRowHover': {
      opacity: 0,
      transition: theme.transitions.create(['color', 'opacity'], { duration: 150 }),
    },
    '&:hover': {
      backgroundColor: colors.grey20,
      '& .checkIcon.selected': {
        color: colors.blue70,
      },
      '& .visibleOnRowHover': {
        opacity: 1,
      },
    },
  },
  '& .groupName': {
    whiteSpace: 'pre-wrap',
  },
}))

const i18nBase = 'projects.subcontractors.settings.forms'

interface SelectCompanyTemplatesDialogProps {
  open: boolean
  onClose: (fromButton?: boolean) => void
  formTypes: NonEmptyArray<ContractOnboardingFormType>
  contract: ContractForCompanyFormSetSelection
  companyTemplateGroups: CompanyTemplateSets | CompanyTemplateCollections
  location: string
}

/**
 * There are two types of template groups stored on the company:
 * 1. A company template set, which is either a pay app template set or a primary/vendor lien waiver template set
 * 2. A company template collection, which is a group of pay app and lien waiver template sets, as well as the default
 *    change order log & change order request templates.
 *
 * A company may have multiple collections and multiple sets, for example if they work with multiple GCs that
 * require different sets of forms.
 *
 * This dialog handles both cases, depending on where it is opened from. If it's opened from the bulk action form
 * onboarding banner, the dialog will display the company's template collections. If it's opened from a form
 * section (e.g., the pay app forms section), the dialog will display the company's template sets.
 *
 * This is a two-step dialog. The first dialog view displays a list of either collections or sets. The second
 * dialog view displays a preview of the selected template collection or set.
 */
export function SelectCompanyTemplatesDialog({
  contract,
  companyTemplateGroups,
  formTypes,
  open,
  onClose,
  location,
}: SelectCompanyTemplatesDialogProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { name: projectName } = useProjectContext()

  const [shouldFilterSameGc, setFilterSameGcOn, setFilterSameGcOff] = useToggle()
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null)
  const [previewingGroupId, setPreviewingGroupId] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [selectForms, { loading }] = useSelectProjectFormsMutation({
    update: (cache) => {
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  const templateGroupType = companyTemplateGroups.type
  const isTemplateSetType = templateGroupType === 'templateSet'
  const isTemplateCollectionType = templateGroupType === 'templateCollection'
  const isPreviewTemplateStep = previewingGroupId !== null && open
  const contractGcId = contract.project.generalContractor?.company.id

  const [templateListTitle, placeholder] = useMemo(() => {
    switch (templateGroupType) {
      case 'templateSet': {
        return [t(`${i18nBase}.select_form_set`), t(`${i18nBase}.search_form_set_name_placeholder`)]
      }
      case 'templateCollection': {
        return [
          t(`${i18nBase}.select_form_collection`),
          t(`${i18nBase}.search_collection_name_placeholder`),
        ]
      }
    }
  }, [t, templateGroupType])

  const groupsWithSameGc = useMemo(() => {
    return companyTemplateGroups.groups.filter((group) => {
      return !!group.generalContractor && group.generalContractor.id === contractGcId
    })
  }, [companyTemplateGroups.groups, contractGcId])

  const filteredCompanyTemplateGroups = useMemo(() => {
    const groups = shouldFilterSameGc ? groupsWithSameGc : companyTemplateGroups.groups
    const mappedWithPreviewStatus = groups.map((group) => {
      if (isTemplateSetType) {
        const set = group as CompanyTemplateSet
        return { ...group, disablePreview: set.isProcessingForms }
      }
      const collection = group as CompanyTemplateCollection
      const previewableTemplateSets = filterCollectionCustomerReadyFormTemplates(collection)
      return { ...group, disablePreview: Object.keys(previewableTemplateSets).length === 0 }
    })
    const sorted = _.orderBy(mappedWithPreviewStatus, (group) => group.name)
    return fuseSearch(sorted, searchQuery, ['name'])
  }, [
    companyTemplateGroups.groups,
    groupsWithSameGc,
    isTemplateSetType,
    searchQuery,
    shouldFilterSameGc,
  ])

  const noFormsFoundMessage = useMemo(() => {
    if (filteredCompanyTemplateGroups.length > 0) {
      return null
    }
    if (searchQuery.length > 0) {
      return t(`${i18nBase}.no_forms_found`)
    }
    if (shouldFilterSameGc) {
      return t(`${i18nBase}.no_forms_found_with_gc`)
    }
    return null
  }, [filteredCompanyTemplateGroups.length, searchQuery.length, shouldFilterSameGc, t])

  // If the dialog is handling template sets, `formTypes` will be a single item corresponding to the form type
  // of that form set. If this dialog is handling template collections, `formTypes` will correspond to all form
  // types available for onboarding based on the contract's billing type & integrations. Collections might not
  // contain all form types, so we need to identify the subset of form types that exists on the selected template
  // collection (only relevant if a collection is being previewed).
  const availableFormTypes = useMemo(() => {
    if (isTemplateSetType) {
      return formTypes
    }
    if (previewingGroupId === null) {
      return formTypes
    }
    const previewingCollection = companyTemplateGroups.groups.find(
      (group) => group.id === previewingGroupId
    )
    if (!previewingCollection) {
      return formTypes
    }
    return Object.keys(previewingCollection.templateSets) as ContractOnboardingFormType[]
  }, [companyTemplateGroups.groups, formTypes, isTemplateSetType, previewingGroupId])

  const shouldDisplayConditionalFormsMessage = useMemo(() => {
    if (previewingGroupId === null || !isTemplateSetType) {
      return false
    }
    const previewingTemplateSet = companyTemplateGroups.groups.find(
      (group) => group.id === previewingGroupId
    )
    return !!previewingTemplateSet?.hasConditionalRequirements
  }, [companyTemplateGroups.groups, isTemplateSetType, previewingGroupId])

  const handleToggleSameGcFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setFilterSameGcOn()
      } else {
        setFilterSameGcOff()
      }
    },
    [setFilterSameGcOff, setFilterSameGcOn]
  )

  const handleSelectTemplateGroup = useCallback((id: string) => {
    setSelectedGroupId((current) => {
      if (current === id) {
        return null
      }
      return id
    })
  }, [])

  const handleClose = useCallback(
    (fromButton: boolean) => {
      if (fromButton && isPreviewTemplateStep) {
        setPreviewingGroupId(null)
        return
      }
      setPreviewingGroupId(null)
      setSelectedGroupId(null)
      setSearchQuery('')
      onClose(fromButton)
    },
    [isPreviewTemplateStep, onClose]
  )

  const handleSubmit = useCallback(
    async (includeChangeOrderLogOnPayApps?: boolean) => {
      if (!selectedGroupId && !previewingGroupId) {
        return
      }

      const selectedId = isPreviewTemplateStep ? previewingGroupId : selectedGroupId
      const templateSetId = isTemplateSetType ? selectedId : undefined
      const templateCollectionId = isTemplateCollectionType ? selectedId : undefined
      const formType = availableFormTypes.length === 1 ? formTypes[0] : undefined

      try {
        await selectForms({
          variables: {
            input: {
              contractId: contract.id,
              formTypes: availableFormTypes,
              templateSetId,
              templateCollectionId,
              includeChangeOrderLogOnPayApps,
            },
          },
        })
        trackSelectedProjectForms({
          contractId: contract.id,
          projectName,
          formsType: availableFormTypes,
          location,
          selectType: templateGroupType,
          includeChangeOrderLogOnPayApps,
        })
        snackbar.showSuccess(
          formType ? t(`${i18nBase}.added_forms_success.${formType}`) : t(`${i18nBase}.added_forms`)
        )
      } catch (error) {
        snackbar.showError(error.message)
      }
      handleClose(false)
    },
    [
      availableFormTypes,
      contract.id,
      formTypes,
      handleClose,
      isPreviewTemplateStep,
      isTemplateCollectionType,
      isTemplateSetType,
      location,
      previewingGroupId,
      projectName,
      selectForms,
      selectedGroupId,
      snackbar,
      t,
      templateGroupType,
    ]
  )

  const shouldHideSameGcFilter =
    !shouldFilterSameGc && (groupsWithSameGc.length === 0 || !contract.project.generalContractor)

  if (isPreviewTemplateStep && isNonEmptyArray(availableFormTypes)) {
    return (
      <PreviewCompanyTemplatesDialog
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        formTypes={availableFormTypes}
        contract={contract}
        supertitle={templateListTitle}
        submitting={loading}
        previewingGroupId={previewingGroupId}
        companyTemplateGroups={companyTemplateGroups}
        shouldDisplayConditionalFormsMessage={shouldDisplayConditionalFormsMessage}
      />
    )
  }

  return (
    <SitelineDialog
      title={templateListTitle}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitting={loading}
      disableSubmit={!selectedGroupId}
      submitLabel={t('common.actions.select')}
      size="email"
    >
      <StyledSelectCompanyTemplatesDialogInner>
        <Row alignItems="center" className="searchAndFilter" gap={16}>
          <SitelineSearch
            initialSearch={searchQuery}
            onSearchChanged={setSearchQuery}
            placeholder={placeholder}
            className="searchBar"
            theme="white"
            showClearButton
            autoFocus
          />
          {!shouldHideSameGcFilter && (
            <Row alignItems="center">
              <SitelineText variant="body1">{t(`${i18nBase}.only_show_same_gc`)}</SitelineText>
              <Switch checked={shouldFilterSameGc} onChange={handleToggleSameGcFilter} />
            </Row>
          )}
        </Row>
        <Column className="templateGroupList">
          {noFormsFoundMessage !== null && (
            <SitelineText variant="body1" color="grey50" className="noFormsFoundMessage">
              {noFormsFoundMessage}
            </SitelineText>
          )}
          {filteredCompanyTemplateGroups.map((group) => {
            const selected = group.id === selectedGroupId
            return (
              <Row
                key={group.id}
                alignItems="center"
                justifyContent="flex-start"
                gap={12}
                className="templateGroupRow"
                onClick={() => handleSelectTemplateGroup(group.id)}
              >
                <CheckIcon
                  className={clsx('checkIcon', { selected, visibleOnRowHover: !selected })}
                />
                <SitelineText variant="body1" className="groupName">
                  {group.name}
                </SitelineText>
                <SameGcChip
                  currentContract={contract}
                  otherContract={undefined}
                  generalContractorId={group.generalContractor?.id}
                />
                <Spacer />
                {!group.disablePreview && (
                  <Button
                    variant="text"
                    color="secondary"
                    className={clsx('previewButton', 'visibleOnRowHover')}
                    onClick={() => setPreviewingGroupId(group.id)}
                  >
                    {t(`${i18nBase}.preview_forms`)}
                  </Button>
                )}
                {group.disablePreview && (
                  <GreyChip
                    label={t(`${i18nBase}.forms_processing_chip`)}
                    className="visibleOnRowHover"
                    size="small"
                  />
                )}
              </Row>
            )
          })}
        </Column>
      </StyledSelectCompanyTemplatesDialogInner>
    </SitelineDialog>
  )
}
